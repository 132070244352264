import React, { useEffect, useRef, useState } from "react";
import "./pageStyle.css";
import {
  Breadcrumbs,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "../../MUIComponents/index";
import Direction from "../../../utils/Icon/Direction.svg";
import DirectionSub from "../../../utils/Icon/DirectionSub.svg";
import Phone from "../../../utils/Icon/Phone.svg";
import ReadyForLine from "../../../utils/Icon/ReadyForLine.svg";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import lines from "../../../utils/Icon/lines.svg";
import UploadImage from "../../../utils/Icon/UploadImage.svg";
import Camera from "../../../utils/Icon/Camera.svg";
import LineInReadyForDel from "../../../utils/Icon/LineInReadyForDel.svg";
import BlueClock from "../../../utils/Icon/BlueClock.svg";
import Pointer from "../../../utils/Icon/Pointer.svg";
import CustomButton from "../../Commoncomponents/button";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_17,
  HeaderFontSize_24,
} from "../../Typography";
// import NewImg from "../../AddProduct/ImageCropper/NewImg"
import CustomCard from "../../Commoncomponents/card";
import { Upload, message } from "antd";
import OrderImage from "../../AddProduct/ImageCropper/OrderImage";
import LoaderReady from "../Loader/LoaderReady";
import EditGoogleMap from "../../EditGoogleMap";
import SimpleMap from "../../LocationCordinates";
import MultiImgOrder from "../../AddProduct/ImageCropper/MultiImgOrder";
import LocationShare from "../GoogleMap/LocationShare";
import CustomDialog from "../../Commoncomponents/dialog";
import CustomTextField from "../../Commoncomponents/input";
function ReadyForDelivery(props) {
  const location = useLocation();
  const orderid = location.state.orderid;

  useEffect(() => {  
    props.getSearchDetails(orderid);
  }, []);

  const readyOrderDetails = ("props", props && props.searchDetails);

  const [data, setData] = useState([
    {
      orderNumber: "1025",
      deliveryDate: "Thu Sep 19 2023",
      deliveryTime: "10:00-12:00pm",
      products: [{ name: "apple", quantity: 2, price: "120" }],
    },
  ]);
 const [showDirection,setShowDirection] = useState(false)
 function handleDirection (){
  setShowDirection(true)
  nav('/home/MyOrders/GoogleMap', { state: { orderid: orderid } });
 }
   const nav = useNavigate();
  function handleClickOne() {
    nav("/home/MyOrders/ReadyForDelivery");
  }
  const Datas = JSON.parse(localStorage.getItem("selectedItems")) || [];
  const DataOut = JSON.parse(localStorage.getItem("orderCardDetails")) || [];

  // const totalProductPrice = data
  //   ? data.products.reduce((total, item) => total + item.quantity * item.price, 0)
  //   : 0;
  const totalProductPrice = readyOrderDetails
    ? readyOrderDetails?.products?.reduce((total, item) => total + item.shopAssistantQuantity * item.productPrice, 0)
    : 0;
  const deliveryCharge = readyOrderDetails?.version === 3 ?  readyOrderDetails?.deliveryChargeWithoutMarkup : readyOrderDetails?.deliveryCharge
  const packingCharge = readyOrderDetails?.version === 3 ? readyOrderDetails?.packingChargeWithoutMarkup : readyOrderDetails?.packingCharge 
  const discountPrice = readyOrderDetails?.couponID?.storeid !== null ? readyOrderDetails?.discountPrice : null

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total 
  const grandTotal = totalProductPrice + totalCharges;

  const OutbuttonStateRef = useRef(false);
  const [OutForDelivery, setOutForDelivery] = useState([]);
 // const [loading, setLoading] = useState(false)
 // const [packingImages, setPackingimages] = useState("")
 const [packingImages, setPackingImages] = useState([props?.multiImage?.uploadMultipleFiles]);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    setPackingImages(props?.multiImage?.uploadMultipleFiles.map((i)=>(
      i.Location
    )));
  }, [props?.multiImage?.uploadMultipleFiles]);
  function handleOutForDelivery (){

    if (packingImages === undefined || packingImages === null) {
      message.error({
        content: "Please upload the image",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "40vh",
        },
      });
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        if (packingImages) {
          props.changeToOutForDelivery(orderid, packingImages);
          if(location.state.shopAssistantOrder){
            nav("/home/ShopAssistant/orders", {
              state: {
                shopAssistantId: location.state.shopAssistantId,
                fname: location.state.fname,
                lname: location.state.lname,
                phno: location.state.phno,
                profilePicUrl:location.state.profilePicUrl,
                moveDelivery:true,
              },
            });
        
          }else{
            nav("/home/MyOrders/OutForDeliveryDetails", {
              state: { orderid: orderid },})
          }
          
          
          // Clear the packingImages variable in state
          //setPackingImages(null);
        }
      }, 3000);
    }
  }
 // Log the packingImages array whenever it changes
 useEffect(()=>{
  setPackingImages(null)
},[])
  // const handleClickTwo = () => {
  //   nav("/home/MyOrders");
  // };


    // console.log(Datas);


    // nav("/home/MyOrders/OutForDelivery");

  
  function handleClickTwo() {
    nav("/home/MyOrders");
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [share,setShare] = useState(false)
  const [open, setOpen] = useState(false);
  function handleShare (){
    setShare(true)
    setOpen(true)
  }
  // function handleClose(){
  //   setOpen(false)
  // }
  //const [open, setOpen] = useState(false);
  // const [url, setUrl] = useState(props.DynamicLink ? props.DynamicLink : '');
 const url = localStorage.getItem('googleMapsUrl')
  
  const [copySuccess, setCopySuccess] = useState(false);


  

  const handleClickOpen = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
    setCopySuccess(false);
  };

  const copyCodeToClipboard = async () => {
    const el = inputRef.current;
    el.select();
    try {
      await navigator.clipboard.writeText(el.value);
      setCopySuccess(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const inputRef = React.createRef();

  const destination = {
    lat: Number(readyOrderDetails?.deliveryLat),
    lng: Number(readyOrderDetails?.deliveryLng)
  }
  const origin = {
    lat: Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[1]) || 10.850516,
    lng: Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[0]) || 76.27108,
  };
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`;
localStorage.setItem('googleMapsUrl',googleMapsUrl)




  return (
    <div style={{ marginLeft: "0px", marginRight: "0px" }}>
      {!loading && (
        <>
          <div>
            <div className="MainReadyOrder">
              <div style={{ cursor: "pointer" }} onClick={handleClickOne}>
                <Typography
                  fontSize={HeaderNavigationFontSize_20_xs_13}
                  className="MainReady"
                  sx={{ textDecoration: "underline" }}
                >
                  Ready for delivery{" "}
                </Typography>
              </div>

              <div>
                <Typography
                  className="MainReady"
                  fontSize={HeaderNavigationFontSize_20_xs_13}
                  sx={{ padding: "0px 7px 0px 7px" }}
                >
                  {" "} / {" "}
                </Typography>
              </div>
              <div style={{ cursor: "pointer" }} >
                <Typography
                  fontSize={HeaderNavigationFontSize_20_xs_13}
                  className="MainReadyForDelivery"
                >
                  View Ready for delivery Details
                </Typography>
              </div>
            </div>
          </div>

          {/* <div
        className="firstBox" 
        
      >
        <Grid container direction="row" justifyContent="space-between">
          <Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "25px",
                marginLeft: "25px",
              }}
            >
              <img
                src={Direction}
                style={{ width: "20px", height: "20px", marginLeft: "-17px" }}
                alt="Direction Icon"
              />
              <span>
                <Typography
                  className="nameContent"
                  sx={{ fontSize: { contentFontsize_14 } }}
                >
                  Saroj Kumar
                </Typography>
              </span>
            </div>
            <Typography
              className="address"
              sx={{ fontSize: { contentFontsize_14 }}}
            >
              SBC- 12, Tejeswini, Technopark, Kazhakuttom,
              <br/>Kerala - 695584 Fri : Jul 21 2023, 3:43 PM
            </Typography>
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "15px",
              marginLeft: "15px",
            }}
          >
            <img
              src={Phone}
              style={{ width: "20px", height: "20px" }}
              alt="Phone icon"
            />
            <span>
              <Typography className="numberStyle" fontSize={contentFontSize_18}>
                +91 471 000 0000
              </Typography>
            </span>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={6} md={8} lg={12} xl={12} sx={{ marginTop: "50px" }}>
            <img sx={{ width: "100%" }} src={lines} alt="LineInReadyForDel" />
          </Grid>
        </Grid>

        <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop:"30px"
      }}
    >
      <Grid item xs={6}> 
        <Timeline  >
          <TimelineItem classes={{ root: 'customTimelineItem' }}>
            <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#85BE49" ,margin: 0 }} />
              <TimelineConnector sx={{ backgroundColor: "#85BE49" , margin: 0 }} />
            </TimelineSeparator>
            
            <TimelineContent>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize={contentFontSize_18} sx={{ fontWeight: "800", fontFamily: "Roboto", color: "#000000",marginBottom:"20px" }}>Outlet</Typography>
            
            <span style={{ marginLeft: "20px" }}><Typography sx={{fontFamily:"Roboto",fontWeight:"500",color:"#8B8989"}} fontSize={contentFontSize_16}>Le Arabia</Typography></span>
            
          </div> 
          </TimelineContent>      
          </TimelineItem>
          <TimelineItem classes={{ root: 'customTimelineItem' }}> 
            <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#85BE49" ,margin: 0}} />
            </TimelineSeparator>
            <TimelineContent>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize={contentFontSize_18} sx={{ fontWeight: "800", fontFamily: "Roboto", color: "#000000" }}>35 mins</Typography>
           
            <span style={{ marginLeft: "20px" }}><Typography sx={{fontFamily:"Roboto",fontWeight:"500",color:"#8B8989"}} fontSize={contentFontSize_16}>3.6 kilometers</Typography></span>
            
          </div>
          </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}> 
        <div style={{ marginRight: "100px" }}>
          <CustomButton
            width="145px"
            height="45px"
            variant="outlined"
            color="#85BE49"
            borderColor="#85BE49"
            background="none"
            hasIcon={true}
            iconPosition="startIcon"
          >
            <img src={Direction} style={{ width: "15px", height: "15px" }} alt="Direction Icon" />
            Direction
          </CustomButton>
        </div>
      </Grid>
    </Grid>

      </div> */}

          <div style={{ marginTop: "30px" }}>
            <CustomCard height="98%" width="100%" padding="0px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div style={{ display: "flex", flexDirection: "column", margin: "28px 40px 2px 44px" }}>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <img
                        src={Direction}
                        style={{ width: "20px", height: "20px" }}
                        alt="Direction Icon"
                        
                      />

                      <div>
                        <Typography
                          className="nameStyleReady"
                          fontSize={contentFontsize_17}
                        >
                          {" "}
                          {readyOrderDetails && readyOrderDetails.customerName}
                        </Typography>
                      </div>
                    </div>

                    {/* <div>
                      <Typography
                        className="addressReady"
                        sx={{ fontSize: { contentFontsize_14 } }}
                      >
                        {readyOrderDetails && readyOrderDetails.deliveryAddress}
                      </Typography>
                    </div> */}
                    <div>
                    <Typography
          //key={index}
          className="address"
          sx={{ fontSize: contentFontsize_14 }}
        >
          {/* {newOrderDetails && newOrderDetails.deliverytime}{" "} */}
          {readyOrderDetails?.deliveryAddress} , {readyOrderDetails?.stats[3]?.created},{readyOrderDetails?.stats[3]?.createdTime}
        </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: smmatch ? "flex-start" : "flex-end",
                      height: "100%",
                      margin: "28px 40px 2px 44px"
                    }}
                  >
                    <img
                      src={Phone}
                      style={{ width: "20px", height: "20px" }}
                      alt="Phone icon"
                    />
                    <span>
                      <Typography
                        className="numberStyle"
                        sx={{ fontSize: { contentFontSize_18 } }}
                      >
                        +91{" "}{readyOrderDetails && readyOrderDetails.mobileNumber}
                      </Typography>
                    </span>
                  </div>
                </Grid>
              </Grid>
              <div style={{ margin: "20px 0px" }}>
                <img style={{ width: "100%" }} src={ReadyForLine} />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ margin: "1px -125px 44px 47px" }}>
                  <div style={{ display: "flex", gap: "10px", overflow: "hidden" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "80px",
                      }}
                    >
                      {/* <Timeline>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ margin: 0 }} />
          <TimelineConnector sx={{ margin: 0}} />
        </TimelineSeparator>
       
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ margin: 0 }} />
        </TimelineSeparator>
       
      </TimelineItem>
    </Timeline> */}
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              background: "#85BE49",
                              height: "10px",
                              width: "10px",
                              borderRadius: "50%",
                            }}
                          ></div>
                          <div
                            style={{
                              background: "#85BE49",
                              height: "10px",
                              width: "10px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                        <div className="connecting-line"></div>
                        {/* </div> */}
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          justifyContent: "space-between",
                          lineHeight: "0.8",
                        }}
                      >
                        <div
                          fontSize={contentFontSize_18}
                          style={{
                            fontWeight: "800",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Outlet
                        </div>
                        <div
                          fontSize={contentFontSize_18}
                          style={{
                            fontWeight: "800",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          {/* 35 mins */}
                          {readyOrderDetails?.travelTime?.text}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          justifyContent: "space-between",
                          lineHeight: "0.8",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#8B8989",
                            fontWeight: "500",
                          }}
                        >
                          {readyOrderDetails?.storeid?.storeName}
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#8B8989",
                            fontWeight: "500",
                          }}
                        >
                          {/* 3.6 kilometers */}
                          {readyOrderDetails?.travelDistance?.text}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ margin: "1px -20px 44px 47px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "30px",
                      height: "100%",
                      justifyContent: smmatch ? "flex-start" : "flex-end",
                      alignItems: "center",
                      marginTop: smmatch ? "20px" : "0px"
                    }}
                  ><div>
                      <CustomButton
                        width="120px"
                        height="45px"
                        variant="outlined"
                        color="#85BE49"
                        borderColor="#85BE49"
                        background="none"
                        hasIcon={true}
                        iconPosition="startIcon"
                       onClick={handleDirection}
                      >
                        <img
                          src={Direction}
                          style={{ width: "15px", height: "15px", marginRight: "10px" }}
                        />
                        Direction
                      </CustomButton>
                    </div>
                   
                    <div onClick={handleShare}>
                      <img
                        src={DirectionSub}
                        style={{ width: "25px", height: "33px", marginRight: "24px",cursor:"pointer" }}
                        onClick={handleShare}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CustomCard>
            {share && (
              <CustomDialog
              width="600px"
              height="200px"
              borderRadius="20px"
              open={open}
              onClose={handleClose}
              titleAlign="center"
              // title="Share URL"
              top={"15px"}
              right={"20px"}
              // showCloseButton={false}
            >
              <Typography
                style={{
                  color: "#170F49",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
                className="head-font"
                fontSize={HeaderFontSize_24}
              >
                Share URL
              </Typography>
              <DialogContent>
                <div>
                  <CustomTextField
                    width="100%"
                    value={url}
                    inputRef={inputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <div>
                          <CustomButton onClick={() => copyCodeToClipboard()}>
                            <Typography fontSize={contentFontSize_16}>
                              Copy Url
                            </Typography>
                          </CustomButton>
                        </div>
                      </InputAdornment>
                    }
                  ></CustomTextField>
                  <div style={{ justifyContent: "center", alignItems: "center" }}>
                    {copySuccess ? (
                      <div style={{ color: "#85BE49", paddingTop: "6px" }}>
                        <Typography fontSize={contentFontSize_16}>
                          Copied to Clipboard
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </div>
              </DialogContent>
            </CustomDialog>
            )}
          </div>

          <div
            className="readyfordeliveryBox"
          // style={{ marginLeft: matches ? "0px" : "50px" }}
          >
            <span>
              <Typography

                fontSize={contentFontSize_18}


                className="textStyleReady"
              >
                #{readyOrderDetails && readyOrderDetails.orderNumber} - Scheduled on{" "}
                {readyOrderDetails && readyOrderDetails.deliveryDate},{" "}
                {readyOrderDetails && readyOrderDetails.deliveryTime}
              </Typography>
            </span>
          </div>

          {/* <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{ marginTop: "40px" }}
      >
        {console.log(data)}
        {data &&
          data.length > 0 &&
          data.map((order, index) => (
            <>
              {order.products &&
                order.products.length > 0 &&
                order.products.map((item, i) => (
                  <>
                    <Grid item xs={2} lg={3}>
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          
                        }}
                        src={Pointer}
                        alt={item?.name}
                      />
                    </Grid>
                    <Grid item xs={7} lg={6}>
                      <Typography
                        className="itemDetails"
                        fontSize={contentFontSize_18}
                      >
                        {item.name}
                        <br />
                        <Typography className="itemDetails">
                          {item.quantity}X {item?.name}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Typography
                        className="itemDetails"
                        fontSize={contentFontSize_18}
                      >
                        ₹{item?.quantity * item?.price}
                      </Typography>
                    </Grid>
                  </>
                ))}
            </>
          ))}
      </Grid> */}

          {/* <Grid container justifyContent="space-between" alignItems="center" spacing={2} marginTop="40px">
  
      <>
        {readyOrderDetails&&readyOrderDetails?.products?.map((item) => (
            <>
              <Grid item xs={6} md="auto" marginLeft={matchessm?"0px":"70px"}>
                <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', gap:matchessm?"20px":"80px" }}>
                  <div className="itemDetails" style={{ color: "#000000", cursor: 'pointer', fontWeight: "500", fontFamily: "Nunito" }}>
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      src={Pointer}
                      alt={item?.name}
                    />
                  </div>
                  <div className="itemDetails"
                    // onClick={() => {
                    //   handleoutForDelivery();
                    //   handleTotalColorChange();
                    // }}
                    style={{ color: "#000000", cursor: 'pointer', fontWeight: '500', fontFamily: "Roboto" }}
                  >
                    <Typography fontSize= { contentFontSize_18 }>{item.productid.productname}<br />
                    {item.quantity}X {item.productid.productname}
                    </Typography>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={3} md={2} sm={3} >
                <Typography
                  component="div"
                  sx={{
                    fontWeight: "700",
                    cursor: "pointer",
                    fontFamily: "Roboto",
                    
                    // textAlign: xsmatch ? "right" : matches? "right": matchessm?"right": "right"
                  }}
                  fontSize={contentFontSize_18}
                >
                  ₹{item?.quantity * item?.productPrice}
                </Typography>
              </Grid>
            </>
          ))}
      </>
    
</Grid> */}

          {/* <Grid
        container
        spacing={2}
        sx={{
          marginTop: "20px",
          marginLeft:matchessm?"0px":matches?"15px":"50px"
        }}
      >
        {readyOrderDetails &&
          readyOrderDetails?.products?.map((item) => (
            <>
              <Grid item xs={2} md={1} lg={1} sm={1} className="customReady" sx={{paddingLeft:"0px"}}>
                <div className="productDetails">
                  <img
                    style={{
                      width: matchessm ? "15px" : "20px",
                      height: matchessm ? "15px" : "20px",
                    }}
                    src={Pointer}
                    alt={item?.name}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={8} lg={8} sm={8} className="customReady">
                <div>
                  <Typography
                    fontSize={contentFontSize_18}
                    className="productDetails"
                  >
                    {item.productid.productname}
                    <br />
                    <Typography
                      fontSize={contentFontsize_14}
                      sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                    >
                      {item.shopAssistantQuantity}X ₹{(item?.productPrice).toFixed(2)}
                    </Typography>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4} md={3} lg={3} sm={3} className="customReady">
                <div>
                  <Typography
                    className="productDetails"
                    fontSize={contentFontSize_18}
                  >
                    
                    ₹{(item?.shopAssistantQuantity * item?.productPrice)?.toFixed(2)}
                  </Typography>
                </div>
              </Grid>
            </>
          ))}
      </Grid> */}


          <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px", padding: smmatch ? "10px 10px" : "10px 70px" }}>
            {readyOrderDetails &&
              readyOrderDetails?.products?.map((item) => (
                <>
                {item.shopAssistantQuantity === 0 ? (
                  <div style={{ display: "flex",color:"grey" }}>
                  {/* {console.log("item vannu", item)} */}
                  <div style={{ flex: "1" }}>
                    <div style={{ display: "flex", gap: "60px" }}>
                      <div className="productDetails">
                        <img
                          style={{
                            width: matchessm ? "15px" : "20px",
                            height: matchessm ? "15px" : "20px",
                            marginTop:"0px",
                          }}    
                          src={Pointer}
                          alt={item?.name}
                        />
                      </div>

                      <div>
                        <Typography
                          fontSize={contentFontsize_17}
                          className="productDetails"
                        >
                          {item.productid?.productname}
                          <br />
                          <Typography
                            fontSize={contentFontsize_14}
                            sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                          >
                            {item.shopAssistantQuantity}X ₹{(item?.productPrice)?.toFixed(2)}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                        {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                        <p
                          className="productDetails"
                          fontSize={contentFontsize_17}
                        >

                          ₹{(item?.shopAssistantQuantity * item?.productPrice)?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                ):(
                  <div style={{ display: "flex" }}>
                    {/* {console.log("item vannu", item)} */}
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", gap: "60px" }}>
                        <div className="productDetails">
                          <img
                            style={{
                              width: matchessm ? "15px" : "20px",
                              height: matchessm ? "15px" : "20px",
                            }}
                            src={Pointer}
                            alt={item?.name}
                          />
                        </div>

                        <div>
                          <Typography
                            fontSize={contentFontsize_17}
                            className="productDetails"
                          >
                            {item.productid?.productname}
                            <br />
                            <Typography
                              fontSize={contentFontsize_14}
                              sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                            >
                              {item.shopAssistantQuantity}X ₹{(item?.productPrice)?.toFixed(2)}
                            </Typography>
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                          {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                          <p
                            className="productDetails"
                            fontSize={contentFontsize_17}
                          >

                            ₹{(item?.shopAssistantQuantity * item?.productPrice)?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                </>
              ))}


          </div>



          {/* <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div >
            <Typography
              fontSize={contentFontSize_16}
              className="productDetails"
            >
              Total Product price
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "25px",
          }}
        >
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            ₹{readyOrderDetails&&readyOrderDetails.totalPrice}
          </Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div >
            <Typography
              fontSize={contentFontSize_16}
              className="productDetails"
            >
              Delivery Charge
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "25px",
          }}
        >
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            {" "}
            ₹{readyOrderDetails&&readyOrderDetails.deliveryCharge}
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{ color: "#2D93FB" }}
          >
            <Typography
              fontSize={contentFontSize_16}
              className="productDetails"
            >
              {" "}
              Coupon - (GRUB)
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
        <Grid
          item
          // xs={10}
          // md={10}
          // sx={{
          //   textAlign: "right",
          //   marginRight: "50px",
          //   fontWeight: "bold",
          //   color: "#2D93FB",
          // }}
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{
            textAlign: "right",
            color: "#2D93FB",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "25px",
          }}
        >
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            -₹{couponDiscount}
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div >
            <Typography
              fontSize={contentFontSize_16}
              className="productDetails"
            >
              {" "}
              Packing Charges
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "25px",
          }}
        >
          <Typography className="productDetails" sx={{ fontWeight: "500" }}>
            {" "}
            ₹ {readyOrderDetails&&readyOrderDetails.packingCharge}
          </Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={8} lg={12}>
          <img
            sx={{ width: "100%" }}
            src={LineInReadyForDel}
            alt="LineInReadyForDel"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div >
            <Typography
              fontSize={contentFontSize_16}
              className="productDetails"
            >
              {" "}
              Total
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          sx={{
            textAlign: "right",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "25px",
          }}
        >
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            ₹{readyOrderDetails&&readyOrderDetails.totalPayable}
          </Typography>
        </Grid>
      </Grid> */}
          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12} sx={{ marginTop: "20px" }}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          {/* <Grid
        container
        spacing={2}
        sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "50px" }}
      >
        <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
          <Typography fontSize={contentFontSize_16} className="productDetails">
            Total Product price
          </Typography>
        </Grid>

        <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            ₹{totalProductPrice.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "50px" }}
      >
        <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
          <Typography fontSize={contentFontSize_16} className="productDetails">
            Delivery Charge
          </Typography>
        </Grid>

        <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            ₹{readyOrderDetails && readyOrderDetails.deliveryCharge.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "50px" }}
      >
        <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
          <Typography
            fontSize={contentFontSize_16}
            sx={{ color: "#2D93FB" }}
            className="productDetails"
          >
            Coupon - (GRUB)
          </Typography>
        </Grid>

        <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
          <Typography className="productDetails" fontSize={contentFontSize_16}>
          -₹{readyOrderDetails?.discountPrice.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "50px" }}
      >
        <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
          <Typography fontSize={contentFontSize_16} className="productDetails">
            Packing Charges
          </Typography>
        </Grid>

        <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
          <Typography className="productDetails" fontSize={contentFontSize_16}>
            {" "}
            ₹{readyOrderDetails && readyOrderDetails.packingCharge.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>




      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12} sx={{marginTop:"20px"}}>
          <img
            style={{ width: "100%" }}
            src={LineInReadyForDel}
            alt="LineInReadyForDel"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "50px" }}
      >
        <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
          <Typography
            fontSize={contentFontSize_20_xs_18}
            sx={{ fontWeight: "700", fontFamily: "Roboto" }}
          >
            Total
          </Typography>
        </Grid>

        <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto" }}
            fontSize={contentFontSize_16}
          >
            ₹{(grandTotal).toFixed(2)}
          </Typography>
        </Grid>
      </Grid> */}



          <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Total Product price
              </Typography>
              {/* </Grid> */}
              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              >
                ₹{totalProductPrice?.toFixed(2)}
              </Typography>
            </div>
            {console.log("boolean",readyOrderDetails?.deliveryBy === null || readyOrderDetails?.deliveryBy === 1)}
            {readyOrderDetails?.version === 3 && readyOrderDetails?.deliveryBy === null || readyOrderDetails?.deliveryBy === 1 ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Delivery Charge
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
              >
                ₹ {readyOrderDetails?.deliveryChargeWithoutMarkup?.toFixed(2)}
              </Typography>
            </div>
            ):(
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Delivery Charge
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
              >
                ₹ {readyOrderDetails?.deliveryCharge?.toFixed(2)}
              </Typography>
            </div>
            )}
          {readyOrderDetails?.discountPrice && readyOrderDetails?.couponID?.storeid !== null ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                sx={{ color: "rgba(45, 147, 251, 1)" }}
                className="productDetails1" 
              >
                Coupon - ({readyOrderDetails?.couponID?.couponCode})
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                className="productDetails1"
                sx={{ color: "rgba(45, 147, 251, 1)" }}
                fontSize={contentFontSize_16}
              >
                -₹{readyOrderDetails?.discountPrice?.toFixed(2)}
              </Typography>
            </div>
            ):(null)}
            {readyOrderDetails?.version === 3 ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Packing Charges
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              // sx={{marginLeft:"22px"}}
              >
                ₹ {packingCharge?.toFixed(2)}
              </Typography>
            </div>
            ):(
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Packing Charges
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              // sx={{marginLeft:"22px"}}
              >
                ₹ {readyOrderDetails?.packingCharge?.toFixed(2)}
              </Typography>
            </div>
            )}
          </div>
          {/* </Grid>
        </Grid> */}
          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "60px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}> */}
          <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_20_xs_18}
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              >
                Total
              </Typography>
              {/* </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                fontSize={contentFontSize_16}
              >
                ₹{grandTotal.toFixed(2)}
              </Typography>
            </div>
          </div>




          <div style={{ marginTop: "20px" }}>
            <Typography style={{ fontWeight: "600" }} fontSize={contentFontSize_20}>
              Upload Product Images<span style={{ color: "#EA4335" }}>*</span>
            </Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            {/* <NewImg/> */}
            {/* <Upload
          maxCount={1}
          
        >
         <img src={Camera}/>
        </Upload> */}
            <MultiImgOrder {...props} />

          </div>
          {readyOrderDetails?.paymentStatus === 0 ? (
          <div
            style={{ marginTop: "20px", marginLeft: smmatch ? "0px" : "30px" }}
          >
            <Typography style={{ color: "#1D89E1" }}>
              <img
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={BlueClock}
              />
              Waiting for Payment
            </Typography>
          </div>
          ):(
            <div
            style={{ marginTop: "20px", marginLeft: smmatch ? "0px" : "30px" }}
          >
            <Typography style={{ color: "#1D89E1" }}>
              <img
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={BlueClock}
              />
              Payment Completed
            </Typography>
          </div>
          )}
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
          >
            <CustomButton
              width="350px"
              height="50px"
              borderRadius="20px"
              onClick={handleOutForDelivery}
            >
              <Typography fontSize={contentFontSize_18} className="deliveryButton">
                {" "}
                Out For Delivery
              </Typography>
            </CustomButton>
          </div>
        </>
      )}
      {loading && (
        <LoaderReady />
      )}
    </div>
  );
}

export default ReadyForDelivery;
