// import { React, useEffect, useState } from "react";
// import About from "../../utils/Icon/About us 1.svg";
// import "./Landing.css";
// import {
//   Typography,
//   useMediaQuery,
//   useTheme,
//   Dialog,
//   Button,
//   Card,
// } from "../MUIComponents/index";
// import {
//   HeaderNavigationFontSize_20,
//   contentFontSize_18,
//   ButtonFontSize_18,
//   HeaderfontSize_30,
//   contentFontSize_15,
//   HeaderFontsize_36,
//   ButtonFontSize_16,
// } from "../Typography";
// import CustomButton from "../Commoncomponents/button";
// import AOS from "aos";
// import "aos/dist/aos.css";


// function LandingCards() {
//   const [animationTriggered, setAnimationTriggered] = useState(false);
//   const [learn, setLearn] = useState(false);

//   const handleLearnClick = () => {
//     setLearn(true);
//   };
//   const handleClose = () => {
//     setLearn(false);
//   };

//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down("md"));
//   const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
//   const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
//   const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
//   const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
//   const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
//   const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

//   useEffect(() => {
//     AOS.init({
//       once: true,           
//       disable: "phone",      
//       duration: 700,        
//       easing: "ease-out-cubic", 
//     });
//     AOS.refresh(); 
//   }, []);

//   return (
//     <>
//       <div className="container_12" id="aboutus">
//         <div data-aos="slide-right" className="image-container">
//           <img src={About} alt="Sell your products online" />
//         </div>
//         <div data-aos="slide-left" className="card-container1">
//           <Card
//             height={"100%"}
//             className="content-cardabout"
//             sx={{
//               // overflowY: "auto",
//               // height: xlmatch && lgmatch && mdmatch ? "70%": "65%",
//               paddingTop: "50px",
//               background: "#F2F2F2",
//               borderRadius: "20px",
//               boxShadow: "none",
//               width:"100%"
//             }}
//           >
//             <div>
//               <Typography className="font-land" fontSize={HeaderfontSize_30}>
//                 About Us
//               </Typography>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "30px",
//                 marginTop: "15%",
//               }}
//             >
//               <Typography className="font-land1" fontSize={contentFontSize_18}>
//                 Looking to sell your products online and grow your business?
//               </Typography>
//               <div style={{ textAlign: "justify" }}>
//                 <Typography
//                   fontSize={HeaderNavigationFontSize_20}
//                   className="font-para"
//                 >
//                   Nearshopz is an online platform for local shop owners, ranging
//                   from grocery to even pet stores, to create an online presence,
//                   sell products online and offer hyper-local delivery service to
//                   local customers. Our user-friendly web and mobile-based
//                   eCommerce applications are designed to simplify the process,
//                   effortlessly showcasing and selling your products. NearShopz
//                   caters to all local businesses. Whether you run a grocery
//                   store, restaurant, a pet shop, or anything in between, we have
//                   the tools you need to succeed online.
//                 </Typography>
//               </div>
//               <Typography className="font-land1" fontSize={contentFontSize_18}>
//                 {" "}
//                 Local Business Online Success Starts Here: Join NearShopz!{" "}
//               </Typography>
//             </div>
//             <div style={{ marginTop: "120px", textAlign: "center" }}>
//               <Button
//                 className="custom-button1"
//                 sx={{
//                   width: {
//                     xs: "40px",
//                     sm: "60px",
//                     md: "80px",
//                     lg: "130px",
//                     xl: "160px",
//                     upXl: "170px",
//                     "&:focus": {
//                       outline: "none",
//                     },
//                   },
//                   height: "40px",
//                   borderRadius: "20px",
//                   background:
//                     "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
//                 }}
//               >
//                 <Typography
//                   fontSize={ButtonFontSize_16}
//                   className="Add-product-btn"
//                   onClick={handleLearnClick}
//                 >
//                   Learn More
//                 </Typography>
//               </Button>
//             </div>
//           </Card>
//         </div>
//       </div>
//       <>
//         {learn && (
//           <>
//             <Dialog
//               maxHeight="70%"
//               borderRadius="20px"
//               open={learn}
//               titleAlign="center"
//               padding="30px"
//               sx={{
//                 "& .MuiDialog-container": {
//                   "& .MuiPaper-root": {
//                     width: "100%",
//                     maxWidth: "800px",
//                     // Set your width here
//                     padding: "10px 10px 30px 10px",
//                     borderRadius: "20px",
//                   },
//                 },
//               }}
//             >
//               <div style={{ display: "flex", flexDirection: "column" }}>
//                 <div
//                   style={{
//                     paddingTop: "20px",
//                     display: "flex",
//                     // justifyContent: "center",
//                     // alignItems: "center",
//                     // height: "100%",
//                   }}
//                 ></div>
//                 <div style={{ display: "flex", flexDirection: "column" }}>
//                   <div
//                     style={{ marginTop: "20px", display: "flex", gap: "10px" }}
//                   >
//                     <Typography
//                       fontSize={HeaderFontsize_36}
//                       className="headerstylelearn"
//                     >
//                       Why
//                     </Typography>
//                     <Typography
//                       fontSize={HeaderFontsize_36}
//                       className="headerstylelearn1"
//                     >
//                       NearShopz?
//                     </Typography>
//                   </div>
//                   <div>
//                     <Typography
//                       fontSize={contentFontSize_15}
//                       className="content-card-1"
//                     >
//                       Welcome to NearShopz, where local business ecommerce is
//                       made easy! <br />
//                       <br />
//                       We understand that managing an online shop can be
//                       challenging, which is why we’ve created a platform that
//                       makes it easy to manage your business, increase sales, and
//                       offer hyper-local delivery services.
//                       <br />
//                       <br /> Break free from local market limitations and reach
//                       a whole new audience of potential customers actively
//                       searching online.
//                       <br />
//                       <br />
//                       Effortlessly manage your online store, showcase your
//                       products to a wider audience of local customers actively
//                       searching online, and boost sales with our built-in tools.
//                       <br />
//                       <br />
//                       Whether you're launching your first online store or
//                       expanding an established brand, our platform scales to
//                       your needs. We're the perfect partner for your online
//                       journey.
//                       <br />
//                       <br /> Sign up for NearShopz today and start selling
//                       online, unlocking a new avenue for your local business
//                       growth!
//                     </Typography>
//                   </div>
//                 </div>
//                 <div>
//                   <div style={{ paddingTop: "20px", textAlign: "center" }}>
//                     <CustomButton
//                       sx={{
//                         "&:focus": {
//                           outline: "none",
//                           boxShadow: "0 0 0 3px rgba(0,123,255,0.5)",
//                         },
//                       }}
//                       width={
//                         upXlmatch
//                           ? "160px"
//                           : xlmatch
//                           ? lgmatch
//                             ? mdmatch
//                               ? smmatch
//                                 ? xsmatch
//                                   ? "40px"
//                                   : "60px"
//                                 : "80px"
//                               : "100px"
//                             : "150px"
//                           : "160px"
//                       }
//                       height={"40px"}
//                       // borderRadius="20px"
//                       background={
//                         "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
//                       }
//                       onClick={handleClose}
//                     >
//                       <Typography
//                         fontSize={ButtonFontSize_18}
//                         className="Add-product-btn"
//                       >
//                         Close
//                       </Typography>
//                     </CustomButton>
//                   </div>
//                 </div>
//               </div>
//             </Dialog>
//           </>
//         )}
//       </>
//     </>
//   );
// }

// export default LandingCards;

// import { React, useEffect, useState } from "react";
// import About from "../../utils/Icon/About us 1.svg";
// import "./Landing.css";
// import {
//   Typography,
//   useMediaQuery,
//   useTheme,
//   Dialog,
//   Button,
//   Card,
// } from "../MUIComponents/index";
// import {
//   HeaderNavigationFontSize_20,
//   contentFontSize_18,
//   ButtonFontSize_18,
//   HeaderfontSize_30,
//   contentFontSize_15,
//   HeaderFontsize_36,
//   ButtonFontSize_16,
// } from "../Typography";
// import CustomButton from "../Commoncomponents/button";
// import AOS from "aos";
// import "aos/dist/aos.css";

// function LandingCards() {
//   const [learn, setLearn] = useState(false);
//   const theme = useTheme();
//   const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   useEffect(() => {
//     AOS.init({
//       once: true,
//       disable: "phone",
//       duration: 700,
//       easing: "ease-out-cubic",
//     });
//     AOS.refresh();
//   }, []);

//   const handleLearnClick = () => setLearn(true);
//   const handleClose = () => setLearn(false);

//   return (
//     <>
//       <div className="container_12" id="aboutus">
//         {!isTablet && (
//           <div data-aos="slide-right" className="image-container">
//             <img src={About} alt="Sell your products online" />
//           </div>
//         )}
//         <div data-aos="slide-left" style={{ padding: "20px 50px 15px 80px",
//     flex: "1",
//     display: "flex",
//     justifyContent: isTablet ? "flex-start": "flex-end",
//     alignItems: "center",
//     overflow: "hidden",
//     }}>
//           <Card
//             className="content-cardabout"
//             sx={{
//               paddingTop: "50px",
//               background: "#F2F2F2",
//               borderRadius: "20px",
//               boxShadow: "none",
//               width: isTablet ? "60%" :"100%",
//             }}
//           >
//             <Typography className="font-land" fontSize={HeaderfontSize_30}>
//               About Us
//             </Typography>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "30px",
//                 marginTop: "15%",
//               }}
//             >
//               <Typography className="font-land1" fontSize={contentFontSize_18}>
//                 Looking to sell your products online and grow your business?
//               </Typography>
//               <Typography
//                 fontSize={HeaderNavigationFontSize_20}
//                 className="font-para"
//                 style={{ textAlign: "justify" }}
//               >
//                 Nearshopz is an online platform for local shop owners, from grocery to pet stores, to create an online presence, sell products, and offer hyper-local delivery. Our web and mobile apps make it easy to showcase your products and reach more customers.
//               </Typography>
//               <Typography className="font-land1" fontSize={contentFontSize_18}>
//                 Local Business Online Success Starts Here: Join NearShopz!
//               </Typography>
//             </div>
//             <div style={{ marginTop: "120px", textAlign: "center" }}>
//               <Button
//                 className="custom-button1"
//                 onClick={handleLearnClick}
//                 sx={{
//                   width: {
//                     xs: "40px",
//                     sm: "60px",
//                     md: "80px",
//                     lg: "130px",
//                     xl: "160px",
//                   },
//                   height: "40px",
//                   borderRadius: "20px",
//                   background: "linear-gradient(to right, #13801C, #FFFFFF)",
//                 }}
//               >
//                 <Typography fontSize={ButtonFontSize_16}>
//                   Learn More
//                 </Typography>
//               </Button>
//             </div>
//           </Card>
//         </div>
//       </div>

//       {learn && (
//         <Dialog
//           open={learn}
//           onClose={handleClose}
//           sx={{
//             "& .MuiDialog-container": {
//               "& .MuiPaper-root": {
//                 width: "100%",
//                 maxWidth: "800px",
//                 padding: "10px 10px 30px 10px",
//                 borderRadius: "20px",
//               },
//             },
//           }}
//         >
//           <div style={{ padding: "20px" }}>
//             <Typography fontSize={HeaderFontsize_36} className="headerstylelearn">
//               Why NearShopz?
//             </Typography>
//             <Typography fontSize={contentFontSize_15} className="content-card-1">
//               Welcome to NearShopz, where local business e-commerce is made easy! <br /><br />
//               We understand managing an online shop can be challenging, so we created a platform that simplifies managing your business, increasing sales, and offering local delivery.
//               <br /><br />
//               Reach a broader audience and grow with NearShopz!
//             </Typography>
//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <CustomButton
//                 onClick={handleClose}
//                 sx={{
//                   background: "linear-gradient(to right, #13801C, #FFFFFF)",
//                 }}
//               >
//                 <Typography fontSize={ButtonFontSize_18}>Close</Typography>
//               </CustomButton>
//             </div>
//           </div>
//         </Dialog>
//       )}
//     </>
//   );
// }

// export default LandingCards;


import React, { useEffect, useState, useRef } from "react";
import About from "../../utils/Icon/About us 1.svg";
import "./Landing.css";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  Button,
  Card,
} from "../MUIComponents/index";
import {
  HeaderNavigationFontSize_20,
  contentFontSize_18,
  ButtonFontSize_18,
  HeaderfontSize_30,
  contentFontSize_15,
  HeaderFontsize_36,
  ButtonFontSize_16,
} from "../Typography";
import CustomButton from "../Commoncomponents/button";

function LandingCards() {
  const [learn, setLearn] = useState(false);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [color, setColor] = useState("#13801C");
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const imageContainerRef = useRef(null);
  const contentCardRef = useRef(null);

  

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show"); // Add 'show' class to trigger animation
        }
      });
    }, observerOptions);

    if (imageContainerRef.current) observer.observe(imageContainerRef.current);
    if (contentCardRef.current) observer.observe(contentCardRef.current);

    return () => {
      if (imageContainerRef.current) observer.unobserve(imageContainerRef.current);
      if (contentCardRef.current) observer.unobserve(contentCardRef.current);
    };
  }, []);

  const handleLearnClick = () => setLearn(true);
  const handleClose = () => setLearn(false);

  return ( 
    <>
      <div className="container_12" id="aboutus">
        {!isTablet && (
          <div ref={imageContainerRef} className="image-container">
            <img src={About} alt="Sell your products online" className="imageSize" />
          </div>
        )}
        <div
          style={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            //justifyContent:"center",
          }}
        >
          <Card
            ref={contentCardRef}
            className="content-cardabout"
            sx={{
              paddingTop: "50px",
              background: "#F2F2F2",
              borderRadius: "20px",
              boxShadow: "none",
              
              width: lgmatch ? "85%" : mdmatch ? "65%": smmatch ? "40%" : xsmatch ? "30%": "97%"
            }}
          >
            <Typography className="font-land" fontSize={HeaderfontSize_30}>
              About Us
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                marginTop: "15%",
              }}
            >
              <Typography className="font-land1" fontSize={contentFontSize_18}>
                Looking to sell your products online and grow your business?
              </Typography>
              <Typography
                fontSize={HeaderNavigationFontSize_20}
                className="font-para"
                style={{ textAlign: "justify" }}
              >
                Nearshopz is an online platform for local shop owners, from grocery to pet stores, to create an online presence, sell products, and offer hyper-local delivery. Our web and mobile apps make it easy to showcase your products and reach more customers.
              </Typography>
              <Typography className="font-land1" fontSize={contentFontSize_18}>
                Local Business Online Success Starts Here: Join NearShopz!
              </Typography>
            </div>
            <div style={{ marginTop: "120px", textAlign: "center" }}>
              <Button
                className="custom-button1"
                onClick={handleLearnClick}
                sx={{
                  width: {
                    xs: "50px",
                    sm: "70px",
                    md: "80px",
                    lg: "130px",
                    xl: "160px",
                  },
                  height: "40px",
                  borderRadius: "20px",
                  background: "linear-gradient(to right, #13801C, #FFFFFF)",
                }}
              >
                <Typography fontSize={ButtonFontSize_16}  className="Add-product-btn">
                  Learn More
                </Typography>
              </Button>
            </div>
          </Card>
        </div>
      </div>

      {learn && (
        <Dialog
          open={learn}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
                padding: "10px 10px 30px 10px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <div style={{ padding: "20px" }}>
            <Typography fontSize={HeaderFontsize_36} className="headerstylelearn">
              Why NearShopz?
            </Typography>
            <Typography fontSize={contentFontSize_15} className="content-card-1">
              Welcome to NearShopz, where local business e-commerce is made easy! <br /><br />
              We understand managing an online shop can be challenging, so we created a platform that simplifies managing your business, increasing sales, and offering local delivery.
              <br /><br />
              Reach a broader audience and grow with NearShopz!
            </Typography>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <CustomButton
                onClick={handleClose}
                sx={{
                  background: "linear-gradient(to right, #13801C, #FFFFFF)",
                }}
              >
                <Typography fontSize={ButtonFontSize_18}>Close</Typography>
              </CustomButton>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default LandingCards;

