// import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';
// import Chip from '@mui/material/Chip';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const Business = [

//     {
//       id: 1,
//       name: "Groceries & Essentials",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Groceries+%26+Essentials.png"
//     },
//     {
//       id: 2,
//       name: "Restaurants & Bakery",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg"
//     },
//     {
//       id: 3,
//       name: "Fish & Meat",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg"
//     },
//     {
//       id: 4,
//       name: "Fruits & Vegetables",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Fruits+%26+Vegetables.png"
//     },
//     {
//       id: 5,
//       name: "Medicines",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Medicines.webp"
//     },
//     {
//       id: 6,
//       name: "Electronics & Home Appliances",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Electronics+%26+Home+Appliances.jpg"
//     },
//     {
//       id: 7,
//       name: "Lifestyle & Fashion",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Lifestyle+%26+Fashion.jpg"
//     },
//     {
//       id: 8,
//       name: "Sports & Fitness",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/sports+%26+fitness.jpg"
//     },
//     {
//       id: 9,
//       name: "Pet Care",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Pet+Care.jpg"
//     },
//     {
//       id: 10,
//       name: "Home decor & Furnishing",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+decor+%26+Furnishing.png"
//     },
//     {
//       id: 11,
//       name: "Home Business",
//       coverImage: "imageUrl",
//       iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+Business.jpg"
//     }
//   ];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }
//  class BusinessType extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			BusinessType:[]

// 		};
// 	}

//   render(){

//     const handleChange = (event) => {
//         const {
//           target: { value },
//         } = event;
//         this.setState({BusinessType:
//           // On autofill we get a stringified value.
//           typeof value === 'string' ? value.split(',') : value},
//           ()=>{
//             this.props.setBusinessType(this.state.BusinessType);
//             this.props.setBusinessError(false);
//         }
//         )

//       };

//     return(
//     <div>
//       <FormControl sx={{ m: 1, width: {md:300,xs:'100%'} }}>
//         <InputLabel id="demo-multiple-chip-label">{'Business Type'}</InputLabel>
//         <Select
//           labelId="demo-multiple-chip-label"
//           id="demo-multiple-chip"
//           multiple
//           label="Business Type"
//           value={this.props.businessId}
//           onChange={handleChange}
//           input={<OutlinedInput id="select-multiple-chip" label="Business Type"  />}
//           renderValue={(selected) => (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((value) => (
//                 <Chip key={value}
//                   label={Business?.find(e => e.id === value).name}
//                 // label={value}
//                  />
//               ))}
//             </Box>
//           )}
//           MenuProps={MenuProps}
//         >
//     {this.props.BusinessType&&this.props.BusinessType.data&&this.props.BusinessType.data.businessTypeListing.map((b)=>
//      <MenuItem key={b.id} value={b.id}>
//      <Checkbox checked={this.props.businessId.includes(b.id)} />
//      <ListItemText primary={b.name} />
//    </MenuItem>
//     //  <MenuItem value={b.id} style={{ width: '100%' }}>{b.name}</MenuItem>
//     )}
//         </Select>
//         {
//             this.props.businessError===true?
//             <p style={{color:'red'}}>Please select atleast one business type</p>
//             :
//             null
//         }
//       </FormControl>
//     </div>
//     )
//   }
// }

// export default BusinessType;

//new code

import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Box,
  OutlinedInput,
  ListItemText,
  CloseIcon,
  IconButton,
  Grid,
} from "../MUIComponents/index";
import CustomButton from "../Commoncomponents/button";
import Chip from "@mui/material/Chip";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme, Typography } from "../MUIComponents/index";
import { contentFontSize_16 } from "../Typography/index";
import {
  contentFontSize_18_xs_13,
  contentFontSize_16_xs_10,
} from "../Typography/index";
import { Alert } from "../MUIComponents/index";
import { Stack } from "../MUIComponents/index";
import { message } from "antd";

export default function BusinessType({
  props,
  businessType = [],
  setBusinessType,
}) {
  const [tempBussiness, setTempBussiness] = useState(() => businessType);

  useEffect(() => {
    setTempBussiness(businessType);
  }, [businessType]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    //setBusinessType(value);
    setTempBussiness(value);
    //props.setBusinessType(value);
    props.setBusinessError(false);
  };

  function handleConfirm() {
    setBusinessType(tempBussiness);
    props.setBusinessType(tempBussiness);
    message.success({
      content: "Department Added Successfully",
      className: "custom-class",
      style: {
        marginTop: "10vh",
        marginLeft: "15vw",
      },
      duration: 3.5,
    });

    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 4000);
  }

  const handleRemove = (id) => {
    const updatedBusinessId = businessType.filter((item) => item !== id);
    setBusinessType(updatedBusinessId);
    props.setBusinessType(updatedBusinessId);
  };

  const Business = [
    {
      id: 1,
      name: "Supermarkets",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Groceries+%26+Essentials.png",
    },
    {
      id: 2,
      name: "Restaurants",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg",
    },
    {
      id: 3,
      name: "Bakery",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg",
    },
    {
      id: 4,
      name: "Fish & Meat",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg",
    },
    {
      id: 5,
      name: "Home Bakery",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg",
    },
    {
      id: 6,
      name: "Fruits & Vegetables",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Fruits+%26+Vegetables.png",
    },
    {
      id: 7,
      name: "Pet Care",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Pet+Care.jpg",
    },
    {
      id: 8,
      name: "Home Business",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+Business.jpg",
    },
    {
      id: 9,
      name: "Electronics",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Electronics+%26+Home+Appliances.jpg",
    },
    {
      id: 10,
      name: "Lifestyle & Fashion",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Lifestyle+%26+Fashion.jpg",
    },
    {
      id: 11,
      name: "Sports & Fitness",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/sports+%26+fitness.jpg",
    },
    {
      id: 12,
      name: "Home Decor",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+decor+%26+Furnishing.png",
    },
    {
      id: 13,
      name: "Medicines",
      coverImage: "imageUrl",
      iconImage:
        "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Medicines.webp",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {/* <FormControl
        fullWidth
        sx={{ borderRadius: "10px", background: "white" }}
        size={props.size}
      >
        <Select
          // multiple
          value={tempBussiness}
          onChange={handleChange}
          // input={<OutlinedInput label="Tag" />}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={() => ""}
          // renderValue={(selected) => (
          //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          //     {selected.map((value) => (
          //       <Chip key={value} label={Business.find(e => e.id === value).name} />
          //     ))}
          //   </Box>
          // )}
          // MenuProps={{
          //   disableScrollLock: true,
          //   marginThreshold: null,
          //   open: isDropdownOpen,
          // }}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#85BE49",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#85BE49",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#85BE49",
            },
            // '.MuiSvgIcon-root ': {
            //   fill: "white !important",
            // }
          }}
          open={isDropdownOpen}
          size={matches ? "small" : "normal"}
          InputProps={{
            sx: {
              fontSize: matches && "13px",
            },
          }}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          disableScrollLock
        >
          {props.BusinessType &&
            props.BusinessType.data &&
            props.BusinessType.data.businessTypeListing.map((b) => (
              <MenuItem key={b.id} value={b.id}>
                <Checkbox checked={tempBussiness.includes(b.id)} />
                <ListItemText primary={b.name} />
              </MenuItem>
            ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <CustomButton
              matchPadding={true}
              padding="10px"
              borderRadius="13px"
              onClick={handleConfirm}
            >
              <Typography
                fontSize={contentFontSize_18_xs_13}
                style={{ fontWeight: "700" }}
              >
                Confirm Department
              </Typography>
            </CustomButton>
          </div>
        </Select>
        {businessType?.length === 0 ? (
          <div style={{ background: "#F4F5F9" }}>
            {" "}
            <Stack sx={{ width: "100%", marginTop: "20px" }} spacing={2}>
              {" "}
              <Alert severity="warning">
                Please select at least one business type
              </Alert>
            </Stack>
          </div>
        ) : null}
      </FormControl> */}
      <div style={{ marginTop: "15px" }}>
        <Box
          mt={2}
          // style={{ display: "flex", gap: "20px", marginTop: "0px" }}
        >
          <Grid container spacing={3}>
            {businessType?.map((selectedType) => (
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <div
                  // style={{ display: "flex", gap: "20px" }}
                  key={selectedType.value}
                >
                  <CustomButton
                    variant="contained"
                    color={props.buttonColor}
                    background="#85BE49"
                    width="100%"
                    borderRadius="12.09px"
                    matchPadding={true}
                    padding="10px"
                    disabled={true}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: smmatch
                          ? "space-between"
                          : "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: smmatch && "20px",
                      }}
                    >
                      <Typography fontSize={contentFontSize_16}>
                        {Business.find((e) => e.id === selectedType).name}
                      </Typography>

                      {/* <IconButton onClick={() => handleRemove(selectedType)}>
                        <CloseIcon
                          style={{ color: "white", width: matches && "13px" }}
                        />
                      </IconButton> */}
                    </div>
                  </CustomButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
}
