import React, { useState, useEffect } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  InputAdornment,
  TextField,
} from "../MUIComponents/index";
import {
  HeaderFontSize_24,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_22,
  contentFontSize_16,
  contentFontSize_20,
  contentFontsize_14,
  ButtonFontSize_18,
} from "../Typography";
import CustomBadge from "../Commoncomponents/badge";
import { useAsyncError, useNavigate } from "react-router-dom";
import CustomCard from "../Commoncomponents/card";
import CustomTextField from "../Commoncomponents/input";
import SearchIcon from "../../utils/Icon/search.svg";
import "./index.css";
import CustomButton from "../Commoncomponents/button";
import View from "./View";

function Index(props) {
  const [newState, setNewState] = useState(true);
  const theme = useTheme();
  const [view, setView] = useState(false);
  const [offset, setOffset] = useState(0);
  const [first, setFirst] = useState(10);
  const [settlement, setSettlement] = useState([]);
  const [search, setSearch] = useState("");
  const [orderSearch, setOrderSearch] = useState(false);

  useEffect(() => {
    setOrderSearch(true);
    if (search === "") {
      props.getAllPendingSettlements({ offset, first });
    } else if (search.length >= 3) {
      const searchAsFloat = parseFloat(search);
      props.getAllPendingSettlements({ orderNumber: searchAsFloat });
    }
  }, [search]);

  useEffect(() => {
    props.getAllPendingSettlements();
  }, []);

  useEffect(() => {
    props.getAllCompletedSettlements();
  }, []);

  useEffect(() => {
    props.getAllPendingPayments();
  }, []);

  const [displayedSettlement, setDisplayedSettlement] = useState([]);

  useEffect(() => {
    const newOrders =
      props?.pendingSettlementData?.listAllPendingSettlements?.items || [];
    setSettlement(newOrders);
    setDisplayedSettlement(newOrders.slice(0, 10));
  }, [props.pendingSettlementData]);

  const nav = useNavigate();

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [selectedOrder, setSelectedOrder] = useState([]);

  const handleViewClick = (order) => {
    setView(true);
    setSelectedOrder(order);
  };

  const handleLoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    setDisplayedSettlement((prevDisplayed) =>
      prevDisplayed.concat(settlement.slice(newOffset, newOffset + 10))
    );
  };

  const handlePendingClick = () => {
    nav("/home/Payments/PendingDeposits");
  };

  const handleCompletedClick = () => {
    nav("/home/Payments/CompletedTransactions");
  };

  // console.log("idddd",settlement.id)
  //const [one,setOne] = useState(false)
  function handleOrder(id) {
    console.log("orderid", id);
    //setOne(true)
    const one = "hi";
    nav("/home/MyOrders/OrderHistoryDetails", {
      state: { orderid: id, one: one },
    });
  }
  // ,{ state: { orderid: orderid } }
  return (
    <>
      <div>
        <Typography
          style={{ fontFamily: "Roboto", fontWeight: "600" }}
          fontSize={HeaderNavigationFontSize_22}
        >
          Payment Details
        </Typography>
      </div>
      <div style={{ display: "flex", marginTop: "20px", gap: "100px" }}>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={newState ? "#659B1B" : "#646464"}
          fontSize={HeaderNavigationFontSize_20}
        >
          Settlements In-progress
          <CustomBadge
            badgeContent={
              props?.pendingSettlementData?.listAllPendingSettlements?.count
            }
            backgroundColor={newState ? "#659B1B" : "White"}
            color={newState ? "white" : "#659B1B"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "25px"}
            minWidth={mdmatch ? "15px" : "25px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "15px"}
          ></CustomBadge>
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            color: "#8B8989",
            cursor: "pointer",
          }}
          fontSize={HeaderNavigationFontSize_20}
          onClick={handlePendingClick}
        >
          Pending Deposits
          <CustomBadge
            badgeContent={
              props?.pendingPaymentData?.listAllPendingPaymentsBySeller?.count
            }
            backgroundColor={"#8B8989"}
            color={"white"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "25px"}
            minWidth={mdmatch ? "15px" : "25px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "15px"}
          ></CustomBadge>
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            color: "#8B8989",
            cursor: "pointer",
          }}
          fontSize={HeaderNavigationFontSize_20}
          onClick={handleCompletedClick}
        >
          Completed Transactions
          <CustomBadge
            badgeContent={
              props?.completedPaymentData?.listAllCompletedSettlements?.count
            }
            backgroundColor={"#8B8989"}
            color={"white"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "25px"}
            minWidth={mdmatch ? "15px" : "25px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "15px"}
          ></CustomBadge>
        </Typography>
      </div>
      {/* {displayedSettlement?.length > 0 && ( */}
      <div>
        <div style={{ marginTop: "40px" }}>
          <TextField
            placeholder="Search"
            onChange={(event) => {
              setSearch(event.target.value); // Update search value
            }}
            value={search}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D1D5DB",
                },
                borderRadius: "20px",
                "&.Mui-focused fieldset": {
                  borderColor: "#D1D5DB",
                },
              },
              "& .MuiInputBase-input": {
                backgroundColor: "transparent",
                borderRadius: "20px",
              },
            }}
            InputProps={{
              sx: {
                fontSize: matches && "13px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={SearchIcon}
                    alt="Search"
                    style={{ marginRight: "8px" }}
                  />
                </InputAdornment>
              ),
            }}
            size={matches ? "small" : "medium"}
          />
        </div>
        <div style={{ paddingTop: "20px", boxSizing: "border-box" }}>
          {props?.pendingSettlementData?.listAllPendingSettlements?.count !=
            0 &&
            props?.pendingSettlementData?.listAllPendingSettlements?.items?.map(
              (order, index) => (
                <Grid sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
                  <CustomCard
                    padding={"30px"}
                    width="100%"
                    height="100%"
                    borderLeft="8px solid #FF9900"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              className="Card-text"
                              fontSize={contentFontSize_20}
                              color={"#2D93FB"}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOrder(order.id)}
                            >
                              #{order?.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14}
                              className="Card-subtext"
                            >
                              {order?.transferSettlement[0]?.created}{" "}
                              {order?.transferSettlement[0]?.createdTime}
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "15px",
                            }}
                          >
                            <Typography
                              className="Card-price"
                              fontSize={contentFontSize_16}
                              color={"black"}
                            >
                              ₹{" "}
                              {order?.totalPayable != null
                                ? order.totalPayable.toFixed(2)
                                : "0.00"}
                            </Typography>
                          </div>
                        </Grid>

                        {/* Adjusted status Grid item */}
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "15px",
                              whiteSpace: "nowrap", // Ensure the text doesn't wrap
                              overflow: "hidden", // Prevent overflow
                              textOverflow: "ellipsis", // Add ellipsis if the text overflows
                            }}
                          >
                            <div className="dot"></div>
                            <Typography
                              className="Card-price"
                              fontSize={contentFontSize_16}
                              color={"black"}
                            >
                              {order?.transferSettlementStatus}
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                          <div
                            style={{
                              marginTop: "12px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomButton
                              width="60%"
                              height="32px"
                              onClick={() => handleViewClick(order)}
                            >
                              <Typography
                                style={{
                                  textTransform: "capitalize",
                                  marginBottom: "0px",
                                }}
                              >
                                View
                              </Typography>
                            </CustomButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                </Grid>
              )
            )}
        </div>
      </div>
      {/* )} */}
      {displayedSettlement?.length < settlement.length && (
        <div style={{ marginTop: "10px" }}>
          <CustomButton width={"20%"} borderRadius="10px">
            <Typography
              fontSize={ButtonFontSize_18}
              className="Add-product-btn"
              onClick={handleLoadMore}
            >
              Load More
            </Typography>
          </CustomButton>
        </div>
      )}
      {props?.pendingSettlementData?.listAllPendingSettlements?.count === 0 && (
        <div
          style={{
            marginTop: "8%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            fontSize={HeaderNavigationFontSize_20}
            className="Add-product-btn"
          >
            No settlement in progress.
          </Typography>
        </div>
      )}
      {orderSearch &&
        search.length >= 5 &&
        displayedSettlement.length === 0 && (
          <div
            style={{
              marginTop: "8%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={HeaderNavigationFontSize_20}
              className="Add-product-btn"
            >
              No Orders Found.
            </Typography>
          </div>
        )}
      {view && (
        <View
          props={props}
          view={view}
          setView={setView}
          settlement={settlement}
          selectedOrder={selectedOrder}
        />
      )}
    </>
  );
}

export default Index;
