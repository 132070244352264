// routeList.js
import React from 'react';
import RetailerEdit from "../Components/MyAccountForm/RetailerEdit";
import SellerPortal from "../Routes/SellerPortal";
import signUpNew from "../Routes/SignUpNew";
import Termsofuse from "../Routes/Termsofuse";
import Privacypolicy from "../Routes/Privacypolicy";
import CheckoutPage from '../Components/CheckoutPage';
import Terms from '../Components/Banner/Terms';
import About from '../Components/Banner/About';
import Privacy from '../Components/Banner/Privacy';
import Contact from '../Components/Banner/Contact';
import PaymentForm from '../Components/CheckoutPage/Forms/PaymentForm';
import Additional from '../Components/CheckoutPage/Forms/Additional';
import DocumentUpload from '../Components/CheckoutPage/Forms/DocumentUpload/DocumentUpload';
import Subscription from '../Components/CheckoutPage/Forms/Subscription';

const routesList = (state, props) => [
    {
        path: "/Register",
        element: <CheckoutPage {...state} {...props} />,
        exact: false,
    },
    {
        path: "/RegisterForm1",
        element: <PaymentForm {...state} {...props} />,
        exact: false,
    },
    {
        path: "/RegisterForm2",
        element: <Additional {...state} {...props} />,
        exact: false,
    },
    {
        path: "/RegisterForm3",
        element: <DocumentUpload {...state} {...props} />,
        exact: false,
    },
    {
        path: "/Subscription",
        element: <Subscription {...state} {...props} />,
        exact: false,
    },
    {
        path: "/Blogs",
        element: <About {...state} {...props} />,
        exact: false, 
    },
    {
        path: "/Terms&Conditions",
        element: <Terms {...state} {...props} />,
        exact: false, 
    },
    {
        path: "/Privacy&Policy",
        element: <Privacy {...state} {...props} />,
        exact: false, 
    },
    {
        path: "/Contact",
        element: <Contact {...state} {...props} />,
        exact: false, 
    },
    {
        path: "/seller-registration",
        element: <signUpNew {...state} {...props} />,
        exact: false,
    },
    {
        path: "/home/MyProfile",
        element: <RetailerEdit {...state} {...props} />,
        exact: false,
    },
    {
        path: "/privacy-policy",
        element: <Privacypolicy {...state} {...props} />,
        exact: true,
    },
    {
        path: "/termsofuse",
        element: <Termsofuse {...state} {...props} />,
        exact: true,
    },
    {
        path: "*",
        element: <SellerPortal {...state} {...props} />,
        exact: false,
    },
];

export default routesList;
