import SignIn from "./signIn";
import {
	getSellerDetail,
	textEditChanges,
	textStoreChanges,
	checkBoxEdit,
	scheduleEdit,
	uploadNewImage,
	postNewUrl,
	getNewLocation,
	getNewCoordinates,
	doorDeliveryEdit,
	changeMaxDistance,
	editDeliveryCharge,
	resetDetails,
	editRetailer,
	editStore,
	resetPassword,
	closeSnackBars,
	logoutHandler,
	AddProduct,
	AddProduct1,
	AddPrdt,
	uploadImage,
	uploadOrderImage,
	uploadProductImage,
	AddDepartment,
	UpdateDepartment,
	updateImage ,
	Delete_Image,
	getShopassistantlist,
	getShopassistantOrders,
	getShopassistantCompletedOrders,
	getOrderDetails,
	AssignOrder,
	StoreEdit,
	getSellerWorking,
	secondaryImage,
	alertOrders,
	criticalOrders,
	AssignPartnerOrder,
	setProducts,
	setOrders,
	RemoveLogo,
	Delete_Logo,
	MultipleImage,
	multipleDelete,
	SingleDelete,
	uploadLogoImage,
	uploadThumbnail,
	updateThumbnail,
	secondaryImageUpdate,
	setMultipleImage,
	OrderNotifications,
	//OrderNotificationSeens,
	getDashboard,
	getNewDashboard,
	EditGst,
	setUrl,
	setImage,
	storeWorkingSubscription,
	DeleteImg,
	setMaster,
	gstChange,
	MultipleImageReset,
	MultiDelete,
	getMasterDepartment,
	getMasterCategory,
	getMasterProducts,
	MastercheckBox,
	AddCatalogProducts,
	resetProducts,
	setRejectClick,
	setCriteria,
	setDeliveryDays,
	getSameDayDelivery,
	CreatePercentage,
	LastSeenChange,
	LastSeenChangeNew,
  CreateFlat,
  CreateDeliveryFree,
  getUserSpecificCoupons,
  getAllCoupons,
  getAllUpComingCoupons,
getAllOnGoingCoupons,
  AssignCouponTouser,
  EditCoupon,
  CreateBXGY,
  getBxgyProducts,
  BxgycheckBox,
  getBusinessType,
  setCatalogProducts,
  setBusinessType,
  setBusinessError,
  setEstimatedTime,
  ShareLink,
  EliminateOrder,
  setHolidaySlot,
  UploadAadhar,
  getNewOrdertlist,
  getActiveOrdertlist,
  getReadyOrdertlist,
  getCompletedOrdertlist,
  getOutForDeliverytlist,
  getOrderCount,
  getAllCount,
  getQrCode,
  VerifyQrCode,
  clearVerifyPayment,
  connectionSuccess
} from "../../Redux/Action/SignInActions/SignInActions";
import { sellerReg, closeSnack, signupForm1,signupForm2,signupForm3 ,setStoreDescription,setStoreName,setStoreLocation,setStoreCoordinates,aadharChange,aadharDelete} from "../../Redux/Action/NewSignUp/GqlRequestAction";
import { shopassistantregister,storeWorking,editShopAssistant,deleteShopAssistant } from "../../Redux/Action/SignInActions/SignInActions";
import {
	getSearchResult,
	getSearchDetails,
	postStatusChange, 
	rejectedStatusHandler,
	scheduleAlert, 
	SetPaymentActive,
	GetEstimatedTime,
	setOrderDetails,
	ChangetoActiveStatus,
    ChangetoReadyStatus,
	changeToOutForDelivery,
	ChangetoCompletedStatus,
	getAllPendingSettlements,
	getAllPendingPayments,
	getAllCompletedSettlements,
	getCompletedPaymentSearch,
	depositCod 
} from "../../Redux/Action/OrderDetails";
import { loginAccess,shopAssistantLoginAccess} from "../../Redux/Action/LoginActions";
import {getProductSearch,
		ProductSearch,
		getProductDetails,
		getMasterProductDetails,
		UpdateProduct,
		getDepartmentList,
		getCategoryList,
		getCategory1List,
		getCategory2List,
		CREATEMASTERPRODUCTS,
		CREATEMASTER_NOIMAGE,
		ProductUpload ,
		Editproductstatus,
		Editallproductstatus,
		Deleteproduct
	} from "../../Redux/Action/ProductSearchDetails/newindex";
import { connect } from "react-redux";
import { SelectState } from "./Selector";
import './index.css';
// import { handleInputState } from "../../Redux/Action/LoginActions/index";

const mapStateToProps = (state) => {
	return { ...SelectState(state) };
};

// eslint-disable-next-line max-lines-per-function
const mapDispatchToProps = (dispatch) => {
	return {
		MultiDelete:(params) => {
			dispatch(MultiDelete(params))
		},
		setImage:(params) => {
			dispatch(setImage(params))
		},
		setUrl : (params) => {
			dispatch(setUrl(params))
		},
		getDashboard: () => {
			dispatch(getDashboard())
		},
		getNewDashboard:(params)=>{
			dispatch(getNewDashboard(params))
		},
		EditGst: (params) => {
			dispatch(EditGst(params))
		},
		uploadLogoImage: (params) => {
			dispatch(uploadLogoImage(params))
		},
	   SingleDelete : () =>{
			dispatch(SingleDelete())
		},
		multipleDelete : () =>{
			dispatch(multipleDelete())
		},
		MultipleImage : () =>{
			dispatch(MultipleImage())
		},
		Delete_Logo:(params)=>{
			dispatch(Delete_Logo(params))
		},
		RemoveLogo:()=>{
			dispatch(RemoveLogo())
		},
		setOrders:(params) => {
			dispatch(setOrders(params))
		},
		setProducts: (params) => {
			dispatch(setProducts(params))
		},
		criticalOrders : () => {
			dispatch(criticalOrders())
		},
		OrderNotifications: () =>{
			dispatch(OrderNotifications())
		},
		// OrderNotificationSeens:()=>{
		// 	dispatch(OrderNotificationSeens())
		// },
		alertOrders:()=> {
			dispatch(alertOrders());
		},
		scheduleAlert : (params1) => {
			dispatch(scheduleAlert(params1));
		},
		Delete_Image: ()=> {
			dispatch(Delete_Image());
		},
		getProductSearch: (params) => {
			dispatch(getProductSearch(params));
		},
		ProductSearch: (params) => {
			dispatch(ProductSearch(params));
		},
		getSellerDetail: (params) => {
			dispatch(getSellerDetail(params));
		},
		textEditChanges: (e, params) => {
			dispatch(textEditChanges({ e, params }));
		},
		textStoreChanges: (e,e1, params) => {
			dispatch(textStoreChanges({ e,e1, params }));
		},
		checkBoxEdit: (e, params) => {
			dispatch(checkBoxEdit(e, params));
		},
		setHolidaySlot: (params1,params2) => {
			dispatch(setHolidaySlot(params1,params2));
		},
		scheduleEdit: (params) => {
			dispatch(scheduleEdit(params));
		},
		uploadNewImage: (params) => {
			dispatch(uploadNewImage(params));
		},
		uploadThumbnail: (params) => {
			dispatch(uploadThumbnail(params))
		},
		secondaryImageUpdate :(params) => {
			dispatch(secondaryImageUpdate(params))
		},
		updateThumbnail: (params) => {
			dispatch(updateThumbnail(params))
		},
		postNewUrl: (params) => {
			dispatch(postNewUrl(params));
		},
		getNewLocation: (params) => {
			dispatch(getNewLocation(params));
		},
		getNewCoordinates: (params) => {
			dispatch(getNewCoordinates(params));
		},
		doorDeliveryEdit: (params) => {
			dispatch(doorDeliveryEdit(params));
		},
		changeMaxDistance: (params) => {
			dispatch(changeMaxDistance(params));
		},
		editDeliveryCharge: (params) => {
			dispatch(editDeliveryCharge(params));
		},
		resetDetails: (params) => {
			dispatch(resetDetails(params));
		},
		editRetailer: (id,params) => {
			dispatch(editRetailer(id,params));
		},
		editStore: (params1,params2) => {
			dispatch(editStore(params1,params2));
		},
		StoreEdit:(params1,params2,params3,params4,params5) => {
			dispatch(StoreEdit(params1,params2,params3,params4,params5));
		},
		closeSnackBars: () => {
			dispatch(closeSnackBars());
		},
		resetPassword: (params) => {
			dispatch(resetPassword(params));
		},
		getSearchResult: (params) => {
			dispatch(getSearchResult(params));
		},
		getAllPendingSettlements:(params)=>{
           dispatch(getAllPendingSettlements(params))
		},
		getAllPendingPayments:(params)=>{
			dispatch(getAllPendingPayments(params))
		 },
		 getAllCompletedSettlements:(params)=>{
			dispatch(getAllCompletedSettlements(params))
		 },
		 getCompletedPaymentSearch:(params)=>{
			dispatch(getCompletedPaymentSearch(params))
		 },
		 depositCod:(params)=>{
			dispatch(depositCod(params))
		 },
		getSearchDetails: (params) => {
			dispatch(getSearchDetails(params));
		},
		getMasterProductDetails: (params) => {
			dispatch(getMasterProductDetails(params));
		},
		postStatusChange: (params) => {
			dispatch(postStatusChange(params));
		},
		rejectedStatusHandler: (params) => {
			dispatch(rejectedStatusHandler(params));
		},
		logoutHandler:(params1,params2)=> {
			dispatch(logoutHandler(params1,params2));
		},
		AddProduct:(params,department,category,category1,category2,multipleImg,thumbnail)=> {
			dispatch(AddProduct(params,department,category,category1,category2,multipleImg,thumbnail));
		},
		AddProduct1:(params,department,category,category1,category2,multipleImg,thumbnail)=> {
			dispatch(AddProduct1(params,department,category,category1,category2,multipleImg,thumbnail));
		},
		CREATEMASTERPRODUCTS:(params,primary,multipleImg,thumbnail)=> {
			dispatch(CREATEMASTERPRODUCTS(params,primary,multipleImg,thumbnail));
		},
		CREATEMASTER_NOIMAGE:(params,primary,multipleImg,thumbnail)=> {
			dispatch(CREATEMASTER_NOIMAGE(params,multipleImg,thumbnail));
		},
		AddPrdt:(params,department,category,category1,category2)=> {
			dispatch(AddPrdt(params,department,category,category1,category2));
		},
		uploadImage:(params)=> {
			dispatch(uploadImage(params));
		},
		uploadOrderImage:(params)=>{
			dispatch(uploadOrderImage(params));
		},
		uploadProductImage:(params)=>{
			dispatch(uploadProductImage(params));
		},
		AddDepartment:(params)=> {
			dispatch(AddDepartment(params));
		},
		UpdateDepartment:(params)=> {
			dispatch(UpdateDepartment(params));
		},
		getProductDetails:(params)=> {
			dispatch(getProductDetails(params));
		},
		UpdateProduct :(params,department,category,category1,category2,productid,thumbnailUrl,multi,primary)=> {
			dispatch(UpdateProduct (params,department,category,category1,category2,productid,thumbnailUrl,multi,primary));
		},
		getDepartmentList:()=> {
			dispatch(getDepartmentList());
		},
		getCategoryList:(params)=> {
			dispatch(getCategoryList(params));
		},
		ProductUpload :(params,department,category,category1,category2,productid)=>{
			dispatch(ProductUpload(params,department,category,category1,category2,productid));
		},
		updateImage :(params)=>{
			dispatch(updateImage (params));
		},
		secondaryImage:(params)=>{
			dispatch(secondaryImage (params));
		},
		getCategory1List:(params) =>{
			dispatch(getCategory1List(params));
		},
		getCategory2List:(params)=>{
			dispatch(getCategory2List(params));
		},
		shopassistantregister:(params1,params2,params3,params4,params5)=>{
			dispatch(shopassistantregister(params1,params2,params3,params4,params5));
		},
		editShopAssistant:(params1,params2,params3,params4)=>{
			dispatch(editShopAssistant(params1,params2,params3,params4));
		},
		deleteShopAssistant:(params)=>{
			dispatch(deleteShopAssistant(params));
		},
		getShopassistantlist: (params) => {
			dispatch(getShopassistantlist(params));
		},
		getShopassistantOrders: (params1,params2) => {
			dispatch(getShopassistantOrders(params1,params2));
		},
		getShopassistantCompletedOrders:(params1,params2) => {
			dispatch(getShopassistantCompletedOrders(params1,params2));
		},
		getOrderDetails: (params) => {
			dispatch(getOrderDetails(params));
		},
		AssignOrder: (params1,params2)=>{
			dispatch(AssignOrder(params1,params2))
		},
		AssignPartnerOrder:(params1,params2,params3)=>{
			dispatch(AssignPartnerOrder(params1,params2,params3))
		},
		storeWorking: (params1) => {
			dispatch(storeWorking(params1))
		},
		storeWorkingSubscription:(params1) => {
			dispatch(storeWorkingSubscription(params1))
		},
		getSellerWorking:(params1) => {
			dispatch(getSellerWorking(params1))
		},
		setMultipleImage:(params) => {
			dispatch(setMultipleImage(params))
		},
		DeleteImg:(params) => {
			dispatch(DeleteImg(params))
		},
		setMaster:(params) => {
			dispatch(setMaster(params))
		},
		gstChange:(params) => {
			dispatch(gstChange(params))
		},
		MultipleImageReset:(params) => {
			dispatch(MultipleImageReset(params))
		},
		getMasterDepartment:(params) => {
			dispatch(getMasterDepartment(params))
		},
		getMasterCategory:(params) => {
			dispatch(getMasterCategory(params))
		},
		getMasterProducts:(params) => {
			dispatch(getMasterProducts(params))
		},
		MastercheckBox:(params1,params2) => {
			dispatch(MastercheckBox(params1,params2))
		},
		AddCatalogProducts:(params) => {
			dispatch(AddCatalogProducts(params))
		},
		resetProducts:(params) => {
			dispatch(resetProducts(params))
		},
		Editproductstatus:(params,params1) => {
			dispatch(Editproductstatus(params,params1))
		},
		Editallproductstatus:(params1,params2) => {
			dispatch(Editallproductstatus(params1,params2))
		},
		Deleteproduct:(params) => {
			dispatch(Deleteproduct(params))
		},
		SetPaymentActive:(params) => {
			dispatch(SetPaymentActive(params))
		},
		setRejectClick:(params) => {
			dispatch(setRejectClick(params))
		},
		setCriteria:(params) => {
			dispatch(setCriteria(params))
		},
		setDeliveryDays:(params) => {
			dispatch(setDeliveryDays(params))
		},
		getSameDayDelivery:(params) => {
			dispatch(getSameDayDelivery(params))
		},
		CreatePercentage: (params) => {
			dispatch(CreatePercentage(params))
		},
		LastSeenChange:(params)=>{
			dispatch(LastSeenChange(params))
		},
		LastSeenChangeNew:(params)=>{
			dispatch(LastSeenChangeNew(params))
		},
    CreateFlat: (params) => {
			dispatch(CreateFlat(params))
		},
    CreateDeliveryFree: (params) => {
			dispatch(CreateDeliveryFree(params))
		},
	CreateBXGY: (params) => {
		dispatch(CreateBXGY(params))
	},
    getUserSpecificCoupons: () => {
			dispatch(getUserSpecificCoupons())
		},
    getAllCoupons: () => {
			dispatch( getAllCoupons())
		},
		getAllUpComingCoupons: () => {
			dispatch( getAllUpComingCoupons())
		},

getAllOnGoingCoupons: () => {
			dispatch( getAllOnGoingCoupons())
		},
    AssignCouponTouser: (params) => {
			dispatch( AssignCouponTouser(params))
		},
    EditCoupon: (params) => {
			dispatch(EditCoupon(params))
		},
	getBxgyProducts: (params) => {
		dispatch(getBxgyProducts(params))
	},
	BxgycheckBox: (params) => {
		dispatch(BxgycheckBox(params))
	},
	getBusinessType:(params) => {
		dispatch(getBusinessType(params))
	},
	setCatalogProducts:(params) => {
		dispatch(setCatalogProducts(params))
	},
	setBusinessType:(params) => {
		dispatch(setBusinessType(params))
	},
	setBusinessError:(params) => {
		dispatch(setBusinessError(params))
	},
	GetEstimatedTime:(params) => {
		dispatch(GetEstimatedTime(params))
	},
	setEstimatedTime:(params) => {
		dispatch(setEstimatedTime(params))
	},
	ShareLink:() => {
		dispatch(ShareLink())
	},
	setOrderDetails:(params) => {
		dispatch(setOrderDetails(params))
	},
	EliminateOrder:(params) => {
		dispatch(EliminateOrder(params))
	},
	sellerReg:(params) => {
		dispatch(sellerReg(params))
	},
	closeSnack:(params) => {
		dispatch(closeSnack(params))
	},
	signupForm1:(params)=>{
		dispatch(signupForm1(params))
	},
	signupForm2:(params,params1)=>{
		dispatch(signupForm2(params,params1))
	},
	setStoreName:(params)=>{
		dispatch(setStoreName(params))
	},
	setStoreDescription:(params)=>{
		dispatch(setStoreDescription(params))
	},
	setStoreLocation:(params)=>{
		dispatch(setStoreLocation(params))
	},
	setStoreCoordinates:(params)=>{
		dispatch(setStoreCoordinates(params))
	},
	signupForm3:(params,params2,params3,params4)=>{
		dispatch(signupForm3(params,params2,params3,params4))
	},
	signInClick: (params,params1) => {
		dispatch(loginAccess(params,params1));
	},
	
shopAssistantSignInClick:(params1,params2)=>{
	dispatch(shopAssistantLoginAccess(params1,params2));
},

	UploadAadhar: (params) => {
		dispatch(UploadAadhar(params))
	},
	aadharChange:(params)=>{
		dispatch(aadharChange(params));
	},
	aadharDelete:()=>{
		dispatch(aadharDelete());
	},
	getNewOrdertlist:(params)=>{
		dispatch(getNewOrdertlist(params))
	},
	getAllCount:(params)=>{
         dispatch(getAllCount(params))
	},
	getActiveOrdertlist:(params)=>{
		dispatch(getActiveOrdertlist(params))
	},
	getReadyOrdertlist:(params)=>{
		dispatch(getReadyOrdertlist(params))
	},
	getOutForDeliverytlist:(params)=>{
		dispatch(getOutForDeliverytlist(params))
	},
	getCompletedOrdertlist:(params)=>{
		dispatch(getCompletedOrdertlist(params))
	},
	getOrderCount:(params)=>{
		dispatch(getOrderCount(params))
	},
	ChangetoActiveStatus:(params1,params2)=>{
		dispatch(ChangetoActiveStatus(params1,params2)) 
	},
	ChangetoReadyStatus:(params1,params2)=>{
		dispatch(ChangetoReadyStatus(params1,params2)) 
	},
	changeToOutForDelivery:(params1,params2)=>{
		dispatch(changeToOutForDelivery(params1,params2))
	},
	ChangetoCompletedStatus:(params)=>{
		dispatch(ChangetoCompletedStatus(params))
	},
	getQrCode:(params)=>{
		dispatch(getQrCode(params))
	},
	VerifyQrCode:(params)=>{
		dispatch(VerifyQrCode(params))
	},
	clearVerifyPayment:()=>{
		dispatch(clearVerifyPayment())
	},
	connectionSuccess:()=>{
		dispatch(connectionSuccess())
	} 
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
