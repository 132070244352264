// import React, { useState } from "react";
// import { SHOP } from "../../utils/image";
// import { useHistory } from "react-router-dom";
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import PersonIcon from '@mui/icons-material/Person';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
// import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
// import PercentIcon from '@mui/icons-material/Percent';
// import ReportIcon from '@mui/icons-material/Report';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// import LogoutIcon from '@mui/icons-material/Logout';
// import MenuIcon from '@mui/icons-material/Menu';
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import { STORE_WORKING, STORE_WORKING_SUBSCRIPTION } from "../NavBar/Query";
// import client from "../../Configurations/apollo";
// import ShareUrl from '../NavBar/ShareUrl';
// import Notification from "../NavBar/Notification";
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
// import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
// import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import "./SideNavBar.css";

// const SideNavBar = (props) => {
//   const [isExpanded, setExpendState] = useState(false);
//   const current = new Date();
//   const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
//   const menuItems = [
//     {
//       id: 0,
//       text: "Dashboard",
//       icon: <DashboardIcon />,
//     },
//     { id: 1, text: "Register", icon: <HowToRegOutlinedIcon /> },

//     {
//       id: 2,
//       text: "My Account",
//       icon: <PersonIcon />,
//       subMenuItems: [ // Step 1: Sub-menus for "My Account"
//         { id: 0, text: "My Profile", icon: <PersonOutlinedIcon />, url: "My Account" },
//         { id: 1, text: "My Store", icon: <StorefrontOutlinedIcon />, url: "My Account" },
//         { id: 2, text: "Additional Details", icon: <LocalShippingOutlinedIcon />, url: "My Account" },
//         { id: 3, text: "Change Password", icon: <KeyOutlinedIcon />, url: "My Account" },
//       ],
//     },
//     {
//       id: 3,
//       text: "Subscription",
//       icon: <AttachMoneyIcon />,
//     },
//     {
//       id: 4,
//       text: "Manage Hierarchy",
//       icon: <AccountTreeIcon />,
//     },
//     {
//       id: 5,
//       text: "Add Products",
//       icon: <AddIcon />,
//     },
//     {
//       id: 6,
//       text: "Edit Products",
//       icon: <EditIcon />,
//     },
//     {
//       id: 7,
//       text: "My Orders",
//       icon: <BookmarkBorderIcon />,
//     },
//     {
//       id: 8,
//       text: "Shop Assistant",
//       icon: <SupervisorAccountIcon />,
//       subMenuItems: [ // Step 1: Sub-menus for "Shop Assistant"
//         { id: 0, text: "Register", icon: <HowToRegOutlinedIcon />, url: "ShopAssistant/Register" },
//         { id: 1, text: "View Shop Assistants", icon: <VisibilityOutlinedIcon />, url: "ShopAssistant/Viewshopassistants" },
//       ],
//     },
//     {
//       id: 9,
//       text: "Coupons",
//       icon: <PercentIcon />,
//     },
//     {
//       id: 10,
//       text: "Critical Orders",
//       icon: <ReportIcon />,
//     },
//     {
//       id: 11,
//       text: "Help",
//       icon: <QuestionMarkIcon />,
//     },
//     {
//       id: 12,
//       text: "Logout",
//       icon: <LogoutIcon />,
//     },
//   ];

//   const [state, setState] = React.useState({ items: menuItems });
//   const [open, setOpen] = React.useState(false);
//   const [error, setError] = React.useState(null);

//   const [selectedIndex, setSelectedIndex] = React.useState(0);
//   const [subIndex, setSubIndex] = React.useState(0);
//   const [myAccountOpen, setMyAccountOpen] = useState(false);
//   const [shopAssistantOpen, setShopAssistantOpen] = useState(false);

//   const handleListItemClick = (event, index) => {
//     setSelectedIndex(index);
//   };
//   const loginformNumber = localStorage.getItem('loginformNumber');
//   const loginnumber = loginformNumber === 'null' ? null : (loginformNumber === '0' ? 0 : parseInt(loginformNumber));
//   const history = useHistory();

//   const collapseClick = (id) => {
//     if (id === 2) {
//       setMyAccountOpen(!myAccountOpen);
//     } else if (id === 8) {
//       setShopAssistantOpen(!shopAssistantOpen);
//     } else {
//       setState((state) => ({
//         ...state,
//         items: state.items.map((itm) =>
//           itm.id === id ? { ...itm, open: !itm.open } : itm
//         ),
//       }));
//       history.push(`/home/${menuItems[id].text.replace(/ /g, "")}`);
//     }
//   };

//   const subcollapseClick = (id, subid) => {
//     history.push(`/home/${menuItems[id].subMenuItems[subid].url.replace(/ /g, "")}`);
//   };

//   const handleChange = (checked) => {
//     if (
//       props.retailerDetails.data &&
//       props.retailerDetails.data.retailerdetails &&
//       props.retailerDetails.data.retailerdetails.stores &&
//       props.retailerDetails.data.retailerdetails.stores[0].subscription &&
//       props.retailerDetails.data.retailerdetails.stores[0].subscription.startdate === null
//     ) {
//       client
//         .mutate({
//           mutation: STORE_WORKING_SUBSCRIPTION,
//           variables: {
//             storeWorking: Boolean(checked),
//             storeid: localStorage.getItem("storeid"),
//             subscription: {
//               type: 1,
//               amount: 1000,
//               startdate: date
//             }
//           },
//         })
//         .then((res) => {
//           if (res.data && res.data.updatestore && res.data.updatestore !== null) {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//             props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
//           }
//           if (res.data && res.data.updatestore && res.data.updatestore === null) {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//             props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
//             setOpen(true);
//             setError(res.message);
//           }
//         })
//         .catch((err) => {
//           props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//           setOpen(true);
//           setError(
//             "There is no active products or hierarchy present. so store working cannot be updated"
//           );
//         })
//     }
//     else {
//       client
//         .mutate({
//           mutation: STORE_WORKING,
//           variables: {
//             storeWorking: Boolean(checked),
//             storeid: localStorage.getItem("storeid"),
//           },
//         })
//         .then((res) => {
//           if (res.data && res.data.updatestore && res.data.updatestore !== null) {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//           }
//           if (res.data && res.data.updatestore && res.data.updatestore === null) {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//             setOpen(true);
//             setError(res.message);
//           }
//         })
//         .catch((err) => {
//           props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//           setOpen(true);
//           setError(
//             "There is no active products or hierarchy present. so store working cannot be updated"
//           );
//         })
//     }
//   };

//   return (
//     <>
//       <div style={{ display: 'flex' }}>
//         {/* Left Side Div */}
//         <div
//           className={isExpanded ? "side-nav-container" : "side-nav-container side-nav-container-NX"}
//           style={{
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             height: '100vh',
//             overflowY: 'auto',
//           }}
//         >
//           <div className="nav-upper">
//             <div className="nav-heading">
//               <span
//                 style={{

//                 }}
//                 className={isExpanded ? "drawer-x" : "drawer"}
//                 onClick={() => setExpendState(!isExpanded)}
//               >
//                 {<MenuIcon />}
//               </span>
//             </div>
//             <div className="nav-menu" style={{ paddingLeft: '24px', marginTop: "-10px" }}>
//               {menuItems.map((item, index) => {
//                 if ((localStorage.getItem("storestatus")==="active")&& item.text === "Register") {
//                   return null;
//                 }
//                 else if((loginnumber === 4 || loginnumber === null) && item.text === "Register")
//                 {return null}
//                 return (
//                   <div>
//                     {((loginnumber !== null && loginnumber !== 4) && (item.text !== "Register" && item.text !== "Logout")&&(localStorage.getItem("storestatus")!=="active")) ?
//                       <a
//                         // onClick={(event) => {
//                         //   collapseClick(item.id);
//                         //   handleListItemClick(event, item.id)
//                         // }}
//                         className={"menu-item-disabled"}
//                       >
//                         {item.icon}
//                         <span
//                           style={{
//                             paddingLeft: '15px',
//                             paddingTop: "12px",
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             textAlign: 'center'
//                           }}
//                           title={item.text} >
//                           {isExpanded && <p
//                             style={{
//                               display: 'flex',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               textAlign: 'center'
//                             }}>
//                             {item.text}
//                           </p>}
//                         </span>
//                       </a>
//                       :
//                       <>
//                         <a
//                           onClick={(event) => {
//                             collapseClick(item.id);
//                             handleListItemClick(event, item.id)
//                           }}
//                           className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
//                         >
//                           {item.icon}
//                           <span
//                             style={{
//                               paddingLeft: '15px',
//                               paddingTop: "12px",
//                               display: 'flex',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               textAlign: 'center'
//                             }}
//                             title={item.text} >
//                             {isExpanded && <p
//                               style={{
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 textAlign: 'center'
//                               }}>
//                               {item.text}
//                             </p>}
//                           </span>
//                         </a>
//                         {item.id === 2 && myAccountOpen && ( // Step 2: Render sub-menu if "My Account" is expanded
//                           <div style={{ position: "relative", left: isExpanded ? "25px" : "0px", borderRadius: "9px" }}>
//                             {item.subMenuItems.map((subMenuItem, index) => (
//                               <a
//                                 onClick={(event) => {
//                                   subcollapseClick(item.id, subMenuItem.id);
//                                   props.selectedList(item, index);
//                                 }}
//                                 className="sub-menu-item"
//                                 style={{
//                                   display: 'flex',
//                                   alignItems: 'center',
//                                   justifyContent: 'flex-start',
//                                   paddingBottom: '23px',
//                                   marginLeft: "9px",
//                                   paddingTop: "12px"
//                                 }}
//                               >
//                                 {subMenuItem.icon}
//                                 <span style={{ paddingLeft: '15px', textAlign: 'left' }}>
//                                   {isExpanded ? subMenuItem.text : ''}
//                                 </span>
//                               </a>
//                             ))}
//                           </div>
//                         )}
//                         {item.id === 8 && shopAssistantOpen && ( // Step 2: Render sub-menu if "Shop Assistant" is expanded
//                           <div style={{ position: "relative", left: isExpanded ? "25px" : "0px", borderRadius: "9px" }}>
//                             {item.subMenuItems.map((subMenuItem, index) => (
//                               <a
//                                 onClick={(event) => {
//                                   subcollapseClick(item.id, subMenuItem.id);
//                                   props.selectedList(item, index);
//                                 }}
//                                 className="sub-menu-item"
//                                 style={{
//                                   display: 'flex',
//                                   alignItems: 'center',
//                                   justifyContent: 'flex-start',
//                                   paddingBottom: '23px',
//                                   marginLeft: "9px",
//                                   paddingTop: "12px"
//                                 }}
//                               >
//                                 {subMenuItem.icon}
//                                 <span style={{ paddingLeft: '15px', textAlign: 'left' }}>
//                                   {isExpanded ? subMenuItem.text : ''}
//                                 </span>
//                               </a>
//                             ))}
//                           </div>
//                         )}
//                       </>
//                     }
//                   </div>
//                 )
//               })}
//             </div>
//           </div>
//         </div>

//         {/* Right Side Divs */}
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             marginLeft: isExpanded ? '300px' : '95px',
//             width: isExpanded ? 'calc(100vw - 300px)' : 'calc(100vw - 95px)'
//           }}>
//           {/* Top Right Div */}
//           <div
//             style={{
//               background: '#fff',
//               display: 'flex',
//               height: '70px',
//               boxShadow: 'inset 0 -1px 0 rgba(0, 0, 0, 0.09)',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               width: isExpanded ? 'calc(100vw - 300px)' : 'calc(100vw - 95px)',
//               padding: '20px',
//               boxSizing: 'border-box',
//               position: 'fixed',
//               top: 0,
//               zIndex: 2,
//             }}
//           >
//             <div className="nav-brand">
//               <img src={SHOP} alt="" srcset=""
//                 style={{
//                   height: '28px',
//                   width: '150px'
//                 }} />
//             </div>
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center'
//               }}>
//               <ShareUrl {...props} />
//               <span
//                 style={{
//                   paddingLeft: '20px',
//                   paddingRight: '20px',
//                   position: 'relative',
//                   zIndex: 3
//                 }}>
//                 <Notification {...props} />
//               </span>
//               <BootstrapSwitchButton
//                 checked={props.retailerWorking &&
//                   props.retailerWorking.data &&
//                   props.retailerWorking.data.retailerdetails &&
//                   props.retailerWorking.data.retailerdetails.stores[0] &&
//                   props.retailerWorking.data.retailerdetails.stores[0].storeWorking &&
//                   props.retailerWorking.data.retailerdetails.stores[0]
//                     .storeWorking}
//                 onlabel="Store online"
//                 onstyle="success"
//                 offlabel="Store Offline"
//                 offstyle="danger"
//                 width={150}
//                 onChange={(checked) => handleChange(checked)}
//               />
//             </div>
//           </div>

//           {/* Bottom Right Div */}
//           <div
//             style={{
//               padding: '20px',
//               marginTop: '70px',
//               width: '100%',
//               position: 'relative',
//               zIndex: 1
//             }}>
//             {props.children}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SideNavBar;

//new code written by berlin

import React, { useState } from "react";
import { ReactComponent as DashBoard } from "../../utils/navbaricons/Dashboard.svg";
import { ReactComponent as Myorders } from "../../utils/navbaricons/Myorders.svg";
import { ReactComponent as Addproducts } from "../../utils/navbaricons/Addproducts.svg";
import { ReactComponent as Coupons } from "../../utils/navbaricons/Coupons.svg";
import { ReactComponent as Payment } from "../../utils/navbaricons/Payment.svg";
import { ReactComponent as ShopAssistant } from "../../utils/navbaricons/Shopassistant.svg";
import { ReactComponent as Subscription } from "../../utils/navbaricons/Subscription.svg";
import { ReactComponent as Help } from "../../utils/navbaricons/Help.svg";
import Shoplogo from "../../utils/navbaricons/Shoplogo.svg";
import { SHOP } from "../../utils/image";
import { useNavigate, useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { STORE_WORKING, STORE_WORKING_SUBSCRIPTION } from "../NavBar/Query";
import client from "../../Configurations/apollo";
import ShareUrl from "../NavBar/ShareUrl";
import Notification from "../NavBar/Notification";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import "./SideNavBar.css";
import ToggleSwitch from "../NavBar/ToggleSwitch";
import NavProfile from "../NavBar/NavProfile";
import { Typography } from "../MUIComponents/index";
import {
  HeaderFontSize_48,
  contentFontSize_15,
  contentFontSize_16,
  contentFontSize_20,
} from "../Typography";

const SideNavBar = (props) => {
  const [isExpanded, setExpendState] = useState(true);
  const current = new Date();
  const [selectedItem, setSelectedItem] = useState(null);
  const [addItemsState, setAddItemsState] = useState(true);
  // const [iconColor, setIconColor] = useState('white');
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const role = localStorage.getItem("role");

  const location = useLocation();

  if (role !== "Store Manager") {
    var menuItems = [
      {
        id: 0,
        text: "My Orders",
        icon: <Myorders className="icon" />,
        paths: [
          "/home/MyOrders",
          "/home/MyOrders/NewOrdersDetail",
          "/home/MyOrders/ActiveOrders",
          "/home/MyOrders/ActiveOrdersDetails",
          "/home/MyOrders/ReadyForDelivery",
          "/home/MyOrders/ReadyForDeliveryDetails",
          "/home/MyOrders/OutForDelivery",
          "/home/MyOrders/OutForDeliveryDetails",
          "/home/MyOrders/OrderHistory",
          "/home/MyOrders/OrderHistoryDetails",
          "/",
        ],
      },
      {
        id: 1,
        text: "Help",
        icon: <Help className="icon" />,
        paths: ["/home/Help"],
      },
    ];
  } else {

    var menuItems = [
      {
        id: 0,
        text: "Dashboard",
        icon: <DashBoard className="icon" />,
        paths: ["/home/Dashboard", "/"],
      },

      {
        id: 1,
        text: "My Orders",
        icon: <Myorders className="icon" />,
        paths: [
          "/home/MyOrders",
          "/home/MyOrders/NewOrderDetail",
          "/home/MyOrders/ActiveOrders",
          "/home/MyOrders/ActiveOrdersDetails",
          "/home/MyOrders/ReadyForDelivery",
          "/home/MyOrders/ReadyForDeliveryDetails",
          "/home/MyOrders/OutForDelivery",
          "/home/MyOrders/OutForDeliveryDetails",
          "/home/MyOrders/OrderHistory",
          "/home/MyOrders/OrderHistoryDetails",
          "/home/MyOrders/GoogleMap",
        ],
      },
      {
        id: 2,
        text: "Add Products",
        icon: <Addproducts className="icon" />,
        paths: [
          "/home/AddProducts",
          "/home/ManageHierarchy",
          "/home/ManageHierarchy/Product",
          "/home/Product",
          "/home/CatalogBuilder",
          "/home/CategoryBuilder/Departments",
          "/home/CategoryBuilder/Products",
          "/home/CatalogProducts",
          "/home/EditProducts/details",
        ],
      },

      {
        id: 3,
        text: "Payments",
        icon: <Payment className="icon" />,
        paths: ["/home/Payments"],
      },
      {
        id: 4,
        text: "Coupons",
        icon: <Coupons className="icon" />,
        paths: [
          "/home/Coupons",
          "/home/Coupontype",
          "/home/Coupons/Percentagediscount",
          "/home/Coupons/Flatdiscount",
          "/home/Coupons/BuyXGetYfree",
          "/home/Coupons/FreeDelivery",
          "/home/UpcomingCoupons",
        ],
      },
      {
        id: 5,
        text: "Shop Assistant",
        icon: <ShopAssistant className="icon" />,
        paths: [
          "/home/ShopAssistant",
          "/home/ShopAssistant/Register",
          "/home/ShopAssistant/EditRegister",
          "/home/ShopAssistant/orders",
        ],
      },
      // {
      //   id: 6,
      //   text: "Subscription",
      //   icon: <Subscription className="icon" />,
      //   paths: ["/home/Subscription"],
      // },
      {
        id: 7,
        text: "Help",
        icon: <Help className="icon" />,
        paths: ["/home/Help"],
      },
    ];
  }

  const [state, setState] = React.useState({ items: menuItems });
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [subIndex, setSubIndex] = React.useState(0);
  const [myAccountOpen, setMyAccountOpen] = useState(false);
  const [shopAssistantOpen, setShopAssistantOpen] = useState(false);
  const [storeName, setStoreName] = useState(
    localStorage.getItem("ShopAssistantStoreName")
  );

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleItemClick = (index) => {
    setSelectedItem(index);
    // navigate(`/home/${menuItems[index].text.replace(/ /g, "")}`);
    navigate(index);
  };
  const loginformNumber = localStorage.getItem("loginformNumber");
  const loginnumber =
    loginformNumber === "null"
      ? null
      : loginformNumber === "0"
      ? 0
      : parseInt(loginformNumber);
  const navigate = useNavigate();

  const collapseClick = (id) => {
    // if (id === 2) {
    //   setMyAccountOpen(!myAccountOpen);
    if (id === 5) {
      setShopAssistantOpen(!shopAssistantOpen);
    } else {
      setState((state) => ({
        ...state,
        items: state.items.map((itm) =>
          itm.id === id ? { ...itm, open: !itm.open } : itm
        ),
      }));
      navigate(`/home/${menuItems[id].text.replace(/ /g, "")}`);
    }
  };

  // const subcollapseClick = (id, subid) => {
  //   navigate(`/home/${menuItems[id].subMenuItems[subid].url.replace(/ /g, "")}`);
  // };

  const className = role === 'Store Manager' ? 'nav-logo' : 'shopassistant-logo';

  const handleChange = (checked) => {
    if (
      props.retailerDetails.data &&
      props.retailerDetails.data.retailerdetails &&
      props.retailerDetails.data.retailerdetails.stores &&
      props.retailerDetails.data.retailerdetails.stores[0].subscription &&
      props.retailerDetails.data.retailerdetails.stores[0].subscription
        .startdate === null
    ) {
      client
        .mutate({
          mutation: STORE_WORKING_SUBSCRIPTION,
          variables: {
            storeWorking: Boolean(checked),
            storeid: localStorage.getItem("storeid"),
            subscription: {
              type: 1,
              amount: 1000,
              startdate: date,
            },
          },
        })
        .then((res) => {
          if (
            res.data &&
            res.data.updatestore &&
            res.data.updatestore !== null
          ) {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
            props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
          }
          if (
            res.data &&
            res.data.updatestore &&
            res.data.updatestore === null
          ) {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
            props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
            setOpen(true);
            setError(res.message);
          }
        })
        .catch((err) => {
          props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
          setOpen(true);
          setError(
            "There is no active products or hierarchy present. so store working cannot be updated"
          );
        });
    } else {
      client
        .mutate({
          mutation: STORE_WORKING,
          variables: {
            storeWorking: Boolean(checked),
            storeid: localStorage.getItem("storeid"),
          },
        })
        .then((res) => {
          if (
            res.data &&
            res.data.updatestore &&
            res.data.updatestore !== null
          ) {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
          }
          if (
            res.data &&
            res.data.updatestore &&
            res.data.updatestore === null
          ) {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
            setOpen(true);
            setError(res.message);
          }
        })
        .catch((err) => {
          props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
          setOpen(true);
          setError(
            "There is no active products or hierarchy present. so store working cannot be updated"
          );
        });
    }
  };

  return (
    <>
      <div style={{ display: "flex", background: "#F4F5F9" }}>
        {/* Left Side Div */}
        <div
          className={
            isExpanded
              ? "side-nav-container"
              : "side-nav-container side-nav-container-NX"
          }
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <div className="nav-upper">
            <div className="nav-heading">
              <div className={className}>
                {role === "Store Manager" &&
                props?.retailerDetails?.data?.retailerdetails?.stores?.[0]
                  ?.storeLogoUrl ? (
                  <img
                    src={
                      props.retailerDetails.data.retailerdetails.stores[0]
                        .storeLogoUrl
                    }
                    alt="shoplogo"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    width="40px"
                    height="40px"
                  />
                ) : (
                  <div></div>
                )}

                <div className="nav-logo-text-head">
                  {
                    // role==='Store Manager' &&
                    <>Welcome</>
                  }
                  <br />
                  <p className="nav-logo-text">
                    {role !== "Store Manager" ? (
                      <>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <div className="nav-logo-text">
                            {storeName}
                            {/* {props.ShopAssistantsuccessData.loginShopAssistant.firstName} */}
                          </div>
                          <div className="nav-logo-text">
                            {/* {props.ShopAssistantsuccessData.loginShopAssistant.lastName} */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {props &&
                        props.retailerDetails &&
                        props.retailerDetails.data &&
                        props.retailerDetails.data.retailerdetails &&
                        props.retailerDetails.data.retailerdetails.stores &&
                        props.retailerDetails.data.retailerdetails.stores
                          .length > 0 ? (
                          <div className="nav-logo-text">
                            {
                              props.retailerDetails.data.retailerdetails
                                .stores[0].storeName
                            }
                          </div>
                        ) : (
                          <div>Loading...</div>
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
              <span style={{}}></span>
            </div>
            <div
              className="nav-menu"
              style={{ paddingLeft: "24px", marginTop: "8px" }}
            >
              {menuItems.map((item, index) => {
                const isActive =
                  item.paths && item.paths.includes(location.pathname);

                if (
                  localStorage.getItem("storestatus") === "active" &&
                  item.text === "Register"
                ) {
                  return null;
                } else if (
                  (loginnumber === 4 || loginnumber === null) &&
                  item.text === "Register"
                ) {
                  return null;
                }
                return (
                  <div
                    key={item.id}
                    className={`menuitem ${isActive ? "active" : ""}`}
                    onClick={() => handleItemClick(item.paths[0])}
                  >
                    {loginnumber !== null &&
                    loginnumber !== 4 &&
                    item.text !== "Register" &&
                    item.text !== "Logout" &&
                    localStorage.getItem("storestatus") !== "active" ? (
                      <a
                        className="menuicon"
                        // onClick={(event) => {
                        //   collapseClick(item.id);
                        //   handleListItemClick(event, item.id)
                        // }}
                        // className={"menu-item-disabled"}
                      >
                        {/* {item.icon} */}
                        <span
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          title={item.text}
                        >
                          {isExpanded && (
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Typography fontSize={contentFontSize_15}>
                                {item.text}
                              </Typography>
                            </p>
                          )}
                        </span>
                      </a>
                    ) : (
                      <>
                        <a
                          onClick={(event) => {
                            collapseClick(item.id);
                            handleListItemClick(event, item.id);
                          }}
                          className="menu-item"
                        >
                          {item.icon}
                          <span
                            style={{
                              paddingLeft: "15px",
                              paddingTop: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            title={item.text}
                          >
                            {
                              <p
                                className="sidenav-text"
                                style={{
                                  marginTop: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <Typography fontSize={contentFontSize_15}>
                                  {item.text}
                                </Typography>
                              </p>
                            }
                          </span>
                        </a>
                        {/* {item.id === 2 && myAccountOpen && ( // Step 2: Render sub-menu if "My Account" is expanded
                          <div style={{ position: "relative", borderRadius: "9px" }}>
                            {item.subMenuItems.map((subMenuItem, index) => (
                              <a
                                // onClick={(event) => {
                                //   subcollapseClick(item.id, subMenuItem.id);
                                //   props.selectedList(item, index);
                                // }}
                                className="sub-menu-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  paddingBottom: '23px',
                                  marginLeft: "9px",
                                  paddingTop: "12px"
                                }}
                              >
                                {subMenuItem.icon}
                                <span style={{ paddingLeft: '15px', textAlign: 'left' }}>
                                  {isExpanded ? subMenuItem.text : ''}
                                </span>
                              </a>
                            ))}
                          </div>
                        )}
                        {item.id === 8 && shopAssistantOpen && ( // Step 2: Render sub-menu if "Shop Assistant" is expanded
                          <div style={{ position: "relative", left: isExpanded ? "25px" : "0px", borderRadius: "9px" }}>
                            {item.subMenuItems.map((subMenuItem, index) => (
                              <a
                                onClick={(event) => {
                                  subcollapseClick(item.id, subMenuItem.id);
                                  props.selectedList(item, index);
                                }}
                                className="sub-menu-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  paddingBottom: '23px',
                                  marginLeft: "9px",
                                  paddingTop: "12px"
                                }}
                              >
                                {subMenuItem.icon}
                                <span style={{ paddingLeft: '15px', textAlign: 'left' }}>
                                  {isExpanded ? subMenuItem.text : ''}
                                </span>
                              </a>
                            ))}
                          </div>
                        )} */}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Right Side Divs */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: isExpanded ? "260px" : "95px",
            width: isExpanded ? "calc(100vw - 295px)" : "calc(100vw - 95px)",
          }}
        >
          {/* Top Right Div */}
          <div
            style={{
              background: "#fff",
              display: "flex",
              height: "70px",
              boxShadow: "inset 0 -1px 0 rgba(0, 0, 0, 0.09)",
              alignItems: "center",
              justifyContent: "space-between",
              width: isExpanded ? "calc(100vw - 230px)" : "calc(100vw - 95px)",
              padding: "20px 85px 20px 30px",
              boxSizing: "border-box",
              position: "fixed",
              top: 0,
              zIndex: 2,
            }}
          >
            <div className="nav-brand">
              <img
                src={SHOP}
                alt=""
                srcset=""
                style={{
                  height: "48px",
                  width: "192.2px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {role !== "Store Manager" ? (
                <>
                  <ShareUrl {...props} />
                </>
              ) : (
                <>
                  <ShareUrl {...props} />
                </>
              )}

              <span
                style={{
                  paddingLeft: "30px",
                  position: "relative",
                  zIndex: 3,
                }}
              >
                {role !== "Store Manager" ? (
                  <>
                    <Notification {...props} />
                  </>
                ) : (
                  <>
                    <Notification {...props} />
                  </>
                )}
              </span>
              <span
                style={{
                  paddingLeft: role !== "Store Manager" ? "0px" : "30px",
                  paddingRight: "30px",
                  position: "relative",
                  zIndex: 3,
                }}
              >
                {role !== "Store Manager" ? (
                  <>{/* <ToggleSwitch {...props} /> */}</>
                ) : (
                  <>
                    <ToggleSwitch {...props} />
                  </>
                )}
              </span>

              <NavProfile {...props} />
            </div>
          </div>

          {/* Bottom Right Div */}
          <div
            style={{
              // padding: '20px 40px',
              // paddingRight:'20px',
              padding: "20px 15px 20px 40px",
              marginTop: "70px",
              width: "100%",
              position: "relative",
              zIndex: 1,
              backgroundColor: "#F4F5F9",
              height: "100%",
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNavBar;
// import { React} from 'react';
// import Drawer from '@mui/material/Drawer';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';

// const drawerWidth = 240;

// function ResponsiveDrawer() {

//   const drawer = (
//     <div >
//       <List style={{backgroundColor:"#293332", overflowX:"hidden"}}>
//         {['DashBoard', 'MyOrders', 'AddProducts', 'Payments','Coupons','Shop Assistant','Subscription','Help'].map((text) => (
//           <ListItem key={text} disablePadding sx={{borderBottom:"none",margin:"25px",color:"white"}}>
//             <ListItemButton sx={{borderRadius:"4px"}}>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//     </div>
//   );
//   return (
//     <div >

//         <Drawer
//           variant="permanent"
//           sx={{
//             display: { xs: 'none', sm: 'block' },
//             '': { boxSizing: 'border-box', width: drawerWidth },
//           }}
//           open
//         >
//           {drawer}
//         </Drawer>
//       </div>
//   );
// }
// export default ResponsiveDrawer;
