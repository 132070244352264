import React, { useState, useEffect } from "react";
import ImgCrop from "antd-img-crop";
import { Upload, Modal, message } from "antd";
import { Row, Col } from "react-bootstrap";
import "./index.css";
import "../../utils/images/NoImage.png";
import { NoImage } from "../../utils/image";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const Uploader = (props) => {
  useEffect(() => {
    // Set updateUrl in localStorage when component mounts
    if (
      props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.salesproducts &&
      props.ProductDetailsData.data.salesproducts.image &&
      props.ProductDetailsData.data.salesproducts.image.primary !== null
    ) {
      setFileList([
        {
          uid: "-1",
          name:
            props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.productname &&
            `${props.ProductDetailsData.data.salesproducts.productname}`,
          status: "done",
          url:
            props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts.image.primary,
        },
      ]);
      localStorage.setItem(
        "updateUrl",
        props.ProductDetailsData.data.salesproducts.image.primary
      );
    } else {
      setFileList([]);
      localStorage.removeItem("updateUrl");
    }
  }, [props.imge]);

  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(
    localStorage.getItem("imageUrl")
  );
  const [previewVisible, setPreviewVisible] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [Btn, setButton] = useState(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  const handleRemove = () => {
    props.DeleteImg(
      props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.productname &&
        `${props.ProductDetailsData.data.salesproducts.productname}`
    );
    localStorage.setItem("updateUrl", null);
  };

  const onChange = ({ fileList: newFileList }) => {
    const maxSize = 1024 * 1024;
    const validFiles = newFileList.filter((file) => file.size <= maxSize);

    if (validFiles.length !== newFileList.length) {
      message.error("Please upload an image file up to 1 MB in size.");
      return;
    }

    localStorage.setItem("updateUrl", null);
    if (validFiles.length !== 0) {
      props.RemoveLogo(
        props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.productname &&
          `${props.ProductDetailsData.data.salesproducts.productname}`
      );
    }
    setFileList(validFiles);
    setButton(true);
  };

  const SubmitNow = () => {
    setButton(false);
    const type = 2;

    const storeid = localStorage.getItem("storeid");
    const Imgdata = fileList[0].originFileObj;
    const size = fileList[0].size;
    const filename = fileList[0].name;
    if (fileList.length > 0 && fileList[0].originFileObj !== null) {
      props.updateImage({
        files: Imgdata,
        size: size,
        filename: filename,
        type: type,
        storeid,
      });
      props.updateThumbnail({
        files: Imgdata,
        size: size,
        filename: filename,
        type: type,
        storeid,
      });
    } else {
      console.error("No file object available to upload.");
    }
    if (props.newSignedUrl) {
      message.success({
        content: "Image Uploaded",
        className: "custom-class",
        style: {
          marginTop: "20vh",
        },
      });
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Row>
        <Col>
          <ImgCrop
            modalTitle="product"
            modalWidth={700}
            modalHeight={700}
            minZoom={0.1}
            aspect={1 / 1}
            rotate={true}
            grid
          >
            <Upload
              maxCount={1}
              customRequest={dummyRequest}
              accept="image/*"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={onChange}
              onRemove={handleRemove}
            >
              {fileList.length < 1 && "Click here to choose the image"}
            </Upload>
          </ImgCrop>
        </Col>
        <Col>{/* <Cards/> */}</Col>
      </Row>
      {Btn === true && fileList.length !== 0 ? (
        <button
          style={{
            justifyContent: "center",
            color: "rgb(255, 255, 255)",
            backgroundImage:
              "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
            boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
            width: "80px",
            height: "30px",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "5px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "transparent",
            borderImage: "initial",
            overflow: "hidden",
            outline: "none",
            minHeight: "14px",
            fontFamily: "Varela Round, sans-serif",
          }}
          type="button"
          onClick={SubmitNow}
        >
          Upload
        </button>
      ) : null}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
export default Uploader;
