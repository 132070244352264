import { React, useState,useEffect } from "react";
import CustomCard from "../Commoncomponents/card";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
} from "../MUIComponents/index";
import {
  HeaderNavigationFontSize_22,
  contentFontSize_18,
  ButtonFontSize_18,
  HeaderfontSize_30,
  HeaderFontSize_25,
  contentFontSize_15,
  HeaderFontsize_36,
} from "../Typography";
import "./LandingCard1.css";
import Members from "../../utils/Icon/Members.svg";
import Verify from "../../utils/Icon/Verify.svg";
import Catalog from "../../utils/Icon/Catalog.svg";
import Employee from "../../utils/Icon/Employee.svg";
import Order from "../../utils/Icon/Order.svg";
import Streamline from "../../utils/Icon/Streamline.svg";
import CustomDialog from "../Commoncomponents/dialog";
// import Register from "../../utils/images/Register1.png";
import CustomButton from "../Commoncomponents/button";



function LandingCard1() {
  const [reg, setReg] = useState(false);
  const [verify, setVerify] = useState(false);
  const [catalog, setCatalog] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [order, setOrder] = useState(false);
  const [stream, setStream] = useState(false);

  const Register = localStorage.getItem("Register");
  const Verify1 = localStorage.getItem("Verify1");
  const Order1 = localStorage.getItem("Order1");
  const Employee1 = localStorage.getItem("Employee1");
  const Catalog1 =localStorage.getItem("Catalog1");
  const Stream1 = localStorage.getItem("Stream1");

  function handleRegClick() {
    setReg(true);
  }
  function handleVerifyClick() {
    setVerify(true);
  }
  function handleCatlogClick() {
    setCatalog(true);
  }
  function handleEmployeeClick() {
    setEmployee(true);
  }
  function handleOrderClick() {
    setOrder(true);
  }
  function handleStreamClick() {
    setStream(true);
  }
  function handleClose() {
    setReg(false);
    setVerify(false);
    setCatalog(false);
    setOrder(false);
    setEmployee(false);
    setStream(false);
  }

  const [color, setColor] = useState('#13801C');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor(prevColor => (prevColor === '#13801C' ? 'black' : '#13801C'));
    }, 2000);

    return () => clearInterval(intervalId);
  },[]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
    <div style={{paddingTop:"100px"}}>
      <div >
        <Typography
          fontSize={HeaderfontSize_30}
          className="header"
          style={{color:color}}
        >
          How To Become a Seller on NearShopz ?
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div>
          <div
            style={{
              marginTop: "40px",
              display: "flex",
              gap: "30px",
              alignItems: "center",
              padding: "40px",
              justifyContent: "space-evenly",
              flexDirection:mdmatch ? " column" : "row"
            }}
          >
            <div style={{cursor:"pointer",width:"100%"}}>
              <CustomCard
                padding={"20px"}
                paddingTop="50px"
                className="card"
                height={ mdmatch  || lgmatch ? "100%" :"235px"}
                width={"100%"}
                background={"white"}
                borderRadius={"20px"}
                position="relative"
                onClick={handleRegClick}
                 boxShadow={"none"}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography
                      fontSize={HeaderFontSize_25}
                      className="headerstyle"
                    >
                      Register{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={contentFontSize_15}
                      className="content-card"
                    >
                      Sell online fast with NearShopz!<br /> Register on NearShopz in minutes! Start selling your products online quickly and reach a wider local audience actively searching for businesses like yours. Boost your online presence and unlock sales potential with NearShopz!
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "60.12px",
                    width: "60.12px",
                    borderRadius: "50%",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "white",
                    border: "1px solid #E5EAF4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={Members} alt="Boost your business" />
                  </div>
                </div>
              </CustomCard>
            </div>
            <div style={{cursor:"pointer",width:"100%"}}>
              <CustomCard
                padding={"20px"}
                paddingTop="50px"
                className="card"
                height={"235px"}
                width={"100%"}
                background={"white"}
                borderRadius={"20px"}
                position="relative"
                onClick={handleVerifyClick}
                boxShadow={"none"}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography
                      fontSize={HeaderFontSize_25}
                      className="headerstyle"
                    >
                      Get Verified{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={contentFontSize_15}
                      className="content-card"
                    >
                      Trusted seller, trusted brand <br />
                      Activate your seller portal for powerful features to manage your store and grow sales. Take your local business to new heights with NearShopz!
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "60.12px",
                    width: "60.12px",
                    borderRadius: "50%",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "white",
                    border: "1px solid #E5EAF4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      src={Verify}
                      alt="best online food ordering system"
                      
                    />
                  </div>
                </div>
              </CustomCard>
            </div>
            <div style={{cursor:"pointer",width:"100%"}}>
              <CustomCard
                padding={"20px"}
                paddingTop="50px"
                className="card"
                height={"235px"}
                width={"100%"}
                background={"white"}
                borderRadius={"20px"}
                position="relative"
                onClick={handleCatlogClick}
                boxShadow={"none"}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography
                      fontSize={HeaderFontSize_25}
                      className="headerstyle"
                    >
                      Add Products{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={contentFontSize_15}
                      className="content-card"
                    >
                    Easily add products using our Catalog Builder.<br />
                     Showcase your unique offerings with NearShopz's user-friendly catalog builder. Easily add existing products or upload new ones to create an attractive catalog that grabs local customer attention and boosts sales!
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "60.12px",
                    width: "60.12px",
                    borderRadius: "50%",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "white",
                    border: "1px solid #E5EAF4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      src={Catalog}
                      alt="Local delivery solutions for businesses"
                     
                    />
                  </div>
                </div>
              </CustomCard>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              alignItems: "center",
              padding: "40px",
              justifyContent: "space-evenly",
              flexDirection: mdmatch ? "column" : "row"
            }}
          >
            <div style={{cursor:"pointer",width:"100%"}}>
              <CustomCard
                padding={"20px"}
                paddingTop="50px"
                className="card"
                height={"235px"}
                width={"100%"}
                background={"white"}
                borderRadius={"20px"}
                position="relative"
                onClick={handleEmployeeClick}
                boxShadow={"none"}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography
                      fontSize={HeaderFontSize_25}
                      className="headerstyle"
                    >
                      Add Employees{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={contentFontSize_15}
                      className="content-card"
                    >
                      Scale up your business with shop assistants.<br /> Streamline operations by adding shop assistants on NearShopz. Delegate tasks, improve efficiency, and manage your online business effectively with NearShopz's shop assistant feature.
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "60.12px",
                    width: "60.12px",
                    borderRadius: "50%",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "white",
                    border: "1px solid #E5EAF4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      src={Employee}
                      alt="local delivery service"
                      
                    />
                  </div>
                </div>
              </CustomCard>
            </div>
            <div style={{cursor:"pointer",width:"100%"}}>
              <CustomCard
                padding={"20px"}
                paddingTop="50px"
                className="card"
                height={"235px"}
                width={"100%"}
                background={"white"}
                borderRadius={"20px"}
                position="relative"
                onClick={handleOrderClick}
                boxShadow={"none"}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography
                      fontSize={HeaderFontSize_25}
                      className="headerstyle"
                    >
                      Manage Orders{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={contentFontSize_15}
                      className="content-card"
                    >
                      Fulfill orders with ease with NearShopz .<br />
                      Manage online orders effortlessly with NearShopz! Choose delivery slots, modify quantities, communicate directly with customers, and gain valuable business insights for informed decisions. Optimize fulfillment and keep your customers happy!
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "60.12px",
                    width: "60.12px",
                    borderRadius: "50%",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "white",
                    border: "1px solid #E5EAF4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={Order} alt="restaurant online ordering system" />
                  </div>
                </div>
              </CustomCard>
            </div>
            <div style={{cursor:"pointer",width:"100%"}}>
              <CustomCard
                padding={"20px"}
                paddingTop="50px"
                className="card"
                height={"235px"}
                width={"100%"}
                background={"white"}
                borderRadius={"20px"}
                position="relative"
                onClick={handleStreamClick}
                boxShadow={"none"}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography
                      fontSize={HeaderFontSize_25}
                      className="headerstyle"
                    >
                      Regular Pay Out{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontSize={contentFontSize_15}
                      className="content-card"
                    >
                      Monitor your earnings and receive regular payout  <br /> Gain a clear financial overview with NearShopz. Track earnings, manage payouts, and access detailed financial information for a seamless and transparent experience.
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "60.12px",
                    width: "60.12px",
                    borderRadius: "50%",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "white",
                    border: "1px solid #E5EAF4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      src={Streamline}
                      alt="restaurant delivery management software"
                      
                    />
                  </div>
                </div>
              </CustomCard>
            </div>
          </div>
        </div>
      </div>
      {reg && (
        <>
          <Dialog
            maxHeight="70%"
            borderRadius="20px"
            open={reg}
            titleAlign="center"
            padding="30px"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",
                  // Set your width here
                  padding: "10px 10px 30px 10px",
                  borderRadius: "20px",
                },
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <img src={Register} alt="Register" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    fontSize={HeaderFontsize_36}
                    className="headerstyle-1"
                  >
                    Register{" "}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize={contentFontSize_15}
                    className="content-card-1"
                  >
                    Unlock the potential of your business by registering on
                    NearShopz. Easily upload your documents and complete the
                    registration process to start selling your products. Gain
                    access to a wide customer base and boost your online
                    presence with NearShopz. 
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <CustomButton
                    width={
                      upXlmatch
                        ? "160px"
                        : xlmatch
                        ? lgmatch
                          ? mdmatch
                            ? smmatch
                              ? xsmatch
                                ? "40px"
                                : "60px"
                              : "80px"
                            : "100px"
                          : "150px"
                        : "160px"
                    }
                    height={"40px"}
                    // borderRadius="20px"
                    background={
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
                    }
                    onClick={handleClose}
                  >
                    <Typography
                      fontSize={ButtonFontSize_18}
                      className="Add-product-btn"
                    >
                      Close
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      {verify && (
        <>
          <Dialog
            maxHeight="70%"
            borderRadius="20px"
            open={verify}
            titleAlign="center"
            padding="30px"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",
                  // Set your width here
                  padding: "10px 10px 30px 10px",
                  borderRadius: "20px",
                },
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <img src={Verify1} alt="Get Verified" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    fontSize={HeaderFontsize_36}
                    className="headerstyle-1"
                  >
                    Get Verified{" "}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize={contentFontSize_15}
                    className="content-card-1"
                  >
                    Get verified on NearShopz and take your business to new
                    heights. By attaining a verified business status and
                    activating your seller portal, you'll establish credibility
                    and trust among potential customers.
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <CustomButton
                    width={
                      upXlmatch
                        ? "160px"
                        : xlmatch
                        ? lgmatch
                          ? mdmatch
                            ? smmatch
                              ? xsmatch
                                ? "40px"
                                : "60px"
                              : "80px"
                            : "100px"
                          : "150px"
                        : "160px"
                    }
                    height={"40px"}
                    // borderRadius="20px"
                    background={
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
                    }
                    onClick={handleClose}
                  >
                    <Typography
                      fontSize={ButtonFontSize_18}
                      className="Add-product-btn"
                    >
                      Close
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      {catalog && (
        <>
          <Dialog
            maxHeight="70%"
            borderRadius="20px"
            open={catalog}
            titleAlign="center"
            padding="30px"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",
                  // Set your width here
                  padding: "10px 10px 30px 10px",
                  borderRadius: "20px",
                },
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <img src={Catalog1} alt="Create Catalog" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    fontSize={HeaderFontsize_36}
                    className="headerstyle-1"
                  >
                    Create Catalog{" "}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize={contentFontSize_15}
                    className="content-card-1"
                  >
                    Utilize our catalog builder to easily add your products from
                    our vast collection. Alternatively, you can add new products
                    to showcase your unique offerings. Tap into the power of our
                    catalog builder and make your products shine on NearShopz.
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <CustomButton
                    width={
                      upXlmatch
                        ? "160px"
                        : xlmatch
                        ? lgmatch
                          ? mdmatch
                            ? smmatch
                              ? xsmatch
                                ? "40px"
                                : "60px"
                              : "80px"
                            : "100px"
                          : "150px"
                        : "160px"
                    }
                    height={"40px"}
                    // borderRadius="20px"
                    background={
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
                    }
                    onClick={handleClose}
                  >
                    <Typography
                      fontSize={ButtonFontSize_18}
                      className="Add-product-btn"
                    >
                      Close
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      {employee && (
        <>
          <Dialog
            maxHeight="70%"
            borderRadius="20px"
            open={employee}
            titleAlign="center"
            padding="30px"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",
                  // Set your width here
                  padding: "10px 10px 30px 10px",
                  borderRadius: "20px",
                },
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <img src={Employee1} alt="Add Employees" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    fontSize={HeaderFontsize_36}
                    className="headerstyle-1"
                  >
                    Add Employees{" "}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize={contentFontSize_15}
                    className="content-card-1"
                  >
                    Scale up your operations by adding shop assistants on
                    NearShopz. Delegate tasks and assign roles to your shop
                    assistants, allowing them to log in to the seller portal or
                    app. Streamline your order management process and improve
                    efficiency with the help of your trusted shop assistants.  
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <CustomButton
                    width={
                      upXlmatch
                        ? "160px"
                        : xlmatch
                        ? lgmatch
                          ? mdmatch
                            ? smmatch
                              ? xsmatch
                                ? "40px"
                                : "60px"
                              : "80px"
                            : "100px"
                          : "150px"
                        : "160px"
                    }
                    height={"40px"}
                    // borderRadius="20px"
                    background={
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
                    }
                    onClick={handleClose}
                  >
                    <Typography
                      fontSize={ButtonFontSize_18}
                      className="Add-product-btn"
                    >
                      Close
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      {order && (
        <>
          <Dialog
            maxHeight="70%"
            borderRadius="20px"
            open={order}
            titleAlign="center"
            padding="30px"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",
                  // Set your width here
                  padding: "10px 10px 30px 10px",
                  borderRadius: "20px",
                },
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <img src={Order1} alt="Order Management" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    fontSize={HeaderFontsize_36}
                    className="headerstyle-1"
                  >
                    Order Management{" "}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize={contentFontSize_15}
                    className="content-card-1"
                  >
                    Manage orders effortlessly, choose delivery slots, modify
                    quantities, provide coupons, communicate with customers, and
                    navigate with ease. Additionally, our comprehensive
                    dashboard provides detailed insights into order volume,
                    product updates, and other critical business metrics.
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <CustomButton
                    width={
                      upXlmatch
                        ? "160px"
                        : xlmatch
                        ? lgmatch
                          ? mdmatch
                            ? smmatch
                              ? xsmatch
                                ? "40px"
                                : "60px"
                              : "80px"
                            : "100px"
                          : "150px"
                        : "160px"
                    }
                    height={"40px"}
                    // borderRadius="20px"
                    background={
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
                    }
                    onClick={handleClose}
                  >
                    <Typography
                      fontSize={ButtonFontSize_18}
                      className="Add-product-btn"
                    >
                      Close
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      {stream && (
        <>
          <Dialog
            maxHeight="70%"
            borderRadius="20px"
            open={stream}
            titleAlign="center"
            padding="30px"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px",
                  // Set your width here
                  padding: "10px 10px 30px 10px",
                  borderRadius: "20px",
                },
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <img src={Stream1} alt="Streamlined Pay Out" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    fontSize={HeaderFontsize_36}
                    className="headerstyle-1"
                  >
                    Streamlined Pay Out{" "}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize={contentFontSize_15}
                    className="content-card-1"
                  >
                    Track and manage your payout amounts with ease, while
                    gaining access to detailed financial information. NearShopz
                    is designed to simplify payment management, providing you
                    with a seamless and accessible experience.  
                    <br />
                    <br />Start your online selling journey with NearShopz
                    today and reach a wider audience. Join now to experience the
                    benefits of selling on NearShopz! 
                  </Typography>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <CustomButton
                    width={
                      upXlmatch
                        ? "160px"
                        : xlmatch
                        ? lgmatch
                          ? mdmatch
                            ? smmatch
                              ? xsmatch
                                ? "40px"
                                : "60px"
                              : "80px"
                            : "100px"
                          : "150px"
                        : "160px"
                    }
                    height={"40px"}
                    // borderRadius="20px"
                    background={
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)"
                    }
                    onClick={handleClose}
                  >
                    <Typography
                      fontSize={ButtonFontSize_18}
                      className="Add-product-btn"
                    >
                      Close
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      </div>
    </>
  );
}

export default LandingCard1;
