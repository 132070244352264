import {
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import "./OutForDelivery.css";
import Direction from "../../../utils/Icon/Direction.svg";
import Phone from "../../../utils/Icon/Phone.svg";
import ReadyForLine from "../../../utils/Icon/ReadyForLine.svg";
import LineInReadyForDel from "../../../utils/Icon/LineInReadyForDel.svg";
import UploadImage from "../../../utils/Icon/UploadImage.svg";
import Camera from "../../../utils/Icon/Camera.svg";
import BlueClock from "../../../utils/Icon/BlueClock.svg";
import CustomButton from "../../Commoncomponents/button";
import Payment from "../../../utils/Icon/Payment.svg";
import Pointer from "../../../utils/Icon/Pointer.svg";
import lines from "../../../utils/Icon/lines.svg";
import Scanner from "../../../utils/Icon/Scanner.svg";
import DirectionSub from "../../../utils/Icon/DirectionSub.svg";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_22,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_17,
  HeaderFontSize_24,
} from "../../Typography";
import CustomCard from "../../Commoncomponents/card";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { message } from "antd";
import LoaderCompleted from "../Loader/LoaderComplete";
import CustomTextField from "../../Commoncomponents/input";
import CustomDialog from "../../Commoncomponents/dialog";
import { io } from "socket.io-client";

function OutForDelivery(props) {
  const location = useLocation();
  const orderid = location.state?.orderid;
  localStorage.setItem("orderid",orderid)
  useEffect(() => {
    props.getSearchDetails(orderid);
  }, []);

  const OutForDeliveryDetails = ("props", props && props.searchDetails);
  console.log("out", OutForDeliveryDetails);
  const nav = useNavigate();
  function handleClickOne() {
    nav("/home/MyOrders/OutForDelivery");
  }
  function handleClickTwo() {
    nav("/home/MyOrders");
  }
  const from = "out";
  function handleDirection() {
    nav("/home/MyOrders/GoogleMap", { state: { orderid: orderid,from:from } });
  }
  //const dataItem = JSON.parse(localStorage.getItem("selectedItems"));
  const totalProductPrice = OutForDeliveryDetails
    ? OutForDeliveryDetails?.products?.reduce(
        (total, item) =>
          total + item?.shopAssistantQuantity * item?.productPrice,
        0
      )
    : 0;
  const deliveryCharge =
    OutForDeliveryDetails?.version === 3
      ? OutForDeliveryDetails?.deliveryChargeWithoutMarkup
      : OutForDeliveryDetails?.deliveryCharge;
  const packingCharge =
    OutForDeliveryDetails?.version === 3 &&
    OutForDeliveryDetails?.shoppingPackingChargeWithoutMarkup !== null
      ? OutForDeliveryDetails?.shoppingPackingChargeWithoutMarkup
      : OutForDeliveryDetails?.packingCharge;

  const discountPrice =
    OutForDeliveryDetails?.couponID?.storeid !== null
      ? OutForDeliveryDetails?.discountPrice
      : null;

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;
  const [deliveryCompleted, setDeliveryCompleted] = useState(true);

  const handleboxChange = (event) => {
    // You can also toggle the state based on the checkbox value
    setDeliveryCompleted(!deliveryCompleted);

    console.log("Checkbox clicked, state will update.");
  };
  const OrderHistorybuttonStateRef = useRef(false);
  const [OrderHistory, setOrderHistory] = useState([]);
  // function handleDelivered() {
  //   // const dataHistory = JSON.parse(localStorage.getItem("orderCardDetails"));
  //   // OrderHistorybuttonStateRef.current = !OrderHistorybuttonStateRef;
  //   // dataHistory.buttonState = OrderHistorybuttonStateRef.current;
  //   // const orderId = dataHistory.orderId;

  //   // const OrderHistory = JSON.parse(localStorage.getItem("OrderHistory")) || [];

  //   // // Check if the orderId is already present in acceptedOrders
  //   // const isOrderHistory = OrderHistory.some(
  //   //   (order) => order.orderId === orderId
  //   // );

  //   // if (!isOrderHistory) {
  //   //   // If the order is not already accepted, update both the local storage and state
  //   //   const updatedOrderHistory = [...OrderHistory, dataHistory];
  //   //   localStorage.setItem("OrderHistory", JSON.stringify(updatedOrderHistory));

  //   //   // Update the acceptedOrders state with the new order
  //   //   setOrderHistory(updatedOrderHistory);
  //   // } else {
  //   //   // The order is already accepted, you can handle this case if needed
  //   //   alert("This order has already been accepted.");
  //   // }

  //   // // Update the orderCardDetails in local storage with the new order
  //   // localStorage.setItem("orderCardDetails", JSON.stringify(dataItem));
  //   // console.log(dataItem);
  //   if(checked && deliveryCompleted){
  //     message.error({
  //       content: "Status Changed to Completed",
  //       className: "custom-class",
  //       style: {
  //         marginTop: "20vh",
  //         marginLeft: "60vh",
  //       },
  //     });
  //     if(checked && deliveryCompleted ){
  //       nav("/home/MyOrders/OrderHistory");
  //       }
  //   }else{
  //     message.error({
  //       content: "Please choose the payment method",
  //       className: "custom-class",
  //       style: {
  //         marginTop: "20vh",
  //         marginLeft: "60vh",
  //       },
  //     })
  //   }
  //   const status = "Completed";
  //   props.ChangetoCompletedStatus({
  //     orderid: orderid,
  //     stats: { status: status },
  //   });

  // }
  const [loading, setLoading] = useState(false);
  function handleDelivereds() {
    localStorage.removeItem("verify")
    props.clearVerifyPayment()
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      const status = "Completed";
      props.ChangetoCompletedStatus({
        orderid: orderid,
        stats: { status: status },
      });
      if (location.state.shopAssistantOrder) {
        nav("/home/ShopAssistant/orders", {
          state: {
            shopAssistantId: location.state.shopAssistantId,
            fname: location.state.fname,
            lname: location.state.lname,
            phno: location.state.phno,
            profilePicUrl: location.state.profilePicUrl,
            moveCompleted: true,
          },
        });
      } else {
        nav("/home/MyOrders/OrderHistory");
      }
    }, 4000);
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const [showScanner, setShowScanner] = useState(false);
  const [showCollected, setShowCollected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dataVerify = JSON.parse(localStorage.getItem("verify"));
  console.log("dataVerify",dataVerify)
  console.log("dataPayment",props?.VerifyQrCodeData?.verifyQrCodePayment)
  //  useEffect(()=>{
  //   if (dataVerify) {
  //     message.success({
  //       content: "Payment Received",
  //       className: "custom-class",
  //       style: { marginTop: "10vh", marginLeft: "15vw" },
  //       duration: 3.5,
  //     });
  //     props.getSearchDetails(orderid); // Use defined orderid
  //   }
  //  },[])
  const orderId = localStorage.getItem('orderid'); // Ensure orderid is defined

  // Declare the socket variable outside of useEffect
  let socket;
  const [isSocketConnected, setIsSocketConnected] = useState(true); 
  useEffect(() => {
    // Initialize the socket connection
    socket = io.connect("https://testserver.nearshopz.com");


    socket.on("connect", () => {
      console.log("Socket connected");
      setIsSocketConnected(true); // Update flag on successful connection
    });
    // Listen for payment received event
    socket.on(`${orderId}_order`, (data) => {
      props.setOrderDetails(data);
      console.log("Payment received data:", data);
      localStorage.setItem("verify", JSON.stringify(data));
      props.connectionSuccess();

      // Display payment received message
      message.success({
        content: "Payment Received",
        className: "custom-class",
        style: { marginTop: "10vh", marginLeft: "15vw" },
        duration: 3.5,
      });
      props.getSearchDetails(orderId);
    });
    console.log("propssss",props.connectionData)
    socket.on("connect_error", (error) => {
      console.log("Socket disconnected. Verifying QR code...",error);
      setIsSocketConnected(true);
      if (orderId) {
        // Call VerifyQrCode action with the order ID
        props.VerifyQrCode({ orderid: orderId });

         if (props?.VerifyQrCodeData?.verifyQrCodePayment?.paymentStatus === 2 && OutForDeliveryDetails?.orderNumber === props?.VerifyQrCodeData?.verifyQrCodePayment?.orderNumber && isSocketConnected  ) {
          message.success({
            content: "Payment Recieved",
            className: "custom-class",        
            style: { marginTop: "10vh", marginLeft: "15vw" },
            duration: 3.5,
          });
          props.getSearchDetails(orderId);
        }
      } else {
        console.error("Order ID not found in localStorage.");
      }
    });
  
    // Clean up the socket connection on component unmount or dependency change
    return () => {
      socket.disconnect();
    };
  }, [props?.QrCodeData?.generateQRcodeForCOD?.image_url, OutForDeliveryDetails?.qrCodeUrl]); // Include orderId as a dependency

 useEffect(()=>{
  props.clearVerifyPayment()
 },[])

  const scanner = props?.QrCodeData?.generateQRcodeForCOD.image_url;
  console.log("scanner",scanner)
  const handleCheckboxChange = (event) => {
    if(OutForDeliveryDetails.qrCodeUrl === null){
     props.getQrCode({orderid:orderid})
  }
    const isChecked = event.target.checked;
    setShowScanner(isChecked);
    setShowCollected(!isChecked);
    setChecked(false);
    //setDeliveryCompleted(!deliveryCompleted)
  };
  const handleChange = (event) => {
   
    const isChecked = event.target.checked;
  
    // Update state based on checkbox change
    setShowCollected(isChecked);
    setShowScanner(!isChecked);
  
    // Check if QR code is missing and fetch it if necessary
    
    
    // Optionally handle 'deliveryCompleted' if needed
    // setDeliveryCompleted(!deliveryCompleted);
  };
  
  const [checked, setChecked] = useState();
  const handleCheckbox = (event) => {
    const checked = event.target.checked;
    setChecked(checked);
    console.log("checked", checked);
  };
  // const handleCheckbox = (event) => {
  //   const isChecked = event.target.checked;
  //   setChecked(isChecked);
  //   //setDeliveryCompleted(isChecked); // Set delivery completed based on checkbox state
  // };
  const [share, setShare] = useState(false);
  const [open, setOpen] = useState(false);
  function handleShare() {
    setShare(true);
    setOpen(true);
  }
  function handleDelivered() {
    localStorage.removeItem("verify")
    props.clearVerifyPayment()
    if (checked && !deliveryCompleted) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        const status = "Completed";
        props.ChangetoCompletedStatus({
          orderid: orderid,
          stats: { status: status },
        });
        if (location.state.shopAssistantOrder) {
          nav("/home/ShopAssistant/orders", {
            state: {
              shopAssistantId: location.state.shopAssistantId,
              fname: location.state.fname,
              lname: location.state.lname,
              phno: location.state.phno,
              profilePicUrl: location.state.profilePicUrl,
              moveCompleted: true,
            },
          });
        } else {
          nav("/home/MyOrders/OrderHistory");
        }
      }, 4000);
    } else {
      message.error({
        content: "Please choose the payment method",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "40vh",
        },
      });
    }
  }
  const packingImages = props?.proImage?.imageEditAndUpload?.Location;

  const url = localStorage.getItem("googleMapsUrl");

  const [copySuccess, setCopySuccess] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopySuccess(false);
  };

  const copyCodeToClipboard = async () => {
    const el = inputRef.current;
    el.select();
    try {
      await navigator.clipboard.writeText(el.value);
      setCopySuccess(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const inputRef = React.createRef();

  const destination = {
    lat: Number(OutForDeliveryDetails?.deliveryLat),
    lng: Number(OutForDeliveryDetails?.deliveryLng),
  };
  const origin = {
    lat:
      Number(
        OutForDeliveryDetails?.storeid?.storeCoordinates?.coordinates[1]
      ) || 10.850516,
    lng:
      Number(
        OutForDeliveryDetails?.storeid?.storeCoordinates?.coordinates[0]
      ) || 76.27108,
  };
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`;
  localStorage.setItem("googleMapsUrl", googleMapsUrl);

  return (
    <>
      <div style={{ marginLeft: "0px", marginRight: "0px" }}>
        {!loading && (
          <>
            <div className="MainOutDelivery">
              <div style={{ cursor: "pointer" }} onClick={handleClickOne}>
                <Typography
                  fontSize={HeaderNavigationFontSize_20_xs_13}
                  className="MainOut"
                  sx={{ textDecoration: "underline" }}
                >
                  Ready for Delivery
                </Typography>
              </div>
              <div>
                <Typography
                  className="MainOut"
                  fontSize={HeaderNavigationFontSize_20_xs_13}
                  sx={{
                    padding: xlmatch ? "0px 7px 0px 7px" : "0px 0px 0px 0px",
                  }}
                >
                  /
                </Typography>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="MainOutForDelivery"
                // onClick={handleClickTwo}
              >
                <Typography fontSize={HeaderNavigationFontSize_20_xs_13}>
                  View Out for Delivery Details
                </Typography>
              </div>
            </div>

            {/* start */}

            {/* {end} */}
            <div style={{ marginTop: "30px" }}>
              <CustomCard height="100%" width="100%" padding="0px">
                <Grid container spacing={2} sx={{ paddingLeft: "0px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "28px 40px 2px 44px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img
                          src={Direction}
                          style={{ width: "20px", height: "20px" }}
                          alt="Direction Icon"
                        />

                        <div>
                          <Typography
                            className="nameStyleOut"
                            fontSize={contentFontsize_17}
                          >
                            {OutForDeliveryDetails &&
                              OutForDeliveryDetails.customerName}
                          </Typography>
                        </div>
                      </div>

                      {/* <div>
                    <Typography
                      className="addressOut"
                      sx={{ fontSize: { contentFontsize_14 } }}
                    >
                      {OutForDeliveryDetails &&
                        OutForDeliveryDetails.deliveryAddress}
                    </Typography>
                  </div> */}
                      <div>
                        <Typography
                          //key={index}
                          className="addressOut"
                          sx={{ fontSize: contentFontsize_14 }}
                        >
                          {/* {newOrderDetails && newOrderDetails.deliverytime}{" "} */}
                          {OutForDeliveryDetails?.deliveryAddress} ,{" "}
                          {OutForDeliveryDetails?.stats[4]?.created},
                          {OutForDeliveryDetails?.stats[4]?.createdTime}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        height: "100%",
                        margin: "28px 40px 2px 44px",
                      }}
                    >
                      <img
                        src={Phone}
                        style={{ width: "20px", height: "20px" }}
                        alt="Phone icon"
                      />
                      <span>
                        <Typography
                          className="numberStyleOut"
                          sx={{ fontSize: { contentFontSize_18 } }}
                        >
                          +91
                          {OutForDeliveryDetails &&
                            OutForDeliveryDetails.mobileNumber}
                        </Typography>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ margin: "20px 0px" }}>
                  <img style={{ width: "100%" }} src={ReadyForLine} />
                </div>
                <Grid container spacing={2} sx={{ paddingLeft: "0px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ margin: "1px -125px 44px 47px", paddingLeft: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "80px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                background: "#85BE49",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <div
                              style={{
                                background: "#85BE49",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </div>
                          <div className="connecting-line"></div>
                          {/* </div> */}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            lineHeight: "0.8",
                          }}
                        >
                          <div
                            fontSize={contentFontSize_18}
                            style={{
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            Outlet
                          </div>
                          <div
                            fontSize={contentFontSize_18}
                            style={{
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            {/* 35 mins */}
                            {OutForDeliveryDetails?.travelTime?.text}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            lineHeight: "0.8",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#8B8989",
                              fontWeight: "500",
                            }}
                          >
                            {OutForDeliveryDetails?.storeid?.storeName}
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#8B8989",
                              fontWeight: "500",
                            }}
                          >
                            {/* 3.6 kilometers */}
                            {OutForDeliveryDetails?.travelDistance?.text}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      margin: "1px -20px 44px 47px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "30px",
                        height: "100%",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <CustomButton
                          width="120px"
                          height="45px"
                          variant="outlined"
                          color="#85BE49"
                          borderColor="#85BE49"
                          background="none"
                          hasIcon={true}
                          iconPosition="startIcon"
                          onClick={handleDirection}
                        >
                          <img
                            src={Direction}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                          Direction
                        </CustomButton>
                      </div>
                      <div onClick={handleShare}>
                        <img
                          onClick={handleShare}
                          src={DirectionSub}
                          style={{
                            width: "25px",
                            height: "33px",
                            marginRight: "24px",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CustomCard>
              {share && (
                <CustomDialog
                  width="600px"
                  height="200px"
                  borderRadius="20px"
                  open={open}
                  onClose={handleClose}
                  titleAlign="center"
                  // title="Share URL"
                  top={"15px"}
                  right={"20px"}
                  // showCloseButton={false}
                >
                  <Typography
                    style={{
                      color: "#170F49",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                    className="head-font"
                    fontSize={HeaderFontSize_24}
                  >
                    Share URL
                  </Typography>
                  <DialogContent>
                    <div>
                      <CustomTextField
                        width="100%"
                        value={url}
                        inputRef={inputRef}
                        endAdornment={
                          <InputAdornment position="end">
                            <div>
                              <CustomButton
                                onClick={() => copyCodeToClipboard()}
                              >
                                <Typography fontSize={contentFontSize_16}>
                                  Copy Url
                                </Typography>
                              </CustomButton>
                            </div>
                          </InputAdornment>
                        }
                      ></CustomTextField>
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {copySuccess ? (
                          <div style={{ color: "#85BE49", paddingTop: "6px" }}>
                            <Typography fontSize={contentFontSize_16}>
                              Copied to Clipboard
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </DialogContent>
                </CustomDialog>
              )}
            </div>
            <div className="outfordeliveryBox">
              <div>
                <Typography
                  fontSize={contentFontsize_17}
                  className="textStyleOut"
                >
                  #{OutForDeliveryDetails && OutForDeliveryDetails.orderNumber}{" "}
                  - Scheduled on{" "}
                  {OutForDeliveryDetails && OutForDeliveryDetails.deliveryDate},{" "}
                  {OutForDeliveryDetails && OutForDeliveryDetails.deliveryTime}
                </Typography>
              </div>
            </div>
            {/* <Grid
          container
          justifyContent="center"
          spacing={2}
          style={{ marginTop: "40px" }}
        >
          {data &&
            data.length > 0 &&
            data.map((order, index) => (
              <>
                {order.products &&
                  order.products.length > 0 &&
                  order.products.map((item, i) => (
                    <>
                      <Grid item xs={2} lg={3}>
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          src={Pointer}
                          alt={item?.name}
                        />
                      </Grid>
                      <Grid item xs={7} lg={6}>
                        <Typography
                          className="itemDetails"
                          fontSize={contentFontSize_18}
                        >
                          {item.name}
                          <br />
                          <Typography className="itemDetails">
                            {item.quantity}X {item?.name}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} lg={3}>
                        <Typography
                          className="itemDetails"
                          fontSize={contentFontSize_18}
                        >
                          ₹{item?.quantity * item?.price}
                        </Typography>
                      </Grid>
                    </>
                  ))}
              </>
            ))}
        </Grid> */}
            {/* <Grid
          container
          spacing={2}
          sx={{
            marginTop: "10px",
            marginLeft: matchessm ? "0px" : matches ? "30px" : "70px",
          }}
        >
          
          <>
            {OutForDeliveryDetails?.products &&
              OutForDeliveryDetails?.products.length > 0 &&
              OutForDeliveryDetails?.products.map((item, i) => (
                <>
                  <Grid item xs={2} sm={1} md={1} lg={1} className="custom" sx={{paddingTop:"0px"}}>
                    <div className="productDetailsOut">
                      <img
                        style={{
                          width: matchessm ? "15px" : "20px",
                          height: matchessm ? "15px" : "20px",
                        }}
                        src={Pointer}
                        alt={item?.name}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={8} lg={8} sm={8} sx={{paddingTop:"0px"}}>
                    <div>
                      <Typography
                        fontSize={contentFontSize_18_xs_13}
                        className="productDetailsOut"
                      >
                        {item.productid.productname}
                        <br />
                        <Typography
                          fontSize={contentFontsize_14}
                          sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                        >
                          {item.quantity}X ₹{(item?.productPrice).toFixed(2)}
                        </Typography>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} md={3} lg={3} sm={3} sx={{paddingTop:"0px"}}>
                    <div>
                      <Typography
                        className="productDetailsOut"
                        fontSize={contentFontSize_18_xs_13}
                      >
                        ₹{(item?.shopAssistantQuantity * item?.price).toFixed(2)}
                      </Typography>
                    </div>
                  </Grid>
                </>
              ))}
          </>
       
        </Grid> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "20px",
                padding: smmatch ? "10px 10px" : "10px 70px",
              }}
            >
              {OutForDeliveryDetails &&
                OutForDeliveryDetails?.products?.map((item) => (

                  <>
                  {item.shopAssistantQuantity === 0 ? (
                  <div style={{ display: "flex",color:"grey" }}>
                  {/* {console.log("item vannu", item)} */}
                  <div style={{ flex: "1" }}>
                    <div style={{ display: "flex", gap: "60px" }}>
                      <div className="productDetails">
                        <img
                          style={{
                            width: matchessm ? "15px" : "20px",
                            height: matchessm ? "15px" : "20px",
                            marginTop:"0px",
                          }}    
                          src={Pointer}
                          alt={item?.name}
                        />
                      </div>

                      <div>
                        <Typography
                          fontSize={contentFontsize_17}
                          className="productDetails"
                        >
                          {item.productid?.productname}
                          <br />
                          <Typography
                            fontSize={contentFontsize_14}
                            sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                          >
                            {item.shopAssistantQuantity}X ₹{(item?.productPrice)?.toFixed(2)}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                        {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                        <p
                          className="productDetails"
                          fontSize={contentFontsize_17}
                        >

                          ₹{(item?.shopAssistantQuantity * item?.productPrice)?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                ):(
                    <div style={{ display: "flex" }}>
                      {/* {console.log("item vannu", item)} */}
                      <div style={{ flex: "1" }}>
                        <div style={{ display: "flex", gap: "60px" }}>
                          <div className="productDetails">
                            <img
                              style={{
                                width: matchessm ? "15px" : "20px",
                                height: matchessm ? "15px" : "20px",
                                marginTop: "10px",
                              }}
                              src={Pointer}
                              alt={item?.name}
                            />
                          </div>

                          <div style={{ marginBottom: "0px" }}>
                            <Typography
                              fontSize={contentFontsize_17}
                              className="productDetails"
                            >
                              {item?.productid?.productname}
                              <br />
                              <Typography
                                fontSize={contentFontsize_14}
                                sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                              >
                                {item.shopAssistantQuantity}X ₹
                                {item?.productPrice?.toFixed(2)}
                              </Typography>
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div style={{ flex: "1" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1px",
                            }}
                          >
                            {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                            <Typography
                              className="productDetails"
                              fontSize={contentFontsize_17}
                            >
                              ₹
                              {(
                                item?.shopAssistantQuantity * item?.productPrice
                              )?.toFixed(2)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                )}
                  </>
                            
                ))}
            </div>

            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sm={12}
                sx={{ marginTop: "20px" }}
              >
                <img
                  style={{ width: "100%" }}
                  src={LineInReadyForDel}
                  alt="LineInReadyForDel"
                />
              </Grid>
            </Grid>

            {/* <div>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails"
                >
                  Total Product price
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "25px",
              }}
            >
              <Typography
                className="productDetails"
                fontSize={contentFontSize_16}
              >
                ₹{totalProductPrice}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails"
                >
                  Delivery Charge
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "25px",
              }}
            >
              <Typography
                className="productDetails"
                fontSize={contentFontSize_16}
              >
                {" "}
                ₹{deliveryCharge}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ color: "#2D93FB" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails"
                >
                  {" "}
                  Coupon - (GRUB)
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
            <Grid
              item
              
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                textAlign: "right",
                color: "#2D93FB",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "25px",
              }}
            >
              <Typography
                className="productDetails"
                fontSize={contentFontSize_16}
              >
                -₹{couponDiscount}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails"
                >
                  {" "}
                  Packing Charges
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "25px",
              }}
            >
              <Typography className="productDetails" sx={{ fontWeight: "500" }}>
                {" "}
                ₹ {packingCharge}
              </Typography>
            </Grid>
          </Grid>
        </div> */}

            {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "70px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} >
            <Typography
              fontSize={contentFontSize_16}
              className="productDetailOut"
            >
              Total Product price
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2} sm={3} >
            <Typography
              className="productDetailOut" 
              fontSize={contentFontSize_16}
            >
              ₹{totalProductPrice?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "70px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
            <Typography
              fontSize={contentFontSize_16}
              className="productDetailOut"
            >
              Delivery Charge
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
            <Typography
              className="productDetailOut"
              fontSize={contentFontSize_16}
            >
              ₹{OutForDeliveryDetails?.deliveryCharge?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "70px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
            <Typography
              fontSize={contentFontSize_16}
              className="productDetailOut"
            >
              Coupon - (GRUB)
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
            <Typography
              className="productDetailOut"
              fontSize={contentFontSize_16}
            >
              -₹{OutForDeliveryDetails?.discountPrice?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "70px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
            <Typography
              fontSize={contentFontSize_16}
              className="productDetailOut"
            >
              Packing Charges
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
            <Typography
              className="productDetailOut"
              fontSize={contentFontSize_16}
            >
              {" "}
              ₹{OutForDeliveryDetails?.packingCharge?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} sm={12} sx={{ marginTop: "10px" }}>
            <img
              style={{ width: "100%" }}
              src={LineInReadyForDel}
              alt="LineInReadyForDel"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "70px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}>
            <Typography
              fontSize={contentFontSize_20_xs_18}
              sx={{ fontWeight: "700", fontFamily: "Roboto" }}
            >
              Total
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>
            <Typography
              sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              fontSize={contentFontSize_16}
            >
              ₹{grandTotal.toFixed(2)}
            </Typography>
          </Grid>
        </Grid> */}

            <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails1"
                >
                  Total Product price
                </Typography>
                {/* </Grid> */}
                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
                <Typography
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                >
                  {/* {totalProductPrice?.toFixed(2)} */}₹
                  {OutForDeliveryDetails?.shoppingTotalPriceWithoutMarkup
                    ? OutForDeliveryDetails?.shoppingTotalPriceWithoutMarkup?.toFixed(
                        2
                      )
                    : OutForDeliveryDetails?.totalPriceWithoutMarkup?.toFixed(2)
                    ? OutForDeliveryDetails?.totalPriceWithoutMarkup?.toFixed(2)
                    : totalProductPrice?.toFixed(2)}
                </Typography>
              </div>
              {(OutForDeliveryDetails?.version === 3 &&
                OutForDeliveryDetails?.deliveryBy === null) ||
              OutForDeliveryDetails?.deliveryBy === 1 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    fontSize={contentFontSize_16}
                    className="productDetails1"
                  >
                    Delivery Charge
                  </Typography>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <Typography
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "}
                    {OutForDeliveryDetails?.deliveryChargeWithoutMarkup?.toFixed(
                      2
                    )}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    fontSize={contentFontSize_16}
                    className="productDetails1"
                  >
                    Delivery Charge
                  </Typography>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <Typography
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹ {OutForDeliveryDetails?.deliveryCharge?.toFixed(2)}
                  </Typography>
                </div>
              )}
              {/* </Grid>
        </Grid> */}
              {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "60px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}> */}
              {OutForDeliveryDetails?.discountPrice &&
              OutForDeliveryDetails?.couponID?.storeid !== null ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    fontSize={contentFontSize_16}
                    sx={{ color: "rgba(45, 147, 251, 1)" }}
                    className="productDetails1"
                  >
                    Coupon - ({OutForDeliveryDetails?.couponID?.couponCode})
                  </Typography>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <Typography
                    className="productDetails1"
                    sx={{ color: "rgba(45, 147, 251, 1)" }}
                    fontSize={contentFontSize_16}
                  >
                    -₹{OutForDeliveryDetails?.discountPrice?.toFixed(2)}
                  </Typography>
                </div>
              ) : null}
              {OutForDeliveryDetails?.version === 3 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    fontSize={contentFontSize_16}
                    className="productDetails1"
                  >
                    Packing Charge
                  </Typography>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <Typography
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"22px"}}
                  >
                    ₹ {packingCharge?.toFixed(2)}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    fontSize={contentFontSize_16}
                    className="productDetails1"
                  >
                    Packing Charge
                  </Typography>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <Typography
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"22px"}}
                  >
                    ₹ {packingCharge?.toFixed(2)}
                  </Typography>
                </div>
              )}
            </div>
            {/* </Grid>
        </Grid> */}
            <Grid container>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <img
                  style={{ width: "100%" }}
                  src={LineInReadyForDel}
                  alt="LineInReadyForDel"
                />
              </Grid>
            </Grid>
            {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "60px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}> */}
            <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_20_xs_18}
                  sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                >
                  Total
                </Typography>
                {/* </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                <Typography
                  sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                  fontSize={contentFontSize_16}
                >
                  ₹{grandTotal.toFixed(2)}
                  {/* {OutForDeliveryDetails?.shoppingtTotalPayable?.toFixed(2)} */}
                </Typography>
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Typography
                style={{ fontWeight: "600" }}
                fontSize={contentFontSize_20}
              >
                Uploaded Product Images
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              {OutForDeliveryDetails?.packingImages.map((imageUrl, index) => (
                <img
                  key={index} // Adding a key prop for each image to help React identify them uniquely
                  style={{ marginTop: "20px", width: "100px", height: "100px" }}
                  src={imageUrl}
                  alt={`Image ${index}`} // Providing alt text for accessibility
                />
              ))}
            </div>
            {/* {deliveryCompleted ? ( */}
            {OutForDeliveryDetails?.paymentStatus === 0 ? (
              <>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "600",
                      fontFamily: "Roboto",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    {/* <img style={{ width: "20px", height: "20px" }}, src={Payment} /> */}
                    Payment Status
                  </Typography>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      color: "#1D89E1",
                      fontWeight: "500",
                      fontFamily: "Roboto",
                    }}
                    fontSize={contentFontSize_18}
                  >
                    <img
                      style={{
                        width: "18px",
                        height: "22px",
                        marginRight: "10px",
                        marginBottom: "5px",
                      }}
                      src={BlueClock}
                    />
                    Please collect Rs {OutForDeliveryDetails?.shoppingTotalPayable} from the customer 
                    <br />
                    <Typography
                      fontSize={contentFontSize_18}
                      sx={{
                        color: "#646464",
                        marginLeft: "29px",
                        fontWeight: "500",
                        fontFamily: "Roboto",
                      }}
                    >
                      Kindly request the customer to choose their preferred
                      payment method from the options provided below.
                    </Typography>
                  </Typography>
                </div>
                {/* )} */}

                <div style={{ display: "flex" }}>
                  <FormGroup
                    style={{
                      marginRight: "16px",
                      marginLeft: "29px",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={showCollected}
                          onChange={handleChange}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#13801C",
                            },
                          }}
                        />
                      }
                      label="Cash"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: "Roboto",
                          fontWeight: "600",
                          fontSize: { contentFontSize_16 },
                        },
                      }}
                      // disabled={showScanner}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={showScanner}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#13801C",
                            },
                          }}
                        />
                      }
                      label="UPI"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: "Roboto",
                          fontWeight: "600",
                        },
                      }}
                      // disabled={showCollected}
                    />
                  </FormGroup>
                </div>

                {showScanner ? (
                  <div className="containers">
                    <div className="content" id="paymentContent">
                    { props?.QrCodeData?.generateQRcodeForCOD.image_url || OutForDeliveryDetails.qrCodeUrl ? (
                      <img
                        className="scanner-image"
                        style={{width:"250px",height:"400px"}}
                        src={OutForDeliveryDetails.qrCodeUrl ? OutForDeliveryDetails.qrCodeUrl : scanner}
                        alt="Scanner"
                      />
                    ):(
                      <CircularProgress/>
                    )}
                      <Typography
                        className="text"
                        fontSize={contentFontsize_14}
                      >
                        Scan this code to make the payment.
                      </Typography>
                    </div>
                  </div>
                ) : null}
                {showCollected && (
                  <div
                    className="containerStyle"
                    style={{ background: checked ? "#37BD6B" : "#D9D9D9" }}
                  >
                    <div
                      style={{
                        marginLeft: matchessm ? "10px" : "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      <Typography
                        fontSize={contentFontSize_22}
                        sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                      >
                        {" "}
                        Cash Collected
                      </Typography>
                      <Typography>₹{OutForDeliveryDetails?.shoppingTotalPayable}</Typography>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: checked ? "white" : "#37BD6B",
                              },
                            }}
                            onChange={handleCheckbox}
                            size={
                              matchessm ? "small" : matches ? "medium" : "large"
                            }
                            //sx={{color:"white"}}
                          />
                        }
                      />
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{ color: deliveryCompleted ? "black" : "#659B1B" }}
                  >
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#659B1B",
                        },
                      }}
                      //checked={checked}
                      disabled={!checked} // Disables the checkbox when checked is false
                      onChange={handleboxChange}
                    />
                    Delivery Completed
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomButton
                    width="320px"
                    height="50px"
                    borderRadius="20px"
                    color={deliveryCompleted ? "black" : "white"}
                    background={deliveryCompleted ? "#8B8989" : "#659B1B"}
                    onClick={handleDelivered}
                  >
                    Delivered
                  </CustomButton>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{ color: deliveryCompleted ? "black" : "#659B1B" }}
                  >
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#659B1B",
                        },
                      }}
                      onChange={handleboxChange}
                    />
                    Delivery Completed
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CustomButton
                    width="320px"
                    height="50px"
                    borderRadius="20px"
                    color={deliveryCompleted ? "black" : "white"}
                    background={deliveryCompleted ? "#8B8989" : "#659B1B"}
                    onClick={handleDelivereds}
                  >
                    Delivered
                  </CustomButton>
                </div>
              </>
            )}
          </>
        )}
        {loading && <LoaderCompleted />}
      </div>
    </>
  );
}

export default OutForDelivery;
