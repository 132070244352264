// import React, { useEffect, useState } from "react";
// import * as ReactDOM from "react-dom/client";
// import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
// import { routesList } from "../routeList";
// import ReduxProvider from "../redux";
// import { ApolloProvider } from "@apollo/client";
// import client from "../apollo";
// import { messaging } from "../../firebase";
// import { notification } from "antd";
// import notificationsound from "../../utils/notification.wav";
// import ThemeConfig from "../../Components/theme";
// import { OrderNotification } from "../../Redux/Action/SignInActions/gqlQuery";
// import DialogTitle from "@mui/material/DialogTitle";
// import Dialog from "@mui/material/Dialog";
// import Error from "../../utils/images/Error.svg";
// import CriticalModal from "./CriticalModal";
// import io from "socket.io-client";
// import "simplebar/dist/simplebar.min.css";
// import "./index.css";
// import { useDispatch } from "react-redux";
// import new_Order from '../../utils/images/new_Order.svg'
// import { Typography,DialogActions,useMediaQuery,useTheme} from "../../Components/MUIComponents";
// import {HeaderNavigationFontSize_20} from '../../Components/Typography'
// const socket = io.connect("https://testserver.nearshopz.com", {
//   secure: true,
//   reconnect: true,
//   rejectUnauthorized: false, // Only use this for self-signed certificates during development
// });

// const Main = (props) => {
//   const [fireToken, setFireToken] = useState(null);
//   const [open, setOpen] = useState(true);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [openDialog,setOpenDialog]=useState(true);

//   const theme=useTheme();
//   const mdmatch = useMediaQuery(theme.breakpoints.down("md"));

//   const handleOrderClose=()=>{
//     setOpenDialog(false)
//   }
//   useEffect(()=>{
//     const newOrderCount = Number(localStorage.getItem("newOrderCount"));
//     const newOrdersList = props?.newOrders?.data?.newOrdersList;
//     const isLoggedin = localStorage.getItem("isLoggedin");
//     const storeid = localStorage.getItem("storeid");
//     if(newOrderCount > newOrdersList?.length){
//       setOpenDialog(true)
//       props.getNewOrdertlist(storeid)
//     }
//   })
//   const nav = useNavigate()
//   const handleConfirmnOrder = () => {
//     setOpenDialog(false)
//    nav("/home/MyOrders/NewOrderDetail");

//   };

//   const playSound = (url) => {
//     let audio = new Audio("https://4labstechnologies-my.sharepoint.com/:u:/g/personal/vthomas_4labsinc_com/Ee-51UDyyrxDu6ye5PawyyIBq1evGKq0uAlrbfZQriH_pg?e=goC2AZ");
//     audio.play();
//   };

//   const isIOSDevice = () => {
//     return (
//       !!window.navigator.platform &&
//       /iPad|iPhone|iPod/.test(window.navigator.platform)
//     );
//   };
// useEffect(()=>{
//   console.log("storeid",localStorage.getItem('storeid'))
// })
// const dispatch = useDispatch()
//   useEffect(() => {
//     // Listen for successful connection
//     socket.on("connect", () => {
//       console.log("Connected to the WebSocket server");

//     });

//     // Handle connection errors
//     socket.on("connect_error", (error) => {
//       console.error("Connection Error:", error);
//     });
//     const storeId = localStorage.getItem('storeid')
//     console.log("storeids",storeId)
//     socket.on(`${localStorage.getItem('storeid')}_newOrdersCount`, (data) => {
//       props.getAllCount(data);
//      console.log("socketCount",data)
//      localStorage.setItem("newOrderCount",data)
//     });
//     // socket.on(`${localStorage.getItem('storeid')}_activeOrdersCount`, (data) => {
//     //   props.getActiveOrdertlist(data);

//     //  localStorage.setItem("activeOrderCount",data)
//     // });
//     socket.on(`${localStorage.getItem('storeid')}_readyOrdersCount`, (data) => {
//       props.getAllCount(data);

//      localStorage.setItem("readyOrderCount",data)
//     });
//     if (isIOSDevice()) {
//       console.log("I am an IOS device!");
//     } else {
//       Notification.requestPermission()
//         .then(() => {
//           return messaging.getToken();
//         })
//         .then((token) => {
//           setFireToken(token);
//           localStorage.setItem("fcmToken", token);
//         })
//         .catch((err) => {
//           console.log("firebase-token-error", err);
//         });

//       navigator.serviceWorker.addEventListener("message", (message) => {
//         playSound(notificationsound);
//         client
//           .query({
//             query: OrderNotification,
//             variables: {
//               storeid: localStorage.getItem("storeid"),
//             },
//           })
//           .then((res) => {
//             if (res.data?.alertOrders) {
//               localStorage.setItem(
//                 "NotificationLength",
//                 res.data.alertOrders.length
//               );
//             }
//           });
//         notification.open({
//           message: `${message.data["firebase-messaging-msg-data"].notification.body}`,
//           description: `${message.data["firebase-messaging-msg-data"].notification.body}`,
//           placement: "topRight",
//           top: 100,
//           style: {
//             width: 600,
//           },
//         });
//       });
//     }

//     // return () => {
//     //   socket.off("connect");
//     //   socket.off("connect_error");
//     //   socket.off(`${localStorage.getItem("storeid")}_newOrdersCount`);
//     // };
//   }, []);

//   const handleClose = () => {
//     setOpen(!open);
//   };

//   const routeComponents = routesList.map(({ path, element, exact }, key) => (
//     <Route path={path} exact={exact} key={key} element={element} />
//   ));

//   return (
//     <ReduxProvider>
//       <ApolloProvider client={client}>
//         <Router>
//           <ThemeConfig>
//             <Routes>
//               <Route>{routeComponents}</Route>
//             </Routes>
//             {/* <Dialog
//               open={
//                 props?.ActiveOrder?.data?.criticalOrders?.length === 0
//                   ? false
//                   : true
//               }
//               fullWidth={true}
//               maxWidth={"md"}
//             >
//               <DialogTitle>
//                 <div
//                   className="Heading"
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <span>
//                     <img src={Error} alt="warning" />
//                   </span>
//                   <span> Critical Orders</span>
//                 </div>
//                 <div className="sub">
//                   <span className="SubHeading">{"You have "}</span>
//                   <span className="RedHeading">
//                     {props?.ActiveOrder?.data?.criticalOrders?.length}
//                   </span>
//                   <span className="SubHeading">
//                     {" pending order requests"}
//                   </span>
//                 </div>
//               </DialogTitle>
//               <div className="red" />
//               <div className="content">
//                 <CriticalModal {...props} />
//                 <div>
//                   <span className="footer1">*</span>
//                   <span className="footer2">
//                     Accept or Reject orders to continue
//                   </span>
//                 </div>
//               </div>
//             </Dialog> */}
//               <Dialog

//  open={openDialog}
//  onClose={handleOrderClose}
//  aria-labelledby="alert-dialog-title"
//  aria-describedby="alert-dialog-description"
//  PaperProps={{ sx: { borderRadius: "10px" } }}
// >

// <DialogTitle id="alert-dialog-title" style={{padding:"34px  120px 44px 120px"}}>
//   <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:'20px'}}>
//    <div>
//      <img src={new_Order} alt="no order image" height={mdmatch?"40px":"58px"} width={mdmatch?"40px":"58px"}/>
//    </div>
//    <div style={{display:"flex",gap:"10px"}}>
//       <Typography style={{fontFamily:"Roboto",fontWeight:"500",color:"#659B1B"}} fontSize={HeaderNavigationFontSize_20}>New order</Typography>
//      <Typography style={{fontFamily:"Roboto",fontWeight:"500"}} fontSize={HeaderNavigationFontSize_20}>#1101</Typography>
//    </div>
//   </div>
//  </DialogTitle>
//      <DialogActions style={{padding:"14px 0px 24px 0px"}}>
//        <div style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
//        <Typography onClick={handleOrderClose} style={{fontFamily:"Roboto",fontWeight:"900",cursor:"pointer"}}>Close</Typography>
//        <div>|</div>
//        <Typography onClick={handleConfirmnOrder} style={{fontFamily:"Roboto",fontWeight:"900",color:"#659B1B",cursor:"pointer"}}>
//          Open
//        </Typography>
//        </div>

//      </DialogActions>
// </Dialog>
//           </ThemeConfig>
//         </Router>
//       </ApolloProvider>
//     </ReduxProvider>
//   );
// };

// export default Main;

//new code

// import React, { useEffect, useState } from "react";
// import * as ReactDOM from "react-dom/client";
// import { HashRouter as Router, Route, Routes } from "react-router-dom";
// import { routesList } from "../routeList";
// import ReduxProvider from "../redux";
// import { ApolloProvider } from "@apollo/client";
// import client from "../apollo";
// import { messaging } from "../../firebase";
// import { notification } from "antd";
// import notificationsound from "../../utils/notification.wav";
// import ThemeConfig from "../../Components/theme";
// import { OrderNotification } from "../../Redux/Action/SignInActions/gqlQuery";
// import DialogTitle from "@mui/material/DialogTitle";
// import Dialog from "@mui/material/Dialog";
// import Error from "../../utils/images/Error.svg";
// import CriticalModal from "./CriticalModal";
// import io from "socket.io-client";
// import "simplebar/dist/simplebar.min.css";
// import "./index.css";
// import { useDispatch} from "react-redux";
// import new_Order from "../../utils/images/new_Order.svg";
// import {
//   Typography,
//   DialogActions,
//   useMediaQuery,
//   useTheme,
// } from "../../Components/MUIComponents";
// import { HeaderNavigationFontSize_20 } from "../../Components/Typography";
// import { useNavigate } from "react-router-dom";

// const socket = io.connect("https://testserver.nearshopz.com", {
//   secure: true,
//   reconnect: true,
//   rejectUnauthorized: false, // Only use this for self-signed certificates during development
// });

// const Main = (props) => {
//   const [fireToken, setFireToken] = useState(null);
//   const [open, setOpen] = useState(true);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false);

//   const theme = useTheme();
//  // const nav=useNavigate();
//   const mdmatch = useMediaQuery(theme.breakpoints.down("md"));

//   useEffect((storeid) => {

//     props.getNewOrdertlist(localStorage.getItem('storeid'));
//   },[]);

//   useEffect(()=>{
//     if( props?.newOrders?.data?.newOrdersList!==undefined){
//       if(props?.newOrders?.data?.newOrdersList.length <(localStorage.getItem('newOrderCount'))){
//         // let audio = new Audio("https://4labstechnologies-my.sharepoint.com/:u:/g/personal/vthomas_4labsinc_com/Ee-51UDyyrxDu6ye5PawyyIBq1evGKq0uAlrbfZQriH_pg?e=goC2AZ"
//         //   );
//         setOpenDialog(true)
//         // audio.play();
//       }
//     }

//   },[ props?.newOrders?.data?.newOrdersList,localStorage.getItem('newOrderCount')])

//   console.log("this is the new order status bar",localStorage.getItem("newOrderCount"))
//  console.log("this is the new order status bar for length",props?.newOrders?.data?.newOrdersList.length)

//   const handleOrderClose = () => {
//     setOpenDialog(false);
//   };

//   const handleConfirmOrder = () => {
//     setOpenDialog(false)
//  //   nav("/home/MyOrders/NewOrderDetail");

//   };

//   const playSound = (url) => {
//     let audio = new Audio(
//       "https://4labstechnologies-my.sharepoint.com/:u:/g/personal/vthomas_4labsinc_com/Ee-51UDyyrxDu6ye5PawyyIBq1evGKq0uAlrbfZQriH_pg?e=goC2AZ"
//     );
//     audio.play();
//   };

//   const isIOSDevice = () => {
//     return (
//       !!window.navigator.platform &&
//       /iPad|iPhone|iPod/.test(window.navigator.platform)
//     );
//   };

//   useEffect(() => {
//     console.log("storeid", localStorage.getItem("storeid"));
//   });

//   const dispatch = useDispatch();

//   useEffect(() => {
//     // Listen for successful connection
//     socket.on("connect", () => {
//       console.log("Connected to the WebSocket server");
//     });

//     // Handle connection errors
//     socket.on("connect_error", (error) => {
//       console.error("Connection Error:", error);
//     });

//     const storeId = localStorage.getItem("storeid");
//     console.log("storeids", storeId);

//     socket.on(`${localStorage.getItem("storeid")}_newOrdersCount`, (data) => {
//       props.getAllCount(data);

//       localStorage.setItem("newOrderCount", data);
//     });

//     socket.on(`${localStorage.getItem("storeid")}_readyOrdersCount`, (data) => {
//       props.getAllCount(data);

//       localStorage.setItem("readyOrderCount", data);
//     });

//     if (isIOSDevice()) {
//       console.log("I am an IOS device!");
//     } else {
//       Notification.requestPermission()
//         .then(() => {
//           return messaging.getToken();
//         })
//         .then((token) => {
//           setFireToken(token);
//           localStorage.setItem("fcmToken", token);
//         })
//         .catch((err) => {
//           console.log("firebase-token-error", err);
//         });

//       navigator.serviceWorker.addEventListener("message", (message) => {
//         playSound(notificationsound);
//         client
//           .query({
//             query: OrderNotification,
//             variables: {
//               storeid: localStorage.getItem("storeid"),
//             },
//           })
//           .then((res) => {
//             if (res.data?.alertOrders) {
//               localStorage.setItem(
//                 "NotificationLength",
//                 res.data.alertOrders.length
//               );
//             }
//           });
//         notification.open({
//           message: `${
//             message.data["firebase-messaging-msg-data"].notification.body
//           }`,
//           description: `${
//             message.data["firebase-messaging-msg-data"].notification.body
//           }`,
//           placement: "topRight",
//           top: 100,
//           style: {
//             width: 600,
//           },
//         });
//       });
//     }
//   }, []);

//   const handleClose = () => {
//     setOpen(!open);
//   };

//   const routeComponents = routesList.map(({ path, element, exact }, key) => (
//     <Route path={path} exact={exact} key={key} element={element} />
//   ));

//   return (
//     <ReduxProvider>
//       <ApolloProvider client={client}>
//         <Router>
//           <ThemeConfig>
//             <Routes>
//               <Route>{routeComponents}</Route>
//             </Routes>
//             {/* <Dialog
//               open={
//                 props?.ActiveOrder?.data?.criticalOrders?.length === 0
//                   ? false
//                   : true
//               }
//               fullWidth={true}
//               maxWidth={"md"}
//             >
//               <DialogTitle>
//                 <div
//                   className="Heading"
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <span>
//                     <img src={Error} alt="warning" />
//                   </span>
//                   <span> Critical Orders</span>
//                 </div>
//                 <div className="sub">
//                   <span className="SubHeading">{"You have "}</span>
//                   <span className="RedHeading">
//                     {props?.ActiveOrder?.data?.criticalOrders?.length}
//                   </span>
//                   <span className="SubHeading">
//                     {" pending order requests"}
//                   </span>
//                 </div>
//               </DialogTitle>
//               <div className="red" />
//               <div className="content">
//                 <CriticalModal {...props} />
//                 <div>
//                   <span className="footer1">*</span>
//                   <span className="footer2">
//                     Accept or Reject orders to continue
//                   </span>
//                 </div>
//               </div>
//             </Dialog> */}
//             <Dialog
//               open={openDialog}
//               onClose={handleOrderClose}
//               aria-labelledby="alert-dialog-title"
//               aria-describedby="alert-dialog-description"
//               PaperProps={{ sx: { borderRadius: "10px" } }}
//             >
//               <DialogTitle
//                 id="alert-dialog-title"
//                 style={{ padding: "34px  120px 44px 120px" }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     flexDirection: "column",
//                     gap: "20px",
//                   }}
//                 >
//                   <div>
//                     <img
//                       src={new_Order}
//                       alt="no order image"
//                       height={mdmatch ? "40px" : "58px"}
//                       width={mdmatch ? "40px" : "58px"}
//                     />
//                   </div>
//                   <div style={{ display: "flex", gap: "10px" }}>
//                     <Typography
//                       style={{
//                         fontFamily: "Roboto",
//                         fontWeight: "500",
//                         color: "#659B1B",
//                       }}
//                       fontSize={HeaderNavigationFontSize_20}
//                     >
//                       New order
//                     </Typography>
//                     <Typography
//                       style={{ fontFamily: "Roboto", fontWeight: "500" }}
//                       fontSize={HeaderNavigationFontSize_20}
//                     >
//                       #1101
//                     </Typography>
//                   </div>
//                 </div>
//               </DialogTitle>
//               <DialogActions style={{ padding: "14px 0px 24px 0px" }}>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-evenly",
//                     width: "100%",
//                   }}
//                 >
//                   <Typography
//                     onClick={handleOrderClose}
//                     style={{
//                       fontFamily: "Roboto",
//                       fontWeight: "900",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Close
//                   </Typography>
//                   <div>|</div>
//                   <Typography
//                     onClick={handleConfirmOrder}
//                     style={{
//                       fontFamily: "Roboto",
//                       fontWeight: "900",
//                       color: "#659B1B",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Open
//                   </Typography>
//                 </div>
//               </DialogActions>
//             </Dialog>
//           </ThemeConfig>
//         </Router>
//       </ApolloProvider>
//     </ReduxProvider>
//   );
// };

// export default Main;

//new code by celu

// import React, { useEffect, useState } from "react";
// import * as ReactDOM from "react-dom/client";
// import {
//   HashRouter as Router,
//   Route,
//   Routes,
//   useNavigate,
// } from "react-router-dom";
// import { routesList } from "../routeList";
// import ReduxProvider from "../redux";
// import { ApolloProvider } from "@apollo/client";
// import client from "../apollo";
// import { messaging } from "../../firebase";
// import { notification } from "antd";
// import notificationsound from "../../utils/notification.wav";
// import ThemeConfig from "../../Components/theme";
// import { OrderNotification } from "../../Redux/Action/SignInActions/gqlQuery";
// import DialogTitle from "@mui/material/DialogTitle";
// import Dialog from "@mui/material/Dialog";
// import Error from "../../utils/images/Error.svg";
// import CriticalModal from "./CriticalModal";
// import io from "socket.io-client";
// import "simplebar/dist/simplebar.min.css";
// import "./index.css";
// import { useDispatch } from "react-redux";
// import new_Order from "../../utils/images/new_Order.svg";
// import {
//   Typography,
//   DialogActions,
//   useMediaQuery,
//   useTheme,
// } from "../../Components/MUIComponents";
// import { HeaderNavigationFontSize_20 } from "../../Components/Typography";

// // WebSocket connection
// const socket = io.connect("https://testserver.nearshopz.com", {
//   secure: true,
//   reconnect: true,
//   rejectUnauthorized: false, // Only use this for self-signed certificates during development
// });

// const MainContent = (props) => {
//   const [fireToken, setFireToken] = useState(null);
//   const [open, setOpen] = useState(true);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [openDialog,setOpenDialog]=useState(true);

//   const theme=useTheme();
//   const mdmatch = useMediaQuery(theme.breakpoints.down("md"));

//   const handleOrderClose=()=>{
//     setOpenDialog(false)
//   }

//     const newOrderCount = Number(localStorage.getItem("newOrderCount"));
//     const newOrdersList = props?.newOrders?.data?.newOrdersList;
//     const isLoggedin = localStorage.getItem("isLoggedin");
//     const storeid = localStorage.getItem("storeid");
//     useEffect(() => {
//       if (newOrderCount > newOrdersList?.length
//            && isLoggedin) {
//         setOpenDialog(true);
//         props.getNewOrdertlist(storeid);
//       } else {
//         setOpenDialog(false);
//       }
//     }, []);

//   const nav = useNavigate()
//   const orderid = props &&
//   props.newOrders &&
//   props.newOrders.data &&
//   props.newOrders.data.newOrdersList[0]?.id
//   const handleConfirmOrder = () => {
//     setOpenDialog(false)
//    nav("/home/MyOrders/NewOrderDetail", {
//     state: { orderid: orderid }});

//   };

//   const playSound = (url) => {
//     let audio = new Audio("https://4labstechnologies-my.sharepoint.com/:u:/g/personal/vthomas_4labsinc_com/Ee-51UDyyrxDu6ye5PawyyIBq1evGKq0uAlrbfZQriH_pg?e=goC2AZ");
//     audio.play();
//   };

//   const isIOSDevice = () => {
//     return (
//       !!window.navigator.platform &&
//       /iPad|iPhone|iPod/.test(window.navigator.platform)
//     );
//   };
// useEffect(()=>{
//   console.log("storeid",localStorage.getItem('storeid'))
// })
// const dispatch = useDispatch()
//   useEffect(() => {
//     // Listen for successful connection
//     socket.on("connect", () => {
//       console.log("Connected to the WebSocket server");

//     });

//     // Handle connection errors
//     socket.on("connect_error", (error) => {
//       console.error("Connection Error:", error);
//     });
//     const storeId = localStorage.getItem('storeid')
//     console.log("storeids",storeId)
//     socket.on(`${localStorage.getItem('storeid')}_newOrdersCount`, (data) => {
//       props.getAllCount(data);
//      console.log("socketCount",data)
//      localStorage.setItem("newOrderCount",data)
//     });
//     // socket.on(`${localStorage.getItem('storeid')}_activeOrdersCount`, (data) => {
//     //   props.getActiveOrdertlist(data);

//     //  localStorage.setItem("activeOrderCount",data)
//     // });
//     socket.on(`${localStorage.getItem('storeid')}_readyOrdersCount`, (data) => {
//       props.getAllCount(data);

//      localStorage.setItem("readyOrderCount",data)
//     });
//     if (isIOSDevice()) {
//       console.log("I am an IOS device!");
//     } else {
//       Notification.requestPermission()
//         .then(() => {
//           return messaging.getToken();
//         })
//         .then((token) => {
//           setFireToken(token);
//           localStorage.setItem("fcmToken", token);
//         })
//         .catch((err) => {
//           console.log("firebase-token-error", err);
//         });

//       navigator.serviceWorker.addEventListener("message", (message) => {
//         playSound(notificationsound);
//         client
//           .query({
//             query: OrderNotification,
//             variables: {
//               storeid: localStorage.getItem("storeid"),
//             },
//           })
//           .then((res) => {
//             if (res.data?.alertOrders) {
//               localStorage.setItem(
//                 "NotificationLength",
//                 res.data.alertOrders.length
//               );
//             }
//           });
//         notification.open({
//           message: `${message.data["firebase-messaging-msg-data"].notification.body}`,
//           description: `${message.data["firebase-messaging-msg-data"].notification.body}`,
//           placement: "topRight",
//           top: 100,
//           style: {
//             width: 600,
//           },
//         });
//       });
//     }

//     // return () => {
//     //   socket.off("connect");
//     //   socket.off("connect_error");
//     //   socket.off(`${localStorage.getItem("storeid")}_newOrdersCount`);
//     // };
//   }, []);

//   const handleClose = () => {
//     setOpen(!open);
//   };

//   const routeComponents = routesList.map(({ path, element, exact }, key) => (
//     <Route path={path} exact={exact} key={key} element={element} />
//   ));

//   return (
//     <ThemeConfig>
//       <Routes>{routeComponents}</Routes>
//       <Dialog
//         open={openDialog}
//         onClose={handleOrderClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         PaperProps={{ sx: { borderRadius: "10px" } }}
//       >
//         <DialogTitle
//           id="alert-dialog-title"
//           style={{ padding: "34px  120px 44px 120px" }}
//         >
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               flexDirection: "column",
//               gap: "20px",
//             }}
//           >
//             <div>
//               <img
//                 src={new_Order}
//                 alt="no order image"
//                 height={mdmatch ? "40px" : "58px"}
//                 width={mdmatch ? "40px" : "58px"}
//               />
//             </div>
//             <div style={{ display: "flex", gap: "10px" }}>
//               <Typography
//                 style={{
//                   fontFamily: "Roboto",
//                   fontWeight: "500",
//                   color: "#659B1B",
//                 }}
//                 fontSize={HeaderNavigationFontSize_20}
//               >
//                 New order
//               </Typography>
//               <Typography
//                 style={{ fontFamily: "Roboto", fontWeight: "500" }}
//                 fontSize={HeaderNavigationFontSize_20}
//               >
//                 {props &&
//                   props?.newOrders &&
//                   props?.newOrders?.data &&
//                   props?.newOrders?.data?.newOrdersList[0]?.orderNumber}
//               </Typography>
//             </div>
//           </div>
//         </DialogTitle>
//         <DialogActions style={{ padding: "14px 0px 24px 0px" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-evenly",
//               width: "100%",
//             }}
//           >
//             <Typography
//               onClick={handleOrderClose}
//               style={{
//                 fontFamily: "Roboto",
//                 fontWeight: "900",
//                 cursor: "pointer",
//               }}
//             >
//               Close
//             </Typography>
//             <div>|</div>
//             <Typography
//               onClick={handleConfirmOrder}
//               style={{
//                 fontFamily: "Roboto",
//                 fontWeight: "900",
//                 color: "#659B1B",
//                 cursor: "pointer",
//               }}
//             >
//               Open
//             </Typography>
//           </div>
//         </DialogActions>
//       </Dialog>
//     </ThemeConfig>
//   );
// };

// const Main = (props) => {
//   return (
//     <ReduxProvider>
//       <ApolloProvider client={client}>
//         <Router>
//           <MainContent {...props} />
//         </Router>
//       </ApolloProvider>
//     </ReduxProvider>
//   );
// };

// export default Main;
import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom/client";
import {
  HashRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import  routesList  from "../routeList";
import ReduxProvider from "../redux";
import { ApolloProvider } from "@apollo/client";
import client from "../apollo";
import { messaging } from "../../firebase";
import { message, notification } from "antd";
import notificationsound from "../../utils/notification.wav";
import ThemeConfig from "../../Components/theme";
import { OrderNotification } from "../../Redux/Action/SignInActions/gqlQuery";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Error from "../../utils/images/Error.svg";
import CriticalModal from "./CriticalModal";
import io from "socket.io-client";
import "simplebar/dist/simplebar.min.css";
import "./index.css";
import { useDispatch } from "react-redux";
import new_Order from "../../utils/images/new_Order.svg";
import orderalarm from "../../utils/orderalarm.wav";
import {
  Typography,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "../../Components/MUIComponents";
import { HeaderNavigationFontSize_20 } from "../../Components/Typography";

const MainContent = (props) => {
  const [fireToken, setFireToken] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderidnew, setOrderidnew] = useState(null);
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("isLoggedin")) {
      props.getNewOrdertlist(localStorage.getItem("storeid"));
      props.getAllCount(localStorage.getItem("storeid"));
    }
  }, []);

  useEffect(() => {
    const socket = io.connect("https://testserver.nearshopz.com", {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
      multiplex: false,
    });

    const storeId = localStorage.getItem("storeid");

    const handleNewOrdersCount = (data) => {
      props.getAllCount(data);
      setNewOrdersCount(data);
      localStorage.setItem("newOrderCount", data);
    };

    socket.on("connect", () => {
      console.log("WebSocket connected");
    });

    socket.on("connect_error", (error) => {
      console.error("Connection Error:", error);
    });
    const orderId = localStorage.getItem("orderid"); // Ensure orderid is defined

    socket.on(`${localStorage.getItem("storeid")}_newOrdersCount`, (data) => {
      props.getAllCount(data);
      setNewOrdersCount(data);
      console.log("countsocket", data);
      // if(localStorage.getItem('isLoggedin') === true){
      localStorage.setItem("newOrderCount", data);
      // }
    });

    // On socket disconnection, verify the QR code
    // socket.on("disconnect", () => {
    //   console.log("Socket disconnected. Verifying QR code...");

    //   if (orderId) {
    //     // Call VerifyQrCode action with the order ID
    //     props.VerifyQrCode({ orderid: orderId })

    //         if (props?.VerifyQrCodeData?.verifyQrCodePayment) {
    //           message.success({
    //             content: "Payment Verified",
    //             className: "custom-class",
    //             style: { marginTop: "10vh", marginLeft: "15vw" },
    //             duration: 3.5,
    //           });
    //           props.getSearchDetails(orderId);
    //         }
    //       })
    //     }
    //            else {
    //     console.error("Order ID not found in localStorage.");
    //   }
    // });

    socket.on(`${storeId}_readyOrdersCount`, (data) => {
      props.getAllCount(data);
      localStorage.setItem("readyOrderCount", data);
    });

    if (!localStorage.getItem("isLoggedin")) {
      socket.off(
        `${localStorage.getItem("storeid")}_newOrdersCount`,
        (data) => {
          props.getAllCount(data);
          props.getNewOrdertlist(localStorage.getItem("storeid"));
          // if(localStorage.getItem('isLoggedin') === true){
          localStorage.setItem("newOrderCount", data);
          // }
        }
      );
    }
    if (isIOSDevice()) {
      // Handle iOS specific logic if needed
    } else {
      Notification.requestPermission()
        .then(() => messaging.getToken())
        .then((token) => {
          setFireToken(token);
          localStorage.setItem("fcmToken", token);
        })
        .catch((err) => console.error(err));

      navigator.serviceWorker.addEventListener("message", (message) => {
        let audio = new Audio(orderalarm);
        audio.play();
        client
          .query({
            query: OrderNotification,
            variables: { storeid: localStorage.getItem("storeid") },
          })
          .then((res) => {
            if (res.data?.alertOrders) {
              localStorage.setItem(
                "NotificationLength",
                res.data.alertOrders.length
              );
            }
          });

        notification.open({
          message:
            message.data["firebase-messaging-msg-data"].notification.body,
          description:
            message.data["firebase-messaging-msg-data"].notification.body,
          placement: "topRight",
          top: 100,
          style: { width: 600 },
        });
      });
    }

    // Clean up the socket connection when the component is unmounted
    //   return () => {
    //     socket.off("connect");
    //     socket.off("connect_error");
    //     socket.off(`${localStorage.getItem("storeid")}_newOrdersCount`, (data) => {
    //       props.getAllCount(data);
    //       props.getNewOrdertlist(localStorage.getItem("storeid"))
    //         // if(localStorage.getItem('isLoggedin') === true){
    //          localStorage.setItem("newOrderCount", data);
    //         // }
    //     });
    //     socket.off(`${orderid}_order`);
    //     socket.off(`${storeId}_readyOrdersCount`);
    //     socket.disconnect();
    //   };
    // }, [props?.newDashboardData]);
    return () => {
      socket.off("connect");
      socket.off("connect_error");
      socket.off(`${orderId}_order`);
      socket.disconnect();
    };
  }, [props?.newDashboardData]);

  const [dashboardOrder, setdashboardOrder] = useState(
    props?.NewDashboardDetails &&
      props?.NewDashboardDetails?.getDashboardNew &&
      props?.NewDashboardDetails?.getDashboardNew?.newOrders
  );
  useEffect(() => {
    console.log("i am inside the useffect");
    console.log(
      "conditon",
      Number(
        props?.NewDashboardDetails &&
          props?.NewDashboardDetails?.getDashboardNew &&
          props?.NewDashboardDetails?.getDashboardNew?.newOrders
      ) < Number(localStorage.getItem("newOrderCount"))
    );
    console.log(
      "dashboard",
      props?.NewDashboardDetails &&
        props?.NewDashboardDetails?.getDashboardNew &&
        props?.NewDashboardDetails?.getDashboardNew?.newOrders
    );
    console.log("neworder", Number(localStorage.getItem("newOrderCount")));
    if (
      Number(
        props?.NewDashboardDetails &&
          props?.NewDashboardDetails?.getDashboardNew &&
          props?.NewDashboardDetails?.getDashboardNew?.newOrders
      ) < Number(localStorage.getItem("newOrderCount"))
    ) {
      if (localStorage.getItem("isLoggedin")) {
        let audio = new Audio(orderalarm);
        audio.play();
        props.getNewOrdertlist(localStorage.getItem("storeid"));
        setOpenDialog(true);
        console.log("openDialog", openDialog);
      }
      setdashboardOrder(localStorage.getItem("newOrderCount"));
    }
  }, [localStorage.getItem("newOrderCount")]);
  const orderid =
    props &&
    props?.newOrders &&
    props?.newOrders?.data &&
    props?.newOrders?.data?.newOrdersList[0]?.id;
  console.log("orderID", orderid);
  const handleConfirmOrder = () => {
    setOpenDialog(false);
    props.getSearchDetails(orderid);
    navigate("/home/MyOrders/NewOrderDetail", {
      state: {
        orderId:
          props &&
          props?.newOrders &&
          props?.newOrders?.data &&
          props?.newOrders?.data?.newOrdersList[0]?.id,
      },
    });
  };

  const isIOSDevice = () => {
    return (
      !!window.navigator.platform &&
      /iPad|iPhone|iPod/.test(window.navigator.platform)
    );
  };

  const token = localStorage.getItem("token");
  const retailerID = localStorage.getItem("retailerid");

  const routeComponents = routesList({ fireToken, token, retailerID }, props).map(
    ({ path, element, exact }, key) => (
      <Route path={path} element={element} exact={exact} key={key} />
    )
  );
  console.log("newOrderCount", localStorage.getItem("newOrderCount"));
  console.log(
    "dashboardOrder",
    props.NewDashboardDetails &&
      props.NewDashboardDetails.getDashboardNew &&
      props.NewDashboardDetails.getDashboardNew.newOrders
  );
  return (
    <ThemeConfig>
      <Routes>{routeComponents}</Routes>
      {openDialog && (
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ padding: "34px 120px 44px 120px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div>
                <img
                  src={new_Order}
                  alt="no order image"
                  height={mdmatch ? "40px" : "58px"}
                  width={mdmatch ? "40px" : "58px"}
                />
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    color: "#659B1B",
                  }}
                  fontSize={HeaderNavigationFontSize_20}
                >
                  New order
                </Typography>
                <Typography
                  style={{ fontFamily: "Roboto", fontWeight: "500" }}
                  fontSize={HeaderNavigationFontSize_20}
                >
                  #
                  {props &&
                    props?.newOrders &&
                    props?.newOrders?.data &&
                    props?.newOrders?.data?.newOrdersList[0]?.orderNumber}
                </Typography>
              </div>
            </div>
          </DialogTitle>
          <DialogActions style={{ padding: "14px 0px 24px 0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "900",
                  cursor: "pointer",
                }}
                onClick={() => setOpenDialog(false)}
              >
                Close
              </Typography>
              <div>|</div>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "900",
                  color: "#659B1B",
                  cursor: "pointer",
                }}
                onClick={handleConfirmOrder}
              >
                Open
              </Typography>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </ThemeConfig>
  );
};

const Main = (props) => {
  return (
    <ReduxProvider>
      <ApolloProvider client={client}>
        <Router>
          <MainContent {...props} />
        </Router>
      </ApolloProvider>
    </ReduxProvider>
  );
};

export default Main;

// import React, { useEffect, useLayoutEffect, useState } from "react";
// import * as ReactDOM from "react-dom/client";
// import {
//   HashRouter as Router,
//   Route,
//   Routes,
//   useNavigate,
// } from "react-router-dom";
// import { routesList } from "../routeList";
// import ReduxProvider from "../redux";
// import { ApolloProvider } from "@apollo/client";
// import client from "../apollo";
// import { messaging } from "../../firebase";
// import { notification } from "antd";
// import notificationsound from "../../utils/notification.wav";
// import ThemeConfig from "../../Components/theme";
// import { OrderNotification } from "../../Redux/Action/SignInActions/gqlQuery";
// import DialogTitle from "@mui/material/DialogTitle";
// import Dialog from "@mui/material/Dialog";
// import Error from "../../utils/images/Error.svg";
// import CriticalModal from "./CriticalModal";
// import io from "socket.io-client";
// import "simplebar/dist/simplebar.min.css";
// import "./index.css";
// import { useDispatch } from "react-redux";
// import new_Order from "../../utils/images/new_Order.svg";
// import orderalarm from '../../utils/orderalarm.wav'
// import {
//   Typography,
//   DialogActions,
//   useMediaQuery,
//   useTheme,
// } from "../../Components/MUIComponents";
// import { HeaderNavigationFontSize_20 } from "../../Components/Typography";

// // WebSocket connection

// const MainContent = (props) => {
//   const [fireToken, setFireToken] = useState(null);
//   const [open, setOpen] = useState(true);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [orderidnew, setOrderidnew] = useState(null);
//   const [newOrdersCount,setNewOrdersCount] = useState()
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const mdmatch = useMediaQuery(theme.breakpoints.down("md"));

//   // const socket = io.connect("https://testserver.nearshopz.com", {
//   //   secure: true,
//   //   reconnect: true,
//   //   rejectUnauthorized: false, // Only use this for self-signed certificates during development
//   // });

//   useEffect((storeid) => {
//     if(localStorage.getItem('isLoggedin'))
// {
//    props.getNewOrdertlist(localStorage.getItem("storeid"));
//    props.getAllCount(localStorage.getItem("storeid"))
// }
//   }, []);

//   // useEffect(() => {
//   //   if (props?.newOrders?.data?.newOrdersList !== undefined) {
//   //     if (props?.newOrders?.data?.newOrdersList.length < Number(localStorage.getItem("newOrderCount"))) {
//   //       setOpenDialog(true);
//   //       props.getNewOrdertlist(localStorage.getItem('storeid'));
//   //       setOrderidnew(props?.newOrders?.data?.newOrdersList[0]?.id)
//   //     }
//   //   }
//   // }, [props?.newOrders?.data?.newOrdersList, localStorage.getItem("newOrderCount")]);

//   const handleOrderClose = () => {
//     setOpenDialog(false);
//   };
//   const orderid =
//     props &&
//     props?.newOrders &&
//     props?.newOrders?.data &&
//     props?.newOrders?.data?.newOrdersList[0]?.id;
//   const handleConfirmOrder = () => {
//     setOpenDialog(false);
//     props.getSearchDetails(orderid);
//     navigate("/home/MyOrders/NewOrderDetail", { state: { orderId: orderid } });
//   };

//   const playSound = () => {
//     let audio = new Audio(orderalarm);
//     audio.play();
//   };
// //  useEffect(()=>{
// //     if(localStorage.getItem('isLoggedin')){
// //       window.location.reload()
// //     }
// //  },[])
//   const isIOSDevice = () => {
//     return (
//       !!window.navigator.platform &&
//       /iPad|iPhone|iPod/.test(window.navigator.platform)
//     );
//   };

//   // useEffect(() => {
//   //   console.log("storeid", localStorage.getItem("storeid"));
//   // },[]);

//   const dispatch = useDispatch();
// // useEffect(()=>{
// //   socket.on(`${localStorage.getItem("storeid")}_newOrdersCount`, (data) => {
// //     props.getAllCount(data);
// //       // if(localStorage.getItem('isLoggedin') === true){
// //        localStorage.setItem("newOrderCount", data);
// //       // }
// //   });
// // },[])
//   useEffect(() => {

//     const socket = io.connect("https://testserver.nearshopz.com", {
//       secure: true,
//       reconnect: true,
//       rejectUnauthorized: false, // Only use this for self-signed certificates during development
//     });
//     // Listen for successful connection
//     socket.on("connect", () => {
//        console.log('web socket connected')
//     });

//     // Handle connection errors
//     socket.on("connect_error", (error) => {
//       console.error("Connection Error:", error);
//     });

//     const storeId = localStorage.getItem("storeid");

//     socket.on(`${localStorage.getItem("storeid")}_newOrdersCount`, (data) => {
//       props.getAllCount(data);
//       setNewOrdersCount(data)
//         // if(localStorage.getItem('isLoggedin') === true){
//          localStorage.setItem("newOrderCount", data);
//         // }
//     });
//    if(!localStorage.getItem('isLoggedin')){
//     socket.off(`${localStorage.getItem("storeid")}_newOrdersCount`, (data) => {
//       props.getAllCount(data);
//       props.getNewOrdertlist(localStorage.getItem("storeid"))
//         // if(localStorage.getItem('isLoggedin') === true){
//          localStorage.setItem("newOrderCount", data);
//         // }
//     });
//   }
//     socket.on(`${localStorage.getItem("storeid")}_readyOrdersCount`, (data) => {
//       props.getAllCount(data);
//       // if(localStorage.getItem('isLoggedin')){
//        localStorage.setItem("readyOrderCount", data);
//       // }
//     });

//     if (isIOSDevice()) {
//     } else {
//       Notification.requestPermission()
//         .then(() => {
//           return messaging.getToken();
//         })
//         .then((token) => {
//           setFireToken(token);
//           localStorage.setItem("fcmToken", token);
//         })
//         .catch((err) => {
//         });

//       navigator.serviceWorker.addEventListener("message", (message) => {
//         playSound(notificationsound);
//         client
//           .query({
//             query: OrderNotification,
//             variables: {
//               storeid: localStorage.getItem("storeid"),
//             },
//           })
//           .then((res) => {
//             if (res.data?.alertOrders) {
//               localStorage.setItem(
//                 "NotificationLength",
//                 res.data.alertOrders.length
//               );
//             }
//           });
//         notification.open({
//           message: `${message.data["firebase-messaging-msg-data"].notification.body}`,
//           description: `${message.data["firebase-messaging-msg-data"].notification.body}`,
//           placement: "topRight",
//           top: 100,
//           style: {
//             width: 600,
//           },
//         });
//       });
//     }
//   }, []);

//   useEffect(() => {
//     const newOrderCount = Number(localStorage.getItem("newOrderCount"));
//     const newOrdersList = props.NewDashboardDetails &&
//     props.NewDashboardDetails.getDashboardNew &&
//     props.NewDashboardDetails.getDashboardNew.newOrders
//     const isLoggedin = localStorage.getItem("isLoggedin");
//     const storeId = localStorage.getItem("storeid");

//     // if (newOrdersList !== undefined) {
//       if (localStorage.getItem('newOrderCount') > newOrdersList ) {
//           if(isLoggedin){
//             let audio = new Audio(orderalarm);
//             audio.play();
//           setOpenDialog(true);
//           props.getNewOrdertlist(storeId)
//         }
//           if(openDialog){
//             props.getNewOrdertlist(storeId)
//           }

//       }
//     // }
//   }, [localStorage.getItem('newOrderCount')]);
//   const handleClose = () => {
//     setOpen(!open);
//   };

//   const routeComponents = routesList.map(({ path, element, exact }, key) => (
//     <Route path={path} exact={exact} key={key} element={element} />
//   ));

//   return (
//     <ThemeConfig>
//       <Routes>{routeComponents}</Routes>
//       <Dialog
//         open={openDialog}
//        onClose={handleOrderClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         PaperProps={{ sx: { borderRadius: "10px" } }}
//       >
//         <DialogTitle
//           id="alert-dialog-title"
//           style={{ padding: "34px  120px 44px 120px" }}
//         >
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               flexDirection: "column",
//               gap: "20px",
//             }}
//           >
//             <div>
//               <img
//                 src={new_Order}
//                 alt="no order image"
//                 height={mdmatch ? "40px" : "58px"}
//                 width={mdmatch ? "40px" : "58px"}
//               />
//             </div>
//             <div style={{ display: "flex", gap: "10px" }}>
//               <Typography
//                 style={{
//                   fontFamily: "Roboto",
//                   fontWeight: "500",
//                   color: "#659B1B",
//                 }}
//                 fontSize={HeaderNavigationFontSize_20}
//               >
//                 New order
//               </Typography>
//               <Typography
//                 style={{ fontFamily: "Roboto", fontWeight: "500" }}
//                 fontSize={HeaderNavigationFontSize_20}
//               >
//                 #{props &&
//                   props?.newOrders &&
//                   props?.newOrders?.data &&
//                   props?.newOrders?.data?.newOrdersList[0]?.orderNumber}
//               </Typography>
//             </div>
//           </div>
//         </DialogTitle>
//         <DialogActions style={{ padding: "14px 0px 24px 0px" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-evenly",
//               width: "100%",
//             }}
//           >
//             <Typography
//              onClick={handleOrderClose}
//               style={{
//                 fontFamily: "Roboto",
//                 fontWeight: "900",
//                 cursor: "pointer",
//               }}
//             >
//               Close
//             </Typography>
//             <div>|</div>
//             <Typography
//              onClick={handleConfirmOrder}
//               style={{
//                 fontFamily: "Roboto",
//                 fontWeight: "900",
//                 color: "#659B1B",
//                 cursor: "pointer",
//               }}
//             >
//               Open
//             </Typography>
//           </div>
//         </DialogActions>
//       </Dialog>
//     </ThemeConfig>
//   );
// };

// const Main = (props) => {
//   return (
//     <ReduxProvider>
//       <ApolloProvider client={client}>
//         <Router>
//           <MainContent {...props} />
//         </Router>
//       </ApolloProvider>
//     </ReduxProvider>
//   );
// };

// export default Main;
