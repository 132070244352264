import React, { useEffect, useState } from "react";
import CustomDialog from "../Commoncomponents/dialog";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Axios from "axios";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import {
  Step,
  Stack,
  Stepper,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import CustomButton from "../Commoncomponents/button";
import {
  ButtonFontSize_16,
  ButtonFontSize_17,
  ButtonFontSize_18,
  contentFontsize_14_xs_10,
  contentFontSize_16,
  contentFontSize_18,
} from "../Typography";
import "./view.css";
import { deposit_cod } from "../../Redux/Action/OrderDetails";
import { DEPOSIT_COD } from "../../Redux/Action/OrderDetails/gqlQuery";
import client from "../../Configurations/apollo";
import { Verify_Payment } from "./query";
import { Fade } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
//import { Timeline } from "antd";

const CustomStep = styled(Step)(({ theme }) => ({
  paddingLeft: "25px",
  "& .MuiStepLabel-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiStepIcon-root": {
    color: theme.palette.secondary.main,
  },
}));

function PendingView(props) {
  const [selectedOrder, setSelectedOrder] = useState([]);

  useEffect(() => {
    props.getOrderDetails(props?.selectedOrder?.id);
    console.log("order details vannu", props?.orderDetails?.data?.orderdetails);
  }, []);

  useEffect(() => {
    const orderDetails = props?.orderDetails?.data?.orderdetails;
    setSelectedOrder(orderDetails);
  }, [props?.orderDetails?.data?.orderdetails]);

  console.log("order illa", selectedOrder);

  const handleClose = () => {
    props?.setView(false);
  };
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const ismobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDepositClick = (order) => {
    console.log("clicked", order);
    // props.depositCod(order);
    client
      .mutate({
        mutation: DEPOSIT_COD,
        variables: {
          orderId: order,
        },
      })
      .then((res) => {
        //
        if (res.data) {
          const options = {
            key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
            amount: 100,
            currency: "INR",
            name: "NearShopz",
            description: "Grocery delivery from shops near you!",
            order_id: res?.data?.depositCODToNearshopz?.id,
            handler: async (response) => {
              try {
                const paymentId = response.razorpay_payment_id;
                console.log("response", response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                client
                  .mutate({
                    mutation: Verify_Payment,
                    variables: {
                      orderid: order,
                      razorpayOrderid: `${response?.razorpay_order_id}`,
                      paymentid: `${response?.razorpay_payment_id}`,
                      signature: `${response?.razorpay_signature}`,
                    },
                  })
                  .then((res) => {
                    // setPaid(true);
                    client.reFetchObservableQueries();
                    props.setView(false);
                    props.getAllPendingPayments();
                  })
                  .catch((err) => {
                    alert(err);
                  });
                // console.log("id", paymentId);
                const API_URL = "https://www.nearshopz.com/";
                const url = `${API_URL}capture/${paymentId}`;
                const captureResponse = await Axios.post(url, {});
              } catch (err) {
                console.log(err);
              }
            },
            theme: {
              color: "#89C74A",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else if (res.errors) {
          alert(res.errors[0].message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const detailsStyle = {
    backgroundColor: "#f9d6d2",
    padding: ismobileScreen ? "0px" : "16px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "800px",
    //flexDirection: ismobileScreen ? "row":"column",
    paddingLeft: ismobileScreen ? "0px" : "50px",
    paddingRight: ismobileScreen ? "0px" : "50px",
  };

  const itemStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  };

  const totalStyle = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
  };

  const stats = props?.selectedOrder?.stats || [];
  const transferSettlement = props?.selectedOrder?.transferSettlement || [];

  console.log("prop ind", props);
  console.log("prop illa", props?.selectedOrder);

  const steps = [
    ...[stats[0], stats[stats.length - 1]].map((stat, index) => ({
      label:
        index === 0
          ? `${stat.status.replace(/-/g, " ")} with COD`
          : `Order ${stat.status.replace(/-/g, " ")}`,
      dateTime: `${stat.created} ${new Date(
        `1970-01-01T${stat.createdTime}Z`
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`,
    })),
    ...transferSettlement.map((settle) => ({
      label: settle.status.replace(/-/g, " "),
      dateTime: `${settle.created} ${new Date(
        `1970-01-01T${settle.createdTime}Z`
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`,
    })),
  ];
  console.log("step ind", steps);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      width: 260,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#17A64F",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#EA4335",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: "#EA4335",
      borderRadius: 1,
    },
  }));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: ownerState.isLastStep
      ? "#EA4335"
      : theme.palette.grey[700],
    zIndex: 1,
    color: "#fff",
    width: 20,
    height: 20,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: ownerState.isLastStep ? "#EA4335" : "#17A64F",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#17A64F",
    }),
    padding: 0,
  }));

  const timelineStyle = {
    padding: matchessm
      ? "50px 0px 0px 18px"
      : xlmatch
      ? "50px 0px 0px 80px"
      : upXlmatch
      ? "50px 0px 0px 80px"
      : "25px 2px 3px 4px",
  };
  function ColorlibStepIcon(props) {
    const { active, completed, className, isLastStep } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ active, completed, isLastStep }}
        className={className}
      >
        {completed ? (
          <div className="ColorlibStepIcon-completedIcon" />
        ) : (
          <div className="ColorlibStepIcon-circle" />
        )}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <>
      <style>
        {`
          .custom-list li::marker {
            color: #EA4335; /* Change this to your desired color */
           
          }
        `}
      </style>
      <Dialog
        maxHeight="70%"
        borderRadius="20px"
        open={props?.view}
        titleAlign="center"
        padding="30px"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "790px",
              padding: "10px 0px 30px 0px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: "center", color: "black", marginTop: "20px" }}
        >
          #{selectedOrder?.orderNumber}
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div style={{ paddingTop: "0px" }}>
            {isSmallScreen ? (
              <>
                <Box
                  sx={{
                    overflowX: "auto",
                    marginBottom: "20px",
                    padding: "20px 0px 0px 40px",
                  }}
                >
                  {steps.map((step, index) => (
                    <Timeline key={index} sx={{ timelineStyle }}>
                      <TimelineItem classes={{ root: "customTimelineItem" }}>
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              backgroundColor:
                                index === steps.length - 1
                                  ? "#EA4335"
                                  : "#85BE49", // Red for the last dot, green for others
                              margin: "0px",
                            }}
                          />
                          {index !== steps.length - 1 && (
                            <TimelineConnector
                              sx={{
                                backgroundColor:
                                  index === steps.length - 2
                                    ? "#EA4335"
                                    : "#85BE49", // Green for the last connector
                                marginBottom: "-26px",
                              }}
                            />
                          )}
                        </TimelineSeparator>

                        <TimelineContent>
                          <div
                            style={{
                              display: "flex",
                              gap: "0px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              fontSize={contentFontSize_18}
                              sx={{
                                fontWeight: "800",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              {step.label}
                              <br />
                              <Typography
                                sx={{
                                  fontFamily: "Roboto",
                                  fontWeight: "500",
                                  color: "#8B8989",
                                  marginLeft: "0px",
                                }}
                                fontSize={contentFontSize_16}
                              >
                                {step.dateTime}
                              </Typography>
                            </Typography>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  ))}
                </Box>
              </>
            ) : (
              <Box sx={{ overflowX: "auto", marginBottom: "20px" }}>
                <Stack sx={{ width: "100%" }} spacing={4}>
                  <Stepper
                    alternativeLabel
                    activeStep={1}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((step, index) => (
                      <CustomStep key={step.label}>
                        <StepLabel
                          StepIconComponent={(stepProps) => (
                            <ColorlibStepIcon
                              {...stepProps}
                              isLastStep={index === steps.length - 1}
                            />
                          )}
                        >
                          <Typography
                            style={{ fontFamily: "Roboto", fontWeight: "500" }}
                            fontSize={contentFontsize_14_xs_10}
                          >
                            {step.label}
                          </Typography>
                          <Typography
                            style={{ fontFamily: "Roboto", fontWeight: "400" }}
                            fontSize={contentFontsize_14_xs_10}
                          >
                            {step.dateTime}
                          </Typography>
                        </StepLabel>
                      </CustomStep>
                    ))}
                  </Stepper>
                </Stack>
              </Box>
            )}
          </div>
          <Box sx={detailsStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexDirection: ismobileScreen ? "column" : "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: ismobileScreen ? "10px" : "100px",
                  // justifyContent: "space-between",
                  flexGrow: 1,
                }}
              >
                <ul
                  className="custom-list"
                  style={{ listStyleType: "disc", paddingLeft: "20px" }}
                >
                  <li>Product Price</li>
                  <li>Packing Charge</li>
                  <li>Delivery Charge</li>
                  <li>Total</li>
                  <li style={{ listStyleType: "none", marginTop: "28px" }}>
                    <strong>Total Amount Collected From Customer</strong>
                  </li>
                </ul>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      ₹{" "}
                      {(
                        selectedOrder?.shoppingTotalPriceWithoutMarkup ?? 0
                      ).toFixed(2)}
                    </li>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      ₹{" "}
                      {(
                        selectedOrder?.shoppingPackingChargeWithoutMarkup ?? 0
                      ).toFixed(2)}
                    </li>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      ₹{" "}
                      {(
                        selectedOrder?.deliveryChargeWithoutMarkup ?? 0
                      ).toFixed(2)}
                    </li>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      ₹
                      {(
                        (selectedOrder?.shoppingTotalPriceWithoutMarkup ?? 0) +
                        (selectedOrder?.shoppingPackingChargeWithoutMarkup ??
                          0) +
                        (selectedOrder?.deliveryChargeWithoutMarkup ?? 0)
                      ).toFixed(2)}
                    </li>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: ismobileScreen ? "0px" : "20px",
                      gap: ismobileScreen ? "0px" : "10px",
                    }}
                  >
                    <li style={{ marginTop: ismobileScreen ? "16px" : "1px" }}>
                      {" : "}
                    </li>
                    <li style={{ marginTop: ismobileScreen ? "16px" : "1px" }}>
                      <strong style={{ marginTop: "10px" }}>
                        ₹{(selectedOrder?.shoppingTotalPayable ?? 0).toFixed(2)}
                      </strong>
                    </li>
                  </div>
                </ul>
              </div>
              <div>
                <CustomButton
                  variant="contained"
                  sx={{ marginLeft: "16px", padding: "10px" }}
                  borderRadius={"40px"}
                  background={"#EA4335"}
                  width={ismobileScreen ? "80px" : "160px"}
                  height="45px"
                  onClick={() => handleDepositClick(selectedOrder?.id)}
                >
                  <Typography
                    fontSize={ButtonFontSize_16}
                    className="button-font-deposite"
                  >
                    Deposit
                    {(selectedOrder?.shoppingTotalPriceWithoutMarkup ?? 0) +
                      (selectedOrder?.shoppingPackingChargeWithoutMarkup ?? 0) +
                      (selectedOrder?.deliveryChargeWithoutMarkup ?? 0) >
                    (selectedOrder?.shoppingTotalPayable ?? 0)
                      ? ` - ${(
                          (selectedOrder?.shoppingTotalPriceWithoutMarkup ??
                            0) +
                          (selectedOrder?.shoppingPackingChargeWithoutMarkup ??
                            0) +
                          (selectedOrder?.deliveryChargeWithoutMarkup ?? 0)
                        ).toFixed(2)}`
                      : ` ${(
                          (selectedOrder?.shoppingTotalPayable ?? 0) -
                          ((selectedOrder?.shoppingTotalPriceWithoutMarkup ??
                            0) +
                            (selectedOrder?.shoppingPackingChargeWithoutMarkup ??
                              0) +
                            (selectedOrder?.deliveryChargeWithoutMarkup ?? 0))
                        ).toFixed(2)}`}
                  </Typography>
                </CustomButton>
              </div>
            </div>
          </Box>

          <div style={{ padding: "20px", textAlign: "center" }}>
            <div style={{ padding: "20px" }}>
              <Typography>
                As per NearShopz policy, the product price, packing charges, and
                delivery charges are allocated to the seller. The remaining
                amount is considered NearShopz's profit, which includes platform
                fees, taxes, and other applicable charges.
                <br />
                Kindly deposit the amount to complete the transaction.
              </Typography>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              onClick={handleClose}
              width="160px"
              height="45px"
              variant={"outlined"}
              background={"transparent"}
              color={"#659B1B"}
              borderRadius="25px"
              borderColor="#659B1B"
            >
              Close
            </CustomButton>
          </div>
        </DialogContent>
      </Dialog>
      {/* ))} */}
    </>
  );
}

export default PendingView;
