// import React from 'react';
// import './index.css';
// // import NoSearchResult from '../NoSearchResult';
// // import { Badge } from 'antd';
// import { NoImage } from '../../utils/image';
// import { NO_RESULT } from "../../utils/image";
// import ProductToggle from './ProductToggle';
// // import EditPopper from './EditPopper';
// import EditIcon from '@mui/icons-material/Edit';

// const ProductCard = (props) => {

//   // const open = Boolean(anchorEl);

//   const EditClick = (id, uom, status, depid, img) => {
//     props.history.push({
//       pathname: "/home/EditProducts/details",
//       state: {
//         Productid: id,
//         images: props.images,
//         status: status,
//         uom: uom,
//         salesdepartment: depid,
//         img: img
//       },
//     });
//   }

//   return (
//     <>
//       {props.products &&
//       props.products.newProducts &&
//         props.products.newProducts.params &&
//         props.products.newProducts.params[0] &&
//         props.products.newProducts.params[0].length !== 0 ? (
//         props.products.newProducts.params.map((p, index) =>
//           p.map((source) => (
//             <div style={{ paddingTop: '10px', paddingLeft: '15px' }} key={source.id}>
//               <div
//                 className="Card"
//                 role="button"
//               >
//                 <div className='EditDiv'>
//                   <span style={{ float: 'left' }}><ProductToggle id={source.id} status={source.status === 'inactive' ? false : true} {...props} /> </span>
//                   <span style={{ float: 'right' }}>
//                     <EditIcon onClick={() => EditClick(source.id, source.uom,
//                       source.status,
//                       source.salesdepartment && source.salesdepartment.id,
//                       source.image.primary
//                     )} />
//                     {/* <EditPopper onClick={()=>EditClick(source.id,source.uom,
//                      source.status,
//                      source.salesdepartment&&source.salesdepartment.id,
//                      )} {...props}/> */}
//                   </span>

//                 </div>
//                 <div className="text-center">
//                   {/* {source.image.map(({primary}) => */}

//                   <div className="product-padding">

//                     <img src={((source?.image && source?.image.primary === null) ||
//                       (source?.image && source?.image?.primary === "null")) ? NoImage : source?.image?.primary} className="product-image" alt="product" />

//                     {/* <EditOutlinedIcon/> */}
//                   </div>
//                   {/* )
// } */}
//                   <div className="product-padding">
//                     <h1>₹ {source.price}</h1>
//                   </div>
//                   <div className="product-name">
//                     <p>
//                       {source.productname}
//                     </p>
//                   </div>
//                 </div>

//               </div>
//             </div>
//           )

//           ))
//       ) :
//         <div style={{
//           width: '75vw',
//           paddingTop: '20px',
//           display: 'flex',
//           justifyContent: 'center'
//         }}>
//           <img style={{ maxWidth: "810px" }} src={NO_RESULT} alt="noresult"></img>
//         </div>

//       }

//     </>
//   )

// }
// export default ProductCard;
import { React, useState, useEffect } from "react";
import { bindTrigger } from "../MUIComponents/index";
import { usePopupState } from "../MUIComponents/index";
import deleteIconSA from "../../utils/Icon/deleteIconSA.svg";
import editIconSA from "../../utils/Icon/editIconSA.svg";
import CustomPopover from "../Commoncomponents/popup/index";
import CustomCard from "../Commoncomponents/card";
import CustomToggleButton from "../Commoncomponents/toggleButton";
import { ReactComponent as Three } from "../../utils/Icon/threedot.svg";
import { NoImage } from "../../utils/image";
import {
  HeaderFontSize_24,
  HeaderNavigationFontSize_12,
  contentFontSize_16,
  contentFontSize_18_xs_13,
  contentFontsize_14,
} from "../Typography/index";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  DialogContent,
  Box,
  TextField,
} from "../MUIComponents/index";
import "./add.css";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Commoncomponents/button";
import { ButtonFontSize_18 } from "../Typography/index";
import CustomDialog from "../Commoncomponents/dialog";
import ProductToggle from "../UpdateProduct/ProductToggle";
import { message } from "antd";

function ProductCard(props) {
  const [department, setDepartment] = useState(null);
  const [category, setCategory] = useState(null);
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [status, setStatus] = useState(null);
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const [key, setKey] = useState("");
  const [from, setFrom] = useState(0);
  const [first, setFirst] = useState(50);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);

  useEffect(() => {
    props.ProductSearch({
      from,
      first,
      department,
      storeid,
      category,
      category1,
      category2,
      status,
      key,
    });
  }, []);

  useEffect(() => {
    if (props?.searchProductData) {
      const newProducts =
        props.searchProductData?.data?.searchProducts?.items || [];
      setProducts(newProducts);
    }
  }, [props?.searchProductData]);

  const [popUpID, setPopUpID] = useState("");
  const nav = useNavigate();

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [toggle, setToggle] = useState(false);

  setTimeout(() => {
    setToggle(true);
  }, 800);

  const customStyles = {
    toggleBtnCirStyle: {
      display: "inline-block",
      border: "1px solid black",
      borderRadius: "15px",
      width: "50px",
      height: "20px",
      cursor: "pointer",
      boxSizing: "content-box",
      padding: "3px",
    },
    disOfflineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    offlineCirStyle: {
      display: "block",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      backgroundColor: "gray",
      marginRight: "10px",
    },
    disOnlineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    onlineCirStyle: {
      display: "block",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      backgroundColor: "#72B01D",
      marginLeft: "10px",
    },
    active: {
      display: "none",
    },
  };
  useEffect(() => {
    if (props.DeleteProductSuccessData) {
      props.ProductSearch(
        from,
        first,
        department,
        storeid,
        category,
        category1,
        category2,
        status,
        key
      );
    }
  }, [props.DeleteProductSuccessData]);

  const handleDeleteProductSuccess = () => {
    popupState1.close();
    if (selectedProduct) {
      props.clearInput();
      props.Deleteproduct(selectedProduct.id);
    }
    if (props?.searchProductData) {
      props.clearInput();
      const newProducts =
        props.searchProductData?.data?.searchProducts?.items || [];
      setProducts(newProducts);
    }
    setDeleteDialog(false);
  };
  const handleDialogClose = () => {
    popupState1.close();
    setDeleteDialog(false);
  };

  const handleStatusChange = (status1, id) => {
    var newStatus;
    if (status1 === "active") {
      status1 = "inactive";
      newStatus = status1;

      props.Editproductstatus(id, newStatus);
    } else if (status1 === "inactive") {
      status1 = "active";
      newStatus = status1;

      props.Editproductstatus(id, newStatus);
    }

    if (props?.ProductStatusUpdateSuccess) {
      props.ProductSearch(
        from,
        first,
        department,
        storeid,
        category,
        category1,
        category2,
        status,
        key
      );
    }
  };
  const handleLoadMore = () => {
    setFirst(first + 20);
  };
  if (props.show1) {
  }
  const LoadMore = () => {
    setFirst(first + 50);
    props.ProductSearch({ first: first + 50 });
  };

  const popupState1 = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const handlePopUpID = (id) => {
    setSelectedProduct(id);

    setPopUpID(id);
  };
  const EditClick = (source) => {
    props.clearInput();

    if (selectedProduct) {
      const details = {
        Productid: selectedProduct.id,
        uom: selectedProduct.uom,
        salesdepartment: selectedProduct?.salesdepartment?.id,
        salescategory: selectedProduct?.salescategory?.id,
        img: selectedProduct?.img?.primary,
      };
      localStorage.setItem("Productid", selectedProduct.id);
      nav("/home/EditProducts/details", { state: { details: details } });
    }
  };
  const handleDeleteProduct = () => {
    props.clearInput();
    popupState1.close();
    setDeleteDialog(true);
  };

  return (
    <>
      <div>
        {toggle &&
          products.map((source) => {
            const price = parseFloat(source.price);
            const promoPrice = parseFloat(source.promoprice);
            const discount = price - promoPrice;
            const discountPercentage = ((discount / price) * 100).toFixed(2);
            const formattedPrice = price.toFixed(2);
            const isInactive = source.status === "inactive";

            const color = isInactive ? "#6D6D6D" : "black";

            const handleStatusChange = (productId, newStatus) => {
              const updatedProducts = products.map((product) =>
                product.id === productId
                  ? { ...product, status: newStatus }
                  : product
              );
              setProducts(updatedProducts);
            };

            return (
              <div
                key={source.id}
                style={{
                  paddingTop: "20px",
                  boxSizing: "border-box",
                }}
              >
                <CustomCard
                  padding={"20px"}
                  width="100%"
                  onClick={() => {
                    handlePopUpID(source);
                    props.setNewProduct(source);
                  }}
                  background={isInactive ? "#F2F2F2" : "white"}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                    >
                      <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <CustomCard
                          padding="2px"
                          width={"70px"}
                          height={"70px"}
                          boxShadow="none"
                          borderRadius="10px"
                          background={isInactive ? "#F2F2F2" : "white"}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "4px",
                            }}
                          >
                            <img
                              className={isInactive ? "added-image" : ""}
                              src={
                                (source?.image &&
                                  source?.image.primary === null) ||
                                (source?.image &&
                                  source?.image?.primary === "null")
                                  ? NoImage
                                  : source?.image?.primary
                              }
                              alt="milk"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                objectFit: "cover",
                                marginBottom: "10px",
                              }}
                            />
                          </div>
                        </CustomCard>
                      </Grid>
                      <Grid item xs={7} sm={3} md={3} lg={3} xl={4}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              className="price-pdt"
                              fontSize={contentFontSize_16}
                              color={color}
                            >
                              {source.productname}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={0}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{ paddingLeft: matches ? "16px" : "36px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flexStart",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "15px",
                              }}
                            >
                              <Typography
                                className="price-pdt"
                                fontSize={contentFontSize_16}
                                color={color}
                              >
                                ₹ {formattedPrice}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={0} sm={3} md={3} lg={3} xl={2}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                              paddingTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "15px",
                              }}
                            >
                              <div
                                style={{
                                  display:
                                    discountPercentage > 0 ? "block" : "none",
                                  width: "100px",
                                }}
                              >
                                <CustomCard
                                  padding="0"
                                  borderRadius="3px"
                                  height="60%"
                                  alignItems="center"
                                  width="100%"
                                  background={
                                    isInactive ? "#A0A0A0" : "#EA4335"
                                  }
                                  boxShadow="none"
                                  // className={isInactive ? 'added-image' : '#EA4335'}
                                >
                                  <Typography fontSize={contentFontsize_14}>
                                    <p
                                      className="off-100"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {discountPercentage} % OFF
                                    </p>
                                  </Typography>
                                </CustomCard>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={0} sm={2} md={2} lg={2} xl={2}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "space-between",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "15px",
                              }}
                            >
                              <ProductToggle
                                id={source.id}
                                status={
                                  source.status === "inactive" ? false : true
                                }
                                {...props}
                                onStatusChange={handleStatusChange}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "15px",
                              }}
                            >
                              <div
                                style={{
                                  paddingBottom: "7px",
                                  marginRight: "7px",
                                }}
                                {...bindTrigger(popupState1, source)}
                              >
                                <Three
                                  style={{
                                    fill: isInactive ? "#F2F2F2" : "white",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </CustomCard>

                <CustomPopover
                  key={source.id}
                  popupState={popupState1}
                  height={matches ? "70px" : "88.82px"}
                  width={matches ? "115px" : "130px"}
                  borderRadius="12.17px"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  padding="15px"
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      gap: matches ? "5px" : "10px",
                    }}
                  >
                    <div
                      onClick={() => {
                        EditClick(source);
                      }}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={editIconSA}
                            alt="edit icon"
                            width={matches && "14px"}
                            height={matches && "14px"}
                          />
                        </div>
                        <Typography
                          fontSize={contentFontSize_16}
                          className="ShopPopupStyle"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: color,
                          }}
                        >
                          Edit
                        </Typography>
                      </div>
                    </div>

                    <div
                      onClick={() => handleDeleteProduct(source)}
                      key={source.id}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={deleteIconSA}
                            alt="delete icon"
                            width={matches && "14px"}
                            height={matches && "14px"}
                          />
                        </div>
                        <Typography
                          fontSize={contentFontSize_16}
                          className="ShopPopupStyle"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#EA4335",
                          }}
                        >
                          Delete
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CustomPopover>
              </div>
            );
          })}
      </div>
      {props?.searchProductData?.data?.searchProducts?.count === 0 && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={HeaderFontSize_24} className="Add-product-btn">
            No Products to display
          </Typography>
        </div>
      )}
      {props.show1 === false &&
      props?.searchProductData?.data?.searchProducts?.count > first + 1 ? (
        <div style={{ marginTop: "10px" }}>
          <CustomButton width={"20%"} borderRadius="10px">
            <Typography
              fontSize={ButtonFontSize_18}
              className="Add-product-btn"
              onClick={LoadMore}
            >
              Load More
            </Typography>
          </CustomButton>
        </div>
      ) : null}
      {props.show1 && (
        <div>
          {props?.searchProductData?.data?.searchProducts?.count > first + 1 ? (
            <div style={{ marginTop: "10px" }}>
              <CustomButton width={"20%"} borderRadius="10px">
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                  onClick={handleLoadMore}
                >
                  Load More
                </Typography>
              </CustomButton>
            </div>
          ) : null}
        </div>
      )}
      <>
        {deleteDialog && (
          <div>
            <CustomDialog
              width="25%"
              borderRadius="20px"
              open={deleteDialog}
              onClose={handleDialogClose}
              titleAlign="center"
              padding="0px"
              showCloseButton={false}
            >
              <div style={{ textAlign: "center" }}>
                <Typography
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "20px",
                    boxSizing: "border-box",
                  }}
                  className="para-font-1"
                  fontSize={contentFontSize_18_xs_13}
                >
                  Do you want to delete <br />
                  the product?
                </Typography>
              </div>

              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div onClick={handleDialogClose}>
                  <Typography
                    className="delete"
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    Cancel
                  </Typography>
                </div>
                <div style={{ borderLeft: "1px solid black" }}></div>
                <div onClick={handleDeleteProductSuccess}>
                  <Typography
                    className="delete"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    Delete
                  </Typography>
                </div>
              </div>
            </CustomDialog>
          </div>
        )}
      </>
    </>
  );
}

export default ProductCard;
