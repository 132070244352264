// import React, { useEffect, useState } from "react";
// import CustomCard from "../Commoncomponents/card";
// import partner from "../../utils/images/partner.png";
// import { Typography, useMediaQuery, useTheme,Button,Card } from "../MUIComponents/index";
// import {
//   HeaderNavigationFontSize_22,
//   contentFontSize_18,
//   ButtonFontSize_18,
//   HeaderfontSize_30,
//   contentFontSize_15,
// } from "../Typography";
// import "./LandingPage.css";
// import CustomButton from "../Commoncomponents/button";
// import rideimg from "../../utils/images/rideimg.jpeg";
// import Shopimg from "../../utils/images/Shopwithus.png";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { useNavigate } from "react-router-dom";

// function LandingPageCard() {
//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down("md"));
//   const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
//   const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
//   const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
//   const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
//   const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
//   const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
//   const [color, setColor] = useState("#13801C");
//   const nav = useNavigate()

//   const handleConnectClick = (subject) =>{
//     nav("/Contact", { state: { subject } });
//   }

//   useEffect(() => {
//     AOS.init({
//       once: true,
//       disable: "phone",
//       duration: 1500,
//       easing: "ease-out-cubic",
//     });
//     AOS.refresh();
//   }, []);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setColor((prevColor) => (prevColor === "#13801C" ? "black" : "#13801C"));
//     }, 2000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <div className="linear-gradient-background">
//       <div style={{ marginTop: "100px" }}>
//         <Typography
//           fontSize={HeaderfontSize_30}
//           className="header"
//           style={{ color: color }}
//         >
//           Let's Grow Together with NearShopz!
//         </Typography>
//       </div>
//       <div
//         style={{
//           marginTop: "50px",
//           display: "flex",
//           gap: "30px",
//           alignItems: "center",
//           padding: "20px",
//           justifyContent: "space-evenly",
//         }}
//       >
//         <div data-aos="slide-right">
//           <Card
//             className="card"
//             sx={{padding:"20px",paddingTop:"50px",height:"600px",width:"450px",background:"white",borderRadius:"20px", cursor:"pointer"}}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 width: "100%",
//               }}
//             >
//               <div>
//                 <img src={partner} alt="Nearshopz Partnership"  style={{ width: "100%",height:"300px" }} />
//               </div>
//               <div style={{ paddingTop: "20px" }}>
//                 <Typography
//                   fontSize={HeaderNavigationFontSize_22}
//                   className="header-1"
//                 >
//                   Partner with Us
//                 </Typography>
//               </div>
//               <div style={{ paddingTop: "20px",height:"150px" }}>
//                 <Typography fontSize={contentFontSize_15} className="para">
//                 Calling all ambitious business owners! Expand your reach and unlock new sales potential by partnering with NearShopz, the leading local ecommerce platform. With Low markups and convenient services, NearShopz helps your business grow. Join our network of successful sellers and attract more customers!
//                 </Typography>
//               </div>
//               <div style={{ paddingTop: "50px", textAlign: "center" }}>
//               <Button
//                   className="custom-button1"
//                   sx={{
//                     width: {
//                       xs: "40px",
//                       sm: "60px",
//                       md: "80px",
//                       lg: "130px",
//                       xl: "160px",
//                       upXl: "170px",
//                     },
//                     '&:focus': {
//                           outline: 'none',
//                         },
//                     height: "40px",
//                     borderRadius: "20px",
//                     background:
//                       "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
//                   }}
//                   onClick={() => handleConnectClick("Partner with Us")}
//                 >
//                   <Typography
//                     fontSize={ButtonFontSize_18}
//                     className="Add-product-btn"
//                   >
//                     Connect
//                   </Typography>
//                 </Button>
//               </div>
//             </div>
//           </Card>
//         </div>
//         <div data-aos="fade-down">
//         <Card
//             className="card"
//             sx={{padding:"20px",paddingTop:"50px",height:"600px",width:"450px",background:"white",borderRadius:"20px", cursor:"pointer"}}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 width: "100%",
//               }}
//             >
//               <div>
//                 <img
//                   src={Shopimg}
//                   alt="multi restaurant online ordering system"
//                   style={{ width: "100%",height:"300px",borderRadius:"10px" }}
//                 />
//               </div>
//               <div style={{ paddingTop: "20px" }}>
//                 <Typography
//                   fontSize={HeaderNavigationFontSize_22}
//                   className="header-1"
//                 >
//                   Shop with Us
//                 </Typography>
//               </div>
//               <div style={{ paddingTop: "20px" ,height:"150px" }}>
//                 <Typography fontSize={contentFontSize_15} className="para">
//                 Elevate your everyday with NearShopz!  From fresh groceries to delightful treats, essential daily items to pampering pet supplies, NearShopz offers endless options to buy and get it all delivered straight to your door.  Download NearShopz and simplify your life - shop smarter, not harder!
//                 </Typography>
//               </div>
//               <div style={{ paddingTop: "50px", textAlign: "center" }}>
//               <Button
//                   className="custom-button1"
//                   sx={{
//                     width: {
//                       xs: "40px",
//                       sm: "60px",
//                       md: "80px",
//                       lg: "130px",
//                       xl: "160px",
//                       upXl: "170px",
//                     },
//                     '&:focus': {
//                           outline: 'none',
//                         },
//                     height: "40px",
//                     borderRadius: "20px",
//                     background:
//                       "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
//                   }}
//                   onClick={() => handleConnectClick("Shop with Us")}
//                 >
//                   <Typography
//                     fontSize={ButtonFontSize_18}
//                     className="Add-product-btn"
//                   >
//                     Connect
//                   </Typography>
//                 </Button>
//               </div>
//             </div>
//           </Card>
//         </div>
//         <div data-aos="slide-left">
//         <Card
//             className="card"
//             sx={{padding:"20px",paddingTop:"50px",height:"600px",width:"450px",background:"white",borderRadius:"20px", cursor:"pointer"}}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 width: "100%",
//               }}
//             >
//               <div>
//                 <img
//                 alt="best food delivery app"
//                   src={rideimg}
//                   style={{ width: "100%",height:"300px" ,borderRadius:"10px"}}
//                 />
//               </div>
//               <div style={{ paddingTop: "20px" }}>
//                 <Typography
//                   fontSize={HeaderNavigationFontSize_22}
//                   className="header-1"
//                 >
//                   Ride with Us
//                 </Typography>
//               </div>
//               <div style={{ paddingTop: "20px",height:"150px"  }}>
//                 <Typography fontSize={contentFontSize_15} className="para">
//                 Looking for a flexible and rewarding opportunity? Join NearShopz as a delivery rider! Earn competitive income with a flexible schedule and full support. Deliver food, groceries , treats, fresh produce, and pet items to our customers. Download the NearShopz app to apply now and start earning today!
//                 </Typography>
//               </div>
//               <div style={{ paddingTop: "50px", textAlign: "center" }}>
//                 <Button
//                   className="custom-button1"
//                   sx={{
//                     width: {
//                       xs: "40px",
//                       sm: "60px",
//                       md: "80px",
//                       lg: "130px",
//                       xl: "160px",
//                       upXl: "170px",
//                     },
//                     '&:focus': {
//                           outline: 'none',
//                         },
//                     height: "40px",
//                     borderRadius: "20px",
//                     background:
//                       "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
//                   }}
//                   onClick={() => handleConnectClick("Ride with Us")}
//                 >
//                   <Typography
//                     fontSize={ButtonFontSize_18}
//                     className="Add-product-btn"
//                   >
//                     Connect
//                   </Typography>
//                 </Button>
//               </div>
//             </div>
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default LandingPageCard;

import React, { useEffect, useRef, useState } from "react";
import partner from "../../utils/images/partner.png";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Card,
} from "../MUIComponents/index";
import {
  HeaderNavigationFontSize_22,
  contentFontSize_15,
  ButtonFontSize_18,
  HeaderfontSize_30,
} from "../Typography";
import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import rideimg from "../../utils/images/rideimg.jpeg";
import Shopimg from "../../utils/images/Shopwithus.png";
import CustomCard from "../Commoncomponents/card";

function LandingPageCard() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [color, setColor] = useState("#13801C");
  const nav = useNavigate();

  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);

  const handleConnectClick = (subject) => {
    nav("/Contact", { state: { subject } });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor((prevColor) => (prevColor === "#13801C" ? "black" : "#13801C"));
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const options = { threshold: 0.5 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    }, options);

    if (card1Ref.current) observer.observe(card1Ref.current);
    if (card2Ref.current) observer.observe(card2Ref.current);
    if (card3Ref.current) observer.observe(card3Ref.current);

    return () => {
      if (card1Ref.current) observer.unobserve(card1Ref.current);
      if (card2Ref.current) observer.unobserve(card2Ref.current);
      if (card3Ref.current) observer.unobserve(card3Ref.current);
    };
  }, []);

  return (
    <div className="linear-gradient-background">
      <div
        style={{
          marginTop: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={HeaderfontSize_30}
          className="header"
          style={{ color: color }}
        >
          Let's Grow Together with NearShopz!
        </Typography>
      </div>
      <div className="cards-container">
        <div ref={card1Ref} className="card-animated slide-right">
          <Card
            className="card"
            sx={{
              padding: "20px",
              paddingTop: "50px",
              height: "600px",
              width: lgmatch ? "350px" : matches ? "250px"  : smmatch ? "200px": xsmatch ? "200px":"450px",
              background: "white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <img
              src={partner}
              alt="Nearshopz Partnership"
              style={{ width: "100%", height: "300px", borderRadius: "10px" }}
            />
            <div style={{ paddingTop: "20px" }}>
              <Typography
                fontSize={HeaderNavigationFontSize_22}
                className="header-1"
              >
                Partner with Us
              </Typography>
            </div>
            <div style={{ paddingTop: "20px", height: "150px" }}>
              <Typography fontSize={contentFontSize_15} className="para">
                Calling all ambitious business owners! Expand your reach and
                unlock new sales potential by partnering with NearShopz, the
                leading local ecommerce platform.
              </Typography>
            </div>
            <div style={{ paddingTop: "30px", textAlign: "center" }}>
              <Button
                className="custom-button1"
                sx={{
                  width: {
                    xs: "40px",
                    sm: "60px",
                    md: "80px",
                    lg: "130px",
                    xl: "160px",
                    upXl: "170px",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                  height: "40px",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                }}
                onClick={() => handleConnectClick("Partner with Us")}
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                >
                  Connect
                </Typography>
              </Button>
            </div>
          </Card>
        </div>

        <div ref={card2Ref} className="card-animated fade-down">
          <Card
            className="card"
            sx={{
              padding: "20px",
              paddingTop: "50px",
              height: "600px",
              width: lgmatch ? "350px" : matches ? "250px"  : smmatch ? "200px": xsmatch ? "200px":"450px",
              background: "white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <img
              src={Shopimg}
              alt="Shop with us"
              style={{ width: "100%", height: "300px", borderRadius: "10px" }}
            />
            <div style={{ paddingTop: "20px" }}>
              <Typography
                fontSize={HeaderNavigationFontSize_22}
                className="header-1"
              >
                Shop with Us
              </Typography>
            </div>
            <div style={{ paddingTop: "20px", height: "150px" }}>
              <Typography fontSize={contentFontSize_15} className="para">
                Elevate your everyday with NearShopz! From fresh groceries to
                delightful treats, essential daily items to pampering pet
                supplies.
              </Typography>
            </div>
            <div style={{ paddingTop: "30px", textAlign: "center" }}>
              <Button
                className="custom-button1"
                sx={{
                  width: {
                    xs: "40px",
                    sm: "60px",
                    md: "80px",
                    lg: "130px",
                    xl: "160px",
                    upXl: "170px",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                  height: "40px",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                }}
                onClick={() => handleConnectClick("Shop with Us")}
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                >
                  Connect
                </Typography>
              </Button>
            </div>
          </Card>
        </div>

        <div ref={card3Ref} className="card-animated slide-left">
          <Card
            className="card"
            sx={{
              padding: "20px",
              paddingTop: "50px",
              height: "600px",
              width :lgmatch ? "350px" : matches ? "250px"  : smmatch ? "200px": xsmatch ? "200px":"450px",
              background: "white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <img
              src={rideimg}
              alt="Ride with us"
              style={{ width: "100%", height: "300px", borderRadius: "10px" }}
            />
            <div style={{ paddingTop: "20px" }}>
              <Typography
                fontSize={HeaderNavigationFontSize_22}
                className="header-1"
              >
                Ride with Us
              </Typography>
            </div>
            <div style={{ paddingTop: "30px", textAlign: "center" }}>
              <Typography fontSize={contentFontSize_15} className="para">
                Join NearShopz as a delivery rider! Earn competitive income with
                a flexible schedule and full support.
              </Typography>
            </div>
            <div style={{ paddingTop: "90px", textAlign: "center" }}>
              <Button
                className="custom-button1"
                sx={{
                  width: {
                    xs: "40px",
                    sm: "60px",
                    md: "80px",
                    lg: "130px",
                    xl: "160px",
                    upXl: "170px",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                  height: "40px",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                }}
                onClick={() => handleConnectClick("Ride with Us")}
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                >
                  Connect
                </Typography>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default LandingPageCard;
