// import { Breadcrumbs, DialogContent, DialogTitle, Link, Typography } from "../../MUIComponents/index";
// import React, { useEffect, useState } from "react";
// import Direction from "../../../utils/Icon/Direction.svg";
// import { Checkbox } from "../../MUIComponents/index";
// import "./index.css";
// import ActiveOrderLine from "../../../utils/Icon/ActiveOrderLine.svg";
// import BlueClock from "../../../utils/Icon/BlueClock.svg";
// import CustomButton from "../../Commoncomponents/button";
// import CustomPopover from "../../Commoncomponents/popup/index";
// import ReadyForDelivery from "../ReadyForDelivery";

// import CustomDialog from "../../Commoncomponents/dialog";
// import { useLocation, useNavigate } from "react-router-dom";
// import  Food  from "../../../utils/Icon/Food.svg";
// import  LineInDialog  from "../../../utils/Icon/LineInDialog.svg";
// import CustomCard from "../../Commoncomponents/card";

// function ActiveOrder() {
//   // passing data
//   //  const location = useLocation();
//   //  const dataFromLocation = location.state?.data;
//   //  console.log(dataFromLocation)
//     // const ActiveOrderDatas= Object.values(dataFromLocation);
//     //   console.log(ActiveOrderDatas)

//     const dataItems = JSON.parse(localStorage.getItem('orderCardDetails'));
//     console.log("Data from local storage:", dataItems);
//     const ActiveOrderDatas = Object.values(dataItems)
//     console.log(ActiveOrderDatas)
// //   const calculateTotalPrice = () => {
// //     const subtotal = ActiveOrderDatas?.reduce((acc, item) => {
// //       if (selectedIds?.includes(item.id)) {
// //         return acc + item?.quantity * item?.price;
// //       }
// //       return acc;
// //     }, 0);

// //     // Additional charges
// //     const deliveryCharge = 15;
// //     const packingCharge = 10.0;
// //     const couponAmount = -45.2;

// //     // Calculate total including additional charges
// //     const totalAmount =
// //       subtotal + deliveryCharge + packingCharge + couponAmount;

// //     return {
// //       subtotal,
// //       deliveryCharge,
// //       packingCharge,
// //       couponAmount,
// //       totalAmount,
// //     };
// //   };

// //   const { subtotal, deliveryCharge, packingCharge, couponAmount, totalAmount } =
// //     calculateTotalPrice();
// //     const nav = useNavigate();
// // function handleOrderReady(){
// //   localStorage.setItem('orderDetails', JSON.stringify(selectedData));
// //   // nav("/home/MyOrders/ReadyForDelivery", { state: { data: selectedData  } });
// //   nav("/home/MyOrders/ReadyForDelivery")
// // }
// // const [count,setCount]=useState(0)
// // const [open,setOpen]=useState(false)
// // function handleQuantity(){
// //      setOpen(true)
// // }
// // function handleClose(){
// //   setOpen(false)
// // }

// // function handleLinkActive(){
// //   nav("/home/MyOrders",{ state: { data: selectedData  } })
// // }

// return (
//     <>

//         <div style={{ marginLeft: "50px" }}>
//           <>

//           {/* <p style={{color:'#646464',textDecoration:"none",cursor:'pointer'}} onClick={handleLinkActive} href="/home/MyOrders">
//            Active Orders
//           </p> */}
//               <Breadcrumbs>
//               <Link
//                 sx={{ color: "#659B1B", fontWeight: "bold", fontSize: "18px" }}
//                 href="/home/MyOrders"
//               >
//                 View Active details
//               </Link>
//             </Breadcrumbs>
//             <div>
//               <Typography>
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <img
//                     src={Direction}
//                     style={{ width: "20px", height: "20px", marginTop: "30px" }}
//                     alt="Direction Icon"
//                   />
//                   <span
//                     style={{
//                       marginLeft: "8px",
//                       marginTop: "30px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Saroj Kumar
//                   </span>
//                 </div>
//                 <p>
//                   SBC- 12, Tejeswini, Technopark, Kazhakuttom,
//                   <br /> Kerala - 695584 Fri : Jul 21 2023, 3:43 PM
//                 </p>
//               </Typography>
//             </div>
//             <div className="activeorderBox">
//               <span style={{ fontWeight: "bold" }}>
//                 #15782 - Scheduled on Fri, Jul 21 2023, 3:43 PM
//               </span>
//             </div>
//             {Array.isArray(ActiveOrderDatas) && (
//   <div>
//     {ActiveOrderDatas.map((order) => (
//       <div key={order?.orderId}>
//         {Array.isArray(order?.items) && order.items.length > 0 && (
//           <div className="activeOrderGrid">
//             {order.items.map((item) => (
//               <div key={item?.name}>
//                 <Checkbox
//                   // checked={selectedIds?.includes(item?.id)}
//                   // onChange={() => handleCheckboxChange(item?.id)}
//                 />
//                 <p>
//                   {console.log(item.name)}
//                   {item?.name}
//                   <br />
//                   {item?.quantity}X{item?.price}
//                 </p>
//                 <div>
//                   {item?.quantity * item?.price} <br />
//                   <p
//                     // onClick={handleQuantity}
//                     style={{ cursor: 'pointer', color: '#85BE49' }}
//                   >
//                     Change quantity
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     ))}
//   </div>
// )}

//     {/* {open && (
//       <CustomDialog open={open} onClick={handleClose} width="400px" height="500px">
//         <DialogTitle style={{ color: '#85BE49' }}>Change quantity</DialogTitle>
//         <img style={{ width: '300px' }} src={LineInDialog} />
//         <DialogContent>
//           <CustomCard
//             padding="2px"
//             width={'70px'}
//             height={'70px'}
//             background="#F2F2F2"
//             boxShadow="none"
//             borderRadius="10px"
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 // padding:"6px"
//               }}
//             >
//               <img src={Food} alt="Beverages" />
//             </div>
//           </CustomCard>
//         </DialogContent>
//       </CustomDialog>
//     )}
//   </div>
// ))}

//             <div>
//               <img src={ActiveOrderLine} />
//             </div>
//             <div className="activeOrderGridOne">
//               <p style={{ fontWeight: "bold" }}>Total Price </p>
//               <p>{subtotal}</p>
//             </div>
//             <div className="activeOrderGridOne">
//               <p style={{ fontWeight: "bold" }}>Delivery Charge</p>
//               <p>{deliveryCharge}</p>
//             </div>
//             <div className="activeOrderGridOne">
//               <p style={{ fontWeight: "bold", color: "#1D89E1" }}>Coupon</p>
//               <p>{couponAmount}</p>
//             </div>
//             <div className="activeOrderGridOne">
//               <p style={{ fontWeight: "bold" }}>Packing Charges</p>
//               <p>{packingCharge}</p>
//             </div>
//             <div>
//               <img src={ActiveOrderLine} />
//             </div>
//             <div className="activeOrderGridOne">
//               <p>Total</p>
//               <p>{totalAmount}</p>
//             </div> */}
//             {/* <div>
//               <p style={{ color: "#1D89E1" }}>
//                 <img
//                   style={{ width: "20px", height: "20px" }}
//                   src={BlueClock}
//                 />
//                 Waiting for Payment
//               </p>
//             </div>
//              <div className="OrderReadyButton">
//               <CustomButton
//                 borderRadius="20px"
//                 width="360px"
//                 height="50px"
//                 onClick={handleOrderReady}
//               >
//                 Order Ready
//               </CustomButton>
//             </div> */}
//           </>

//         </div>

//     </>
//   );
// }
// export default ActiveOrder;

import {
  DialogContent,
  DialogTitle,
  Checkbox,
  Grid,
  Typography,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "../../MUIComponents/index";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ActiveOrder.css";
import Direction from "../../../utils/Icon/Direction.svg";
import Phone from "../../../utils/Icon/Phone.svg";
import Pointer from "../../../utils/Icon/Pointer.svg";
import BlueClock from "../../../utils/Icon/BlueClock.svg";
import ActiveOrderLine from "../../../utils/Icon/ActiveOrderLine.svg";
import CustomButton from "../../Commoncomponents/button";

import CustomDialog from "../../Commoncomponents/dialog";
import LineInReadyForDel from "../../../utils/Icon/LineInReadyForDel.svg";
import Food from "../../../utils/Icon/Food.svg";
import LineInDialog from "../../../utils/Icon/LineInDialog.svg";
import CustomCard from "../../Commoncomponents/card";
import {
  contentFontSize_16,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_14_xs_10,
  contentFontsize_17,
  ButtonFontSize_18,
  HeaderNavigationFontSize_12,
} from "../../Typography";
import { contentFontSize_18 } from "../../Typography";
import Loaders from "../Loader";
import LoaderActive from "../Loader/LoaderActive";
import milk from "../../../utils/images/milk.png";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import { message } from "antd";

function ActiveOrderDetails(props) {
  const location = useLocation();
  const orderid = location.state?.orderid;
  const shopAssistantOrder = location.state?.shopAssistantOrder;
  const [open1, setOpen1] = React.useState(false);
  const [color, setColor] = React.useState(null);

  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isAssigned) {
      message.success({
        content: "Order Reassigned Successfully",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
      dispatch({ type: "ASSIGN_CLEAR" });

      setTimeout(() => {
        nav("/home/ShopAssistant");
      }, 4000);
    }
  }, [props.isAssigned]);

  const handleColorChange = (id) => {
    setColor(id);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleReassign = () => {
    props.AssignOrder(color, orderid);
    dispatch({ type: "ASSIGN_CLEAR" });
    // nav('/home/ShopAssistant')
  };

  useEffect(() => {
    props.getSearchDetails(orderid);
    props.getShopassistantlist(localStorage.getItem("storeid"));
  }, []);
  const activeOrderDetails = ("props", props && props.searchDetails);

  function handleClickOne() {
    nav("/home/MyOrders/ActiveOrders");
    localStorage.setItem("orderCardDetails", JSON.stringify(ActiveOrderData));
  }
  function handleClickTwo() {
    nav("/home/MyOrders");
  }

  const ActiveOrderData =
    JSON.parse(localStorage.getItem("orderCardDetails")) || [];
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [uncheckedItems, setUncheckedItems] = useState([]);
  
  console.log("uncheckedItems", uncheckedItems);
  useEffect(() => {
    const products = activeOrderDetails?.products;
    setCheckedItems(products);
  }, [activeOrderDetails?.products]);
  // const handleCheckboxChange = (item) => {
  //   const isChecked = checkedItems.some(
  //     (checkedItem) => checkedItem.productid.id === item.productid.id
  //   );

  //   if (isChecked) {
  //     // If the item is checked, uncheck it
  //     setCheckedItems(
  //       checkedItems.filter(
  //         (checkedItem) => checkedItem.productid.id !== item.productid.id
  //       )
  //     );
  //     setUncheckedItems([...uncheckedItems, updatedItems]);
      
  //     // updatedItemCopy.push({ ...selectedItem, quantity: counter });
  //   } else {
  //     // If the item is unchecked, check it
  //     setUncheckedItems(
  //       uncheckedItems.filter(
  //         (uncheckedItem) => uncheckedItem.productid?.id !== item.productid?.id
  //       )
  //     );
  //     setCheckedItems([...checkedItems, item]);
  //   }
  // };

  // const handleCheckboxChange = (item) => {
  //   const isChecked = checkedItems.some(
  //     (checkedItem) => checkedItem.productid.id === item.productid.id
  //   );
  
  //   if (isChecked) {
  //     // If the item is checked, uncheck it
  //     setCheckedItems(
  //       checkedItems.filter(
  //         (checkedItem) => checkedItem.productid.id !== item.productid.id
  //       )
  //     );
  //     setUncheckedItems([...uncheckedItems, updatedItems]); // Update this line to add the unchecked item correctly
  //   } else {
  //     // If the item is unchecked, check it
  //     setUncheckedItems(
  //       uncheckedItems.filter(
  //         (uncheckedItem) => uncheckedItem.productid?.id !== item.productid?.id
  //       )
  //     );
  //     setCheckedItems([...checkedItems, item]);
  //   }
  // };
  const [check,setCheck] = useState()
  const handleCheckboxChange = (item) => {
    const isChecked = checkedItems.some(
      (checkedItem) => checkedItem.productid.id === item.productid.id
    );
    setCheck(isChecked)
   console.log("isChecked",isChecked)
    if (isChecked) {
      // Uncheck item and set quantity to 0
      setCheckedItems(
        checkedItems.filter(
          (checkedItem) => checkedItem.productid.id !== item.productid.id
        )
      );
      setUncheckedItems([...uncheckedItems, { ...item, quantity: 0 }]);
    } else {
      // Check item and set initial quantity to counter
      setUncheckedItems(
        uncheckedItems.filter(
          (uncheckedItem) => uncheckedItem.productid.id !== item.productid.id
        )
      );
      setCheckedItems([...checkedItems, { ...item }]);
    }
  };


  const [active, setActive] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const handleQuantity = (item) => {
    setActive("");
    setSelectedItem(item); // Set the selected item for the dialog
    setOpen(true);
    const matchedItem =
      updatedItems.length > 0 &&
      updatedItems?.find(
        (q) =>
          q.productid?.id === item.productid?.id &&
          item?.quantity !== q?.quantity
      );

    setCounter(matchedItem ? matchedItem?.quantity : item.quantity);
  };
  console.log("selectedItem", selectedItem);
  function handleClose() {
    setOpen(false);
    //setActive("cancel")
    setSelectedItem(null);
  }

  const decrement = () => {
    if (counter > 0) {
      setCounter((counter) => counter - 1);
    }
  };
  const increment = (quantity) => {
    if (counter < quantity) {
      setCounter((prevCounter) => prevCounter + 1);
    } else {
      alert("You've reached the maximum quantity!");
    }
  };
  // const increment = (quantity) => {
  //   if (counter > 0 && quantity >= counter ) {
  //     setCounter((counter) => counter + 1);
  //   }
  // };
  const [updatedOrder, setUpdatedOrder] = useState([]);

  const [updatedItems, setUpdatedItem] = useState([]);
  function handleChange(selectedItem) {
    setActive("change");
    setOpen(false);

    // Make a copy of the current updated items state
    const updatedItemCopy = [...updatedItems];

    // Check if the selected item already exists in updatedItems
    const index = updatedItemCopy.findIndex(
      (item) => item?.productid?.id === selectedItem?.productid?.id
    );
    console.log("updatedItemCopy", updatedItemCopy);
    console.log("index", index);
    if (index !== -1) {
      // If the counter is 0, remove the item from updatedItems
      if (counter === 0) {
        //updatedItemCopy.splice(index, 1);
        updatedItemCopy[index].quantity = counter;
        console.log("unchecked list", updatedItemCopy[index]?.quantity);
        const list = [updatedItemCopy[index]];
        setUncheckedItems(list);
      } else {
        // Update the item's quantity in updatedItems
        updatedItemCopy[index].quantity = counter;
      }
    } else {
      // If the item doesn't exist, add it to updatedItems with the updated quantity
      updatedItemCopy.push({ ...selectedItem, quantity: counter });
    }

    // Update the quantity of the selectedItem as well
    // selectedItem.quantity = counter;
    setSelectedItem(updatedItems);

    // Update the state with the new updated items
    setUpdatedItem(updatedItemCopy);

    // Store the updated items in localStorage
    localStorage.setItem("updatedItem", JSON.stringify(updatedItemCopy));

    // Optional: Store the updated selectedItem in localStorage if needed
    localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
  }

  //   function handleChange(selectedItem) {
  //     setActive("change");
  //     setOpen(false);

  //     const updatedItemCopy = [...updatedItems]; // Make a copy of the current state
  //     console.log("updatedItemCopy",updatedItemCopy)
  //     // Check if the selected item already exists in updatedItems
  //     const index = updatedItemCopy.findIndex(
  //       (item) => item.productid.id === selectedItem.productid.id
  //     );

  //     if (index !== -1) {
  //       // If the counter is 0, remove the item from updatedItems
  //       if (counter === 0) {
  //         updatedItemCopy.splice(index, 1);
  //       } else {
  //         // If the item already exists in updatedItems, update its quantity
  //         updatedItemCopy[index].quantity = counter;
  //       }
  //     } else {
  //       // If the item doesn't exist in updatedItems, add it with the updated quantity
  //       updatedItemCopy.push({ ...selectedItem, quantity: counter });
  //     }

  //     // Update the state with the new value
  //     setUpdatedItem(updatedItemCopy);

  //     // Store the updated data in localStorage
  //     localStorage.setItem("updatedItems", JSON.stringify(updatedItemCopy));
  // }

  const mergedItems = [
    ...items.filter(
      (item) =>
        !updatedItems.some(
          (updatedItem) => updatedItem.productid.id === item.productid.id
        )
    ),
    ...updatedItems,
  ];

  const extractedProducts = activeOrderDetails?.products?.map((every) => {
    const productid = every?.productid?.id;
    const productPrice = every?.productPrice;
    const shopAssistantQuantity = every?.quantity;
    const status = 2;

    return { productid, productPrice, shopAssistantQuantity, status };
  });

  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [counter, setCounter] = useState(0); // Initialize counter with 0
  const [tick, setTick] = useState(false);
  console.log("counter", counter);
  // const result = mergedItems?.map((item) => {
  //   const matchingOrderDetails = checkedItems?.find(
  //     (orderDetail) => orderDetail?.productid?.id === item?.productid?.id
  //   );

  //   const productid = item.productid?.id;
  //   const productPrice = item?.productPrice;
  //   console.log("matchingOrderDetails", matchingOrderDetails);
  //   if (
  //     item.quantity === 0 &&
  //     !uncheckedItems?.some(
  //       (uncheckedItem) => uncheckedItem?.productid?.id === item?.productid?.id
  //     )
  //   ) {
  //     setTick(true);
  //     setUncheckedItems([
  //       ...uncheckedItems,
        
  //         updatedItems
  //         //quantity: matchingOrderDetails?.quantity ?? 0,
  //         ]);
  //     setCheckedItems(
  //       checkedItems.filter(
  //         (checkedItem) => checkedItem?.productid?.id !== item?.productid?.id
  //       )
  //     );
  //   }
  //   const shopAssistantQuantity =
  //     counter === (matchingOrderDetails?.quantity ?? item?.quantity)
  //       ? matchingOrderDetails?.quantity ?? item?.quantity
  //       : item?.quantity;
  //   const status =
  //     matchingOrderDetails?.quantity === item?.quantity
  //       ? 2
  //       : item?.quantity === 0
  //       ? 3
  //       : 1;

  //   return {
  //     productid: productid,
  //     productPrice: productPrice,
  //     status: status,
  //     shopAssistantQuantity: shopAssistantQuantity,
  //   };
  // });



  useEffect(() => {
    const updatedUncheckedItems = mergedItems?.filter((item) => {
      const isMatchingOrderDetails = checkedItems?.find(
        (orderDetail) => orderDetail?.productid?.id === item?.productid?.id
      );
  
      if (
        item.quantity === 0 &&
        !uncheckedItems?.some(
          (uncheckedItem) => uncheckedItem?.productid?.id === item?.productid?.id
        )
      ) {
        setTick(true);
        return true; // Include this item in the new unchecked items
      }
      return false;
    });
  
    if (updatedUncheckedItems.length > 0) {
      setUncheckedItems((prevUncheckedItems) => [
        ...prevUncheckedItems,
        ...updatedUncheckedItems,
      ]);
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter(
          (checkedItem) =>
            !updatedUncheckedItems.some(
              (updatedItem) => updatedItem?.productid?.id === checkedItem?.productid?.id
            )
        )
      );
    }
  }, [mergedItems, checkedItems, uncheckedItems]); // Dependencies to trigger useEffect
  
  const result = mergedItems?.map((item) => {
    const matchingOrderDetails = checkedItems?.find(
      (orderDetail) => orderDetail?.productid?.id === item?.productid?.id
    );
  
    const productid = item.productid?.id;
    const productPrice = item?.productPrice;
    
    const shopAssistantQuantity =
      counter === (matchingOrderDetails?.quantity ?? item?.quantity)
        ? matchingOrderDetails?.quantity ?? item?.quantity
        : item?.quantity;
    const status =
      matchingOrderDetails?.quantity === item?.quantity
        ? 2
        : item?.quantity === 0
        ? 3
        : 1;
  
    return {
      productid: productid,
      productPrice: productPrice,
      status: status,
      shopAssistantQuantity: shopAssistantQuantity,
    };
  });
  

  const unselectedItems = uncheckedItems.map((item) => {
   if(item.quantity === 0) { 
    const productid = item.productid?.id;
    const productPrice = item?.productPrice;
    const shopAssistantQuantity = 0;
    const status = 3;

    return {
      productid: productid,
      productPrice: productPrice,
      status: status,
      shopAssistantQuantity: shopAssistantQuantity,
    };
  }
  });

  const combinedItems = [];

result.forEach((resultItem) => {
  if (resultItem) { // Ensure resultItem is not null or undefined
    const matchingUnselectedItem =
      unselectedItems !== undefined &&
      unselectedItems.find(
        (unselectedItem) => unselectedItem?.productid === resultItem?.productid
      );

    if (matchingUnselectedItem) {
      combinedItems.push({
        ...resultItem,
        shopAssistantQuantity: matchingUnselectedItem.shopAssistantQuantity,
        status: matchingUnselectedItem.status,
      });
    } else {
      combinedItems.push(resultItem);
    }
  }
});

// Add unselectedItems that are not present in result
unselectedItems.forEach((unselectedItem) => {
  if (unselectedItem) { // Ensure unselectedItem is not null or undefined
    const matchingResultItem = result.find(
      (resultItem) => resultItem?.productid === unselectedItem?.productid
    );

    if (!matchingResultItem) {
      combinedItems.push(unselectedItem);
    }
  }
});

const combinedProducts = [];

extractedProducts?.forEach((extractedProduct) => {
  if (extractedProduct) { // Ensure extractedProduct is not null or undefined
    const matchingCombinedItem = combinedItems?.find(
      (combinedItem) =>
        combinedItem?.productid === extractedProduct?.productid
    );

    if (matchingCombinedItem) {
      combinedProducts.push({
        ...matchingCombinedItem,
      });
    } else {
      combinedProducts.push(extractedProduct);
    }
  }
});

// Add combinedItems that are not present in extractedProducts
combinedItems?.forEach((combinedItem) => {
  if (combinedItem) { // Ensure combinedItem is not null or undefined
    const matchingExtractedProduct = extractedProducts.find(
      (extractedProduct) =>
        extractedProduct?.productid === combinedItem?.productid
    );

    if (!matchingExtractedProduct) {
      combinedProducts.push(combinedItem);
    }
  }
});

// Filter out null or undefined items in the final array
const products = combinedProducts.filter((item) => item !== null && item !== undefined);

console.log("products", products);

  const [loading, setLoading] = useState(false);
  function handleOrderReady() {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      if (shopAssistantOrder) {
        nav("/home/ShopAssistant/orders", {
          state: {
            shopAssistantId: location.state.shopAssistantId,
            fname: location.state.fname,
            lname: location.state.lname,
            phno: location.state.phno,
            profilePicUrl: location.state.profilePicUrl,
            moveReady: true,
          },
        });
      } else {
        nav("/home/MyOrders/ReadyForDeliveryDetails", {
          state: { orderid: orderid },
        });
      }
    }, 3000);

    props.ChangetoReadyStatus(orderid, products);
  }
  {
    products.map((every) => console.log("e", typeof every?.productPrice));
  }

  useEffect(() => {
    {
      activeOrderDetails?.products?.map((each) => {});
    }
  }, []);

  const totalProductPrice = combinedProducts.reduce((total, item) => {
    return active === "change" &&
      combinedProducts &&
      combinedProducts.some(
        (updatedItem) => updatedItem?.productid?.id === item?.productid?.id
      )
      ? total +
          (combinedProducts.find(
            (updatedItem) => updatedItem?.productid === item?.productid
          )?.shopAssistantQuantity || 0) *
            (item?.productPrice || 0)
      : total + (item?.shopAssistantQuantity || 0) * (item?.productPrice || 0);
  }, 0);

  const deliveryCharge =
    activeOrderDetails?.version === 3
      ? activeOrderDetails?.deliveryChargeWithoutMarkup
      : activeOrderDetails?.deliveryCharge;
  const packingCharge =
    activeOrderDetails?.version === 3
      ? activeOrderDetails?.packingChargeWithoutMarkup
      : activeOrderDetails?.packingCharge;
  const discountPrice =
    activeOrderDetails?.couponID?.storeid !== null
      ? activeOrderDetails?.discountPrice
      : null;

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;
  console.log("active", active);
  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  console.log("unselected", unselectedItems);
  console.log("updated", updatedItems);
  return (
    <div>
      {!loading && (
        <>
          <div style={{ display: "flex", gap: "3px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={handleClickOne}
              className="MainActiveOrder"
            >
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
                sx={{ textDecoration: "underline" }}
              >
                Active Orders
              </Typography>
            </div>
            <div>
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
            </div>
            <div style={{ cursor: "pointer" }}>
              <Typography
                className="MainActives"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                View Active Order Details
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <Grid container spacing={2} sx={{ paddingLeft: "0px" }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ paddingLeft: "0px" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <img
                      src={Direction}
                      style={{ width: "20px", height: "20px" }}
                      alt="Direction Icon"
                    />

                    <div>
                      <Typography
                        className="nameStyle"
                        sx={{ fontSize: { contentFontsize_14 } }}
                      >
                        {" "}
                        {activeOrderDetails && activeOrderDetails.customerName}
                      </Typography>
                    </div>
                  </div>

                  {/* <div>
                    <Typography
                      className="address"
                      sx={{ fontSize: { contentFontsize_14 } }}
                    >
                      {activeOrderDetails && activeOrderDetails.deliveryAddress}{" "}
                    </Typography>
                  </div> */}
                  <div>
                    {/* {activeOrderDetails?.stats[1].map((item, index) => ( */}
                    <Typography
                      //key={index}
                      className="address"
                      sx={{ fontSize: contentFontsize_14 }}
                    >
                      {/* {newOrderDetails && newOrderDetails.deliverytime}{" "} */}
                      {activeOrderDetails?.deliveryAddress} ,{" "}
                      {activeOrderDetails?.stats[1]?.created},
                      {activeOrderDetails?.stats[1]?.createdTime}
                    </Typography>
                    {/* ))} */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          {shopAssistantOrder && (
            <div style={{ margin: "30px 0px" }}>
              <CustomCard
                borderRadius="15px"
                padding="20px 50px"
                background="#FFFFFF"
                borderTop="50px solid rgba(84, 134, 15, 0.99)"
                position="relative"
              >
                <Typography
                  style={{
                    position: "absolute",
                    fontFamily: "Roboto",
                    fontWeight: "600",
                    top: "-40px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "#FFFFFF",
                  }}
                  fontSize={contentFontSize_20}
                >
                  Shop Assistant
                </Typography>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ flex: "1", flexWrap: "wrap" }}>
                    <div
                      style={{ display: "flex", gap: "20%", flexWrap: "wrap" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <img
                            src={milk}
                            alt="man image"
                            height={mdmatch ? "50px" : "80px"}
                            width={mdmatch ? "50px" : "80px"}
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                        <div>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            fontSize={contentFontsize_14_xs_10}
                          >
                            Berlin Jino
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: xlmatch ? "10px 0px" : "20px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: smmatch ? "50px" : "20px",
                            overflow: "hidden",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "100%",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    background: "#85BE49",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    background: "#85BE49",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                              <div className="connecting-line"></div>
                              {/* </div> */}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              gap: "30px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: smmatch ? "30px" : "10px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "600",
                                }}
                                fontSize={contentFontsize_14_xs_10}
                              >
                                Order Placed
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "500",
                                  color: "#8B8989",
                                }}
                                fontSize={HeaderNavigationFontSize_12}
                              >
                                Fri : Jul 21 2023, 3:43 PM
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: smmatch ? "30px" : "10px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                                fontSize={contentFontsize_14_xs_10}
                              >
                                Assigned
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "500",
                                  color: "#8B8989",
                                }}
                                fontSize={HeaderNavigationFontSize_12}
                              >
                                Fri : Jul 21 2023, 3:43 PM
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CustomButton
                        background="#89C74A"
                        borderRadius="25px"
                        matchPadding={true}
                        padding="5px 20px"
                        onClick={handleClickOpen1}
                      >
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "700",
                            color: "#FFFFFF",
                          }}
                          fontSize={contentFontsize_14_xs_10}
                        >
                          Reassign
                        </Typography>
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </CustomCard>
            </div>
          )}

          <Dialog open={open1} onClose={handleClose1}>
            <DialogTitle>
              <div style={{ alignItems: "center", color: "#dbd7d7" }}>
                Reassign Delivery Partner
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {props.Shopassistantlist &&
                  props.Shopassistantlist.data &&
                  props.Shopassistantlist.data.shopAssistantsList &&
                  props.Shopassistantlist.data.shopAssistantsList.map(
                    (item) => (
                      <div
                        key={item.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            background:
                              color === item.id ? "#98dfed" : "#f0fffd",
                            borderRadius: "7px",
                            padding: "10px 100px 10px 20px",
                            fontWeight: "500",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => handleColorChange(item.id)}
                          fontSize={contentFontSize_18}
                        >
                          {item.firstName} {item.lastName}
                        </Typography>
                      </div>
                    )
                  )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <CustomButton
                  onClick={handleClose1}
                  borderRadius="20px"
                  matchPadding={true}
                  padding="10px 40px"
                  background="#dbd7d7"
                  color="black"
                  fontWeight="500"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  onClick={handleReassign}
                  borderRadius="20px"
                  matchPadding={true}
                  padding="10px 40px"
                  fontWeight="500"
                >
                  save
                </CustomButton>
              </div>
            </DialogActions>
          </Dialog>

          <div className="activeorderBox">
            <span>
              <Typography
                fontSize={contentFontsize_17}
                className="textStyleActive"
              >
                #{activeOrderDetails && activeOrderDetails.orderNumber} -
                Scheduled on{" "}
                {activeOrderDetails && activeOrderDetails.deliveryDate},{" "}
                {activeOrderDetails && activeOrderDetails.deliveryTime}
              </Typography>
            </span>
          </div>

          {/* {start} */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              marginTop: "20px",
              padding: smmatch ? "10px 10px" : "10px 70px",
            }}
          >
            {activeOrderDetails &&
              activeOrderDetails?.products?.map((item) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", gap: "60px" }}>
                        {/* <div>
                          <Checkbox
                            checked={
                              uncheckedItems.some(
                                (uncheckedItem) =>
                                  uncheckedItem?.productid?.id ===
                                  item?.productid?.id && uncheckedItem?.quantity === 0
                              )
                                ? false
                                : true
                            }
                            onChange={() => handleCheckboxChange(item)}
                            // disabled={uncheckedItems.some(
                            //   (uncheckedItem) =>
                            //     uncheckedItem.productid?.id === item?.productid?.id
                            //   && uncheckedItem?.quantity === 0
                            // )}
                            sx={{ paddingLeft: "0px" }}
                          />
                        </div> */}
                         <div>
  <Checkbox
    checked={
      // Determine if the item is checked based on quantity
      !uncheckedItems.some(
        (uncheckedItem) =>
          uncheckedItem?.productid?.id === item?.productid?.id &&
          uncheckedItem?.quantity === 0
      )
    }
    onChange={() => handleCheckboxChange(item)}
    // disabled={
    //   uncheckedItems.some(
    //     (uncheckedItem) =>
    //       uncheckedItem?.productid?.id === item?.productid?.id &&
    //       uncheckedItem?.quantity === 0
    //   )
    // }
    sx={{ paddingLeft: "0px" }}
  />
</div>    
                        <div>
                          <Typography
                            fontSize={contentFontsize_17}
                            className="productDetail"
                            style={{
                              color: uncheckedItems.some(
                                (selectedItem) =>
                                  selectedItem?.productid?.id ===
                                  item?.productid?.id && selectedItem?.quantity === 0
                              ) && check
                                ? "rgba(0, 0, 0, 0.5)"                 
                                : "black",
                            }}
                          >
                            {item.productid?.productname} <br />
                            {/* Render updated quantity if it exists */}
                            {updatedItems &&
                              updatedItems.map((updatedItem, index) => {
                                if (
                                  item?.productid?.id ===
                                    updatedItem?.productid?.id &&
                                  item.quantity !== updatedItem.quantity
                                ) {
                                  return (
                                    <Typography
                                      key={index}
                                      fontSize={contentFontsize_14}
                                      sx={{
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: uncheckedItems.some(
                                          (selectedItem) =>
                                            selectedItem?.productid?.id ===
                                            item?.productid?.id && selectedItem?.quantity === 0
                                        ) && check
                                          ? "rgba(0, 0, 0, 0.5)"
                                          : "black",
                                      }}
                                    >
                                      
                                      <>
  <span
    style={{
      textDecoration: "line-through",
    }}
  >
    {item.quantity} X {item.productPrice.toFixed(2)}
  </span>

  <span
    style={{
      color: "rgba(234, 67, 53, 1)",
      fontFamily: "Roboto",
    }}
  >
    {uncheckedItems.some(
      (selectedItem) =>
        selectedItem?.productid?.id === item?.productid?.id &&
        selectedItem?.quantity === 0
    )
      ? ` (${selectedItem?.quantity || 0} X ${updatedItem?.productPrice?.toFixed(2) })`
      : ` (${updatedItem?.quantity || 0} X ${updatedItem?.productPrice?.toFixed(2) || "0.00"})`}
  </span>
</>

                                      
                                    </Typography>
                                  );
                                }

                                return null;
                              })}
                              
                            {/* Render original quantity if updated quantity doesn't exist */}
                            {!updatedItems.some(
                              (updatedItem) =>
                                updatedItem?.productid?.id ===
                                item.productid?.id
                            ) && (
                              <Typography
                                fontSize={contentFontsize_14}
                                sx={{
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: uncheckedItems.some(
                                    (selectedItem) =>
                                      selectedItem?.productid?.id ===
                                      item?.productid?.id && selectedItem?.quantity === 0
                                  ) && check
                                    ? "rgba(0, 0, 0, 0.5)"
                                    : "black",
                                }}
                              >
                                {item.quantity !== undefined
                                  ? `${
                                      item.quantity
                                    } X ${item.productPrice.toFixed(2)}`
                                  : `${counter} X ${item.productPrice.toFixed(
                                      2
                                    )}`}
                              </Typography>
                            )}
                            {updatedItems &&
                              updatedItems.map((updatedItem, index) => {
                                if (
                                  item?.productid?.id ===
                                    updatedItem?.productid?.id &&
                                  item.quantity === updatedItem.quantity
                                ) {
                                  return (
                                    <Typography
                                      key={index}
                                      fontSize={contentFontsize_14}
                                      sx={{
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {`${
                                        item.quantity
                                      } X ${item.productPrice.toFixed(2)}`}
                                    </Typography>
                                  );
                                }

                                return null;
                              })}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1px",
                          }}
                        >
                          {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                          <Typography
                            fontSize={contentFontsize_17}
                            className="productDetail"
                            sx={{
                              color: uncheckedItems.some(
                                (selectedItem) =>
                                  selectedItem?.productid?.id ===
                                  item?.productid?.id && selectedItem?.quantity === 0
                              ) && check
                                ? "rgba(0, 0, 0, 0.5)"
                                : "black",
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            ₹{" "}
                            {
                            updatedItems?.some(
                              (updatedItem) =>
                                updatedItem?.productid?.id ===
                                  item?.productid?.id &&
                                updatedItem.quantity !== item.quantity
                            )
                              ? (
                                  (updatedItems.find(
                                    (updatedItem) =>
                                      updatedItem?.productid?.id ===
                                      item.productid?.id
                                  )?.quantity || 0) * (item?.productPrice || 0)
                                ).toFixed(2)
                              : (
                                  (item.quantity || 0) *
                                  (item?.productPrice || 0)
                                ).toFixed(2)}
                          </Typography>

                          <Typography
                            fontSize={contentFontsize_14_xs_10}
                            className="itemDetails"
                            onClick={() => {
                              if (
                                !uncheckedItems.some(
                                  (selectedItem) =>
                                    selectedItem?.productid?.id ===
                                    item?.productid?.id
                                )
                              ) {
                                handleQuantity(item); // Only trigger if condition is false
                              }
                            }}
                            sx={{
                              cursor: uncheckedItems.some(
                                (selectedItem) =>
                                  selectedItem?.productid?.id ===
                                  item?.productid?.id
                              ) && selectedItem?.quantity === 0 && check
                                ? "not-allowed" // Change the cursor style to indicate it's disabled
                                : "pointer",
                              //cursor:"pointer",
                              color: uncheckedItems.some(
                                (selectedItem) =>
                                  selectedItem?.productid?.id ===
                                  item?.productid?.id && selectedItem?.quantity === 0
                              ) && check 
                                ? "rgba(0, 0, 0, 0.5)"
                                : "#85BE49",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            Change Qty
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}

            {/* {end} */}

            {open && (
              <CustomDialog
                open={open}
                onClick={handleClose}
                width="500px"
                height="400px"
                showCloseButton={false}
              >
                <DialogTitle
                  style={{
                    color: "#85BE49",
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px 24px",
                  }}
                >
                  Change Quantity
                </DialogTitle>
                <img style={{ width: "300px" }} src={LineInDialog} />
                <DialogContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomCard
                    padding="2px"
                    width={"70px"}
                    height={"70px"}
                    background="#F2F2F2"
                    boxShadow="none"
                    borderRadius="10px"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // padding:"6px"
                      }}
                    >
                      <img
                        src={selectedItem?.productid?.image?.primary}
                        alt="Beverages"
                        style={{
                          marginTop: "9px",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                  </CustomCard>
                  <div style={{ marginLeft: "10px" }}>
                    <DialogContentText>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontFamily: "Roboto",
                          color: "black",
                        }}
                      >
                        {selectedItem?.productid?.productname}
                      </Typography>
                    </DialogContentText>
                  </div>

                  <>
                    <div className="counter-container">
                    <div className="counter-button" onClick={() => increment(selectedItem.quantity)}>+</div>
                      <span className="counter-value">{counter}</span>

                      <div className="counter-button" onClick={decrement}>
                        -
                      </div>
                    </div>
                  </>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomButton
                    onClick={handleClose}
                    width="160px"
                    height="45px"
                    variant={active === "cancel" ? "contained" : "outlined"}
                    background={active === "cancel" ? "#659B1B" : "transparent"}
                    color={active === "cancel" ? "white" : "#659B1B"}
                    borderRadius="25px"
                    borderColor="#659B1B"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    onClick={() => handleChange(selectedItem)}
                    width="160px"
                    height="45px"
                    variant={active === "change" ? "contained" : "outlined"}
                    background={active === "change" ? "#659B1B" : "transparent"}
                    color={active === "change" ? "white" : "#659B1B"}
                    borderRadius="25px"
                    borderColor="#659B1B"
                  >
                    Change
                  </CustomButton>
                </DialogActions>
              </CustomDialog>
            )}
          </div>

          {/* {end} */}

          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "60px",paddingLeft:"0px" }}
        > */}
          {/* <Grid item xs={8} md={9} lg={9} sm={9} xl={9}> */}
          {/* {start} */}

          <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Total Product price
              </Typography>
              {/* </Grid> */}
              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              >
                ₹{totalProductPrice?.toFixed(2)}
              </Typography>
            </div>
            {activeOrderDetails?.version === 3 && activeOrderDetails?.deliveryBy === null || activeOrderDetails?.deliveryBy === 1 ?   (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails1"
                >
                  Delivery Charge
                </Typography>
                {/* </Grid> */}

                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                <Typography
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                  // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                >
                  ₹{" "}
                  {activeOrderDetails?.deliveryChargeWithoutMarkup?.toFixed(2)}
                </Typography>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails1"
                >
                  Delivery Charge
                </Typography>
                {/* </Grid> */}

                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                <Typography
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                  // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                >
                  ₹ {activeOrderDetails?.deliveryCharge?.toFixed(2)}
                </Typography>
              </div>
            )}
            {activeOrderDetails?.discountPrice &&
            activeOrderDetails?.couponID?.storeid !== null ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  sx={{ color: "rgba(45, 147, 251, 1)" }}
                  className="productDetails1"
                >
                  Coupon - ({activeOrderDetails?.couponID?.couponCode})
                </Typography>
                {/* </Grid> */}

                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                <Typography
                  className="productDetails1"
                  sx={{ color: "rgba(45, 147, 251, 1)" }}
                  fontSize={contentFontSize_16}
                >
                  -₹ {activeOrderDetails?.discountPrice?.toFixed(2)}
                </Typography>
              </div>
            ) : null}
            {activeOrderDetails?.version === 3 ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails1"
                >
                  Packing Charges
                </Typography>
                {/* </Grid> */}

                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                <Typography
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                  // sx={{marginLeft:"22px"}}
                >
                  ₹ {packingCharge?.toFixed(2)}
                </Typography>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_16}
                  className="productDetails1"
                >
                  Packing Charges
                </Typography>
                {/* </Grid> */}

                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                <Typography
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                  // sx={{marginLeft:"22px"}}
                >
                  ₹ {activeOrderDetails?.packingCharge?.toFixed(2)}
                </Typography>
              </div>
            )}
          </div>
          {/* </Grid>
        </Grid> */}
          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "60px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}> */}
          <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_20_xs_18}
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              >
                Total
              </Typography>
              {/* </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                fontSize={contentFontSize_16}
              >
                ₹{grandTotal?.toFixed(2)}
              </Typography>
            </div>
          </div>
          {/* </Grid>
        </Grid> */}
          {activeOrderDetails?.paymentStatus === 0 ? (
            <div
              style={{
                marginTop: "20px",
                marginLeft: smmatch ? "0px" : "30px",
              }}
            >
              <Typography style={{ color: "#1D89E1" }}>
                <img
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                  src={BlueClock}
                />
                Waiting for Payment
              </Typography>
            </div>
          ) : (
            <div
              style={{
                marginTop: "20px",
                marginLeft: smmatch ? "0px" : "30px",
              }}
            >
              <Typography style={{ color: "#1D89E1" }}>
                <img
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                  src={BlueClock}
                />
                Payment Completed
              </Typography>
            </div>
          )}
          <div className="OrderReadyButton">
            <CustomButton
              borderRadius="20px"
              width="30%"
              height="50px"
              onClick={handleOrderReady}
            >
              <Typography
                fontSize={ButtonFontSize_18}
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              >
                Order Ready
              </Typography>
            </CustomButton>
          </div>
        </>
      )}
      {loading && <LoaderActive />}
    </div>
  );
}

export default ActiveOrderDetails;
