// import React, { useState } from "react";
// import { Stepper, Step, StepLabel, CircularProgress } from "@mui/material";
// import { Row } from "react-bootstrap";
// import { Formik, Form } from "formik";
// // import { ColorlibConnector, ColorlibStepIcon } from "./Colorlib";
// import Header from "../Header";
// import AddressForm from "./Forms/AddressForm";
// import PaymentForm from "./Forms/PaymentForm";
// import ReviewOrder from "./ReviewOrder";
// import DocumentUpload from "./Forms/DocumentUpload";
// import CheckoutSuccess from "./CheckoutSuccess";
// import { CssBaseline } from "@mui/material";
// // import { ThemeProvider } from "@mui/material/styles";
// import validationSchema from "./FormModel/validationSchema";
// import checkoutFormModel from "./FormModel/checkoutFormModel";
// import formInitialValues from "./FormModel/formInitialValues";
// import Additional from "./Forms/Additional";
// import Subscription from "./Forms/Subscription";
// import { theme } from "./NewStyle";
// // import makeStyles from "./styles";
// import { ThemeProvider } from "@mui/material/styles";
// // import createTheme from '@mui/material/styles';
// import { initialConfiguration } from "../../Routes/SignUpNew/constants";
// import { formWrap } from "../../Routes/SignUpNew/helper";
// import { gql } from "@apollo/client";
// import client from "../../Configurations/apollo";
// import Alert from "@mui/material/Alert";
// import Snackbar from "@mui/material/Snackbar";
// import AlertTitle from "@mui/material/AlertTitle";
// import { Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// import { useMediaQuery } from "react-responsive";
// import TextMobileStepper from "./MobileStepper";
// import { useLocation } from "react-router-dom";
// import { pickersArrowSwitcherClasses } from "@mui/x-date-pickers/internals";

// const steps = [
//   "Contact details",
//   "Store details",
//   "Additional details",
//   "Document upload",
//   "Subscription",
//   "Confirmation",
// ];
// const { formId, formField } = checkoutFormModel;

// export default function CheckoutPage(props) {
//   const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
//   React.useEffect(() => {
//     document.body.scrollTop = 0;
//   }, []);
//   document.title = "Seller Portal | Registration";
//   const [activeStep, setActiveStep] = useState(0);
//   const [isError, setIsError] = useState(false);
//   const currentValidationSchema = validationSchema[activeStep];
//   const isLastStep = activeStep === steps.length - 1;
//   const [state] = useState({
//     ...initialConfiguration,
//     activeStep: 0,
//     confirmWindow: false,
//   });

//   const location = useLocation();
//   const { storeName, mobileNumber, emailId, password1 } = location.state || {};
//   console.log("values:", storeName, mobileNumber, emailId, password1);

//   function _renderStepContent(step) {
//     switch (step) {
//       case 0:
//         return (
//           <AddressForm
//           storeName={location?.state?.storeName}
//           mobileNumber={location?.state.mobileNumber}
//           emailId={location?.state?.emailId}
//           password1={location?.state?.password1}
//             formField={formField}
//             props={props}
//             handleChange={props.handleChange}
//           />
//         );
//       case 1:
//         return <PaymentForm formField={formField} {...props} />;
//       case 2:
//         return <Additional formData={state} formField={formField} {...props} />;
//       case 3:
//         return <DocumentUpload formData={state} formField={formField} {...props} />;
//       case 4:
//         return <Subscription formField={formField} {...props} />;
//       case 5:
//         return (
//           <ReviewOrder
//             formData={state}
//             formField={formField}
//             businessType={props.businessId}
//             {...props}
//           />
//         );
//       default:
//         return <div>Not Found</div>;
//     }
//   }

//   function _sleep(ms) {
//     return new Promise((resolve) => setTimeout(resolve, ms));
//   }

//   async function _submitForm(values, actions) {
//     await _sleep(1000);
//     document.body.scrollTop = 0;
//     let FormData = await formWrap(state, props.timings, props.newSignedUrl);
//     if (activeStep === 2) {
//       console.log("props2", props);
//       console.log("values2", values);
//       console.log("formdata2", FormData[0]);
//       props.signupForm3(values, FormData, props);
//     } else if (values.PANnumber === "" && values.GSTnumber !== "") {
//       props.formSubmitP(
//         values,
//         FormData,
//         props.aadharUrl,
//         props.DeliveryCharges,
//         props.licenseUrl,
//         props.fssaiUrl,
//         props.GstUrl,
//         props.SameDayDelivery,
//         props.DeliveryDays,
//         props.businessTypeId
//       );
//     } else if (values.PANnumber !== "" && values.GSTnumber === "") {
//       props.formSubmitG(
//         values,
//         FormData,
//         props.aadharUrl,
//         props.DeliveryCharges,
//         props.licenseUrl,
//         props.fssaiUrl,
//         props.GstUrl,
//         props.SameDayDelivery,
//         props.DeliveryDays,
//         props.businessTypeId
//       );
//     } else if (values.PANnumber === "" && values.GSTnumber === "") {
//       props.formSubmitPG(
//         values,
//         FormData,
//         props.aadharUrl,
//         props.DeliveryCharges,
//         props.licenseUrl,
//         props.fssaiUrl,
//         props.GstUrl,
//         props.SameDayDelivery,
//         props.DeliveryDays,
//         props.businessTypeId
//       );
//     } else {
//       props.formSubmit(
//         values,
//         FormData,
//         props.aadharUrl,
//         props.DeliveryCharges,
//         props.licenseUrl,
//         props.fssaiUrl,
//         props.GstUrl,
//         props.SameDayDelivery,
//         props.DeliveryDays,
//         props.businessTypeId
//       );
//     }

//     setActiveStep(activeStep + 1);
//   }

//   const EmailCheck = gql`
//     query ($emailAddress: String!) {
//       checkRetailerExisting(emailAddress: $emailAddress)
//     }
//   `;

//   function _handleSubmit(values, actions) {
//     console.log("handlesubmit",activeStep)
//     if (activeStep === 0) {
//       props.signupForm1(values);
//       setActiveStep(activeStep + 1)
//     }
//     if (activeStep === 2) {
//       _submitForm(values, actions);
//     }
//     if (isLastStep) {
//       _submitForm(values, actions);
//     } else if (activeStep === 0) {
//       if (values.MobileNumber === values.AlternateContactNumber) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content: "Seller contact number and alternate number should not be same.",
//           okText: "Ok",
//         });
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else if (values.ManagerMobileNumber === values.ManagerAlternateContactNumber) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content: "Store contact number and alternate number should not be same.",
//           okText: "Ok",
//         });
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else {
//         client
//           .query({
//             query: EmailCheck,
//             variables: {
//               emailAddress: values.emailAddress.toLowerCase(),
//             },
//           })
//           .then((res) => {
//             if (res.data.checkRetailerExisting !== null) {
//               setActiveStep(activeStep + 1);
//             } else {
//               alert(`${res.errors[0].message}`);
//             }
//           })
//           .catch((err) => {
//             // Handle error
//           });
//       }
//     } else if (activeStep === 2) {
//       console.log(props.timings);
//       let flag = 0;
//       for (let i = 0; i < 7; i++) {
//         for (let j = 0; j < props.timings[i].slots.length; j++) {
//           if (props?.timings[i]?.slots[j]?.status) {
//             flag = 1;
//             break;
//           }
//         }
//       }
//       if (flag === 0) {
//         alert("Delivery Days cannot be empty");
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else if (!props.maxDistanceValue) {
//         alert("please select maximum delivery distance");
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else {
//         document.body.scrollTop = 0;
//         setActiveStep(activeStep + 1);
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       }
//     } else if (activeStep === 3) {
//       if (props.aadharUrl === null && props.aadharFile === true) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content: "Please upload the aadhar or delete the aadhar before submitting.",
//           okText: "Ok",
//         });
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else if (props.licenseUrl === null && props.LicenseFile === true) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content: "Please upload the License or delete the License before submitting.",
//           okText: "Ok",
//         });
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else if (props.GstUrl === null && props.gstFile === true) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content: "Please upload the GST document or delete it before submitting.",
//           okText: "Ok",
//         });
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else if (props.fssaiUrl === null && props.fssaiFile === true) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content: "Please upload the fssai document or delete it before submitting.",
//           okText: "Ok",
//         });
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       } else {
//         setActiveStep(activeStep + 1);
//         document.body.scrollTop = 0;
//         actions.setTouched({});
//         actions.setSubmitting(false);
//       }
//     } else {
//       setActiveStep(activeStep + 1);
//       document.body.scrollTop = 0;
//       actions.setTouched({});
//       actions.setSubmitting(false);
//     }
//   }

//   function _handleBack() {
//     setActiveStep(activeStep - 1);
//   }

//   function _handleClose() {
//     setIsError(false);
//   }

//   return (
//     <React.Fragment>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <Header />

//         <Snackbar open={isError} autoHideDuration={6000} onClose={_handleClose}>
//           <Alert onClose={_handleClose} severity="error" variant="filled">
//             <AlertTitle>Error</AlertTitle>
//             Something went wrong. Please try again.
//           </Alert>
//         </Snackbar>

//         <TextMobileStepper
//           steps={steps}
//           activeStep={activeStep}
//           isMobile={isSmallScreen}
//           _handleBack={_handleBack}
//           _handleSubmit={_handleSubmit}
//           _renderStepContent={_renderStepContent}
//           isLastStep={isLastStep}
//           currentValidationSchema={currentValidationSchema}
//           formId={formId}
//           formInitialValues={formInitialValues}
//         />
//       </ThemeProvider>
//     </React.Fragment>
//   );
// }
import { React, useEffect, useState } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  InputAdornment,
  IconButton,
} from "../MUIComponents/index";
import { useLocation } from "react-router-dom";
import {
  HeaderFontSize_24,
  HeaderFontSize_24_xs_17,
  HeaderNavigationFontSize_12,
  HeaderNavigationFontSize_20,
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_22,
  contentFontsize_24,
  contentFontsize_28,
} from "../Typography";
import Profileimg from "./ImageCropper/Profile";
import { validationSchema } from "./Validation/SellerValidation";
import client from "../../Configurations/apollo";
import { useDispatch } from "react-redux";
import { SIGNUP_FORM1 } from "../../Redux/Action/NewSignUp/GqlQuery";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CheckoutPage = (props) => {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (location.pathname === "/Register") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    return () => {
      window.removeEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [location.pathname]);

  const [formvalues, setFormValues] = useState({});

  const retailerId = localStorage.getItem("retailerid");
  useEffect(() => {
    props.getSellerDetail(retailerId);
  }, []);

  useEffect(() => {
    const retailerDetails = props?.retailerData?.data?.retailerdetails;
    if (retailerDetails) {
      console.log("details", retailerDetails);
      setFormValues(retailerDetails);
    }
  }, [props?.retailerData]);


  const { storeName, mobileNumber, emailId, password } = location?.state || {};
  const store = location?.state?.storeName;

  const initialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: formvalues?.mobileNumber || "",
    secondaryNumber: "",
    emailAddress: formvalues?.emailAddress || "",
    password: formvalues?.password,
    // PANnumber: "",
    VATnumber: "",
    GSTnumber: "",
    storeName: formvalues?.stores?.[0]?.storeName || "",
    storeDescription: "",
    storeManagerFirstName: "",
    storeManagerLastName: "",
    storeManagerPhone: "",
    storeManagerMobile: "",
    bankAccountNumber: "",
    accountHolderName: "",
    IFSCcode: "",
    aadharNumber: "",
    profileImageUrl: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let mutationVariables = {
        firstName: values.firstName,
        lastName: values.lastName,
        storeManagerFirstName: values.storeManagerFirstName,
        storeManagerLastName: values.storeManagerLastName,
        storeManagerMobile: values.storeManagerMobile,
        storeid: localStorage.getItem("storeid"),
        bankDetails: {
          bankAccountNumber: values.bankAccountNumber,
          accountHolderName: values.accountHolderName,
          IFSCcode: values.IFSCcode,
        },
        profileImageUrl: localStorage.getItem("ProfileUrl"),
        aadharNumber: values.aadharNumber,
      };

      if (values.secondaryNumber) {
        mutationVariables.secondaryNumber = values.secondaryNumber;
      }
      if (values.GSTnumber) {
        mutationVariables.GSTnumber = values.GSTnumber;
      }
      if (values.storeManagerPhone) {
        mutationVariables.storeManagerPhone = values.storeManagerPhone;
      }
      dispatch({
        type: "SIGNUP_FORM1_REQ",
      });
      client
        .mutate({
          mutation: SIGNUP_FORM1,
          variables: mutationVariables,
        })
        .then((response) => {
          dispatch({ type: "SIGNUP_FORM1_SUCCESS", payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: "SIGNUP_FORM1_FAILURE", payload: error.message });
        });
      nav("/RegisterForm1");
    },
  });

  useEffect(() => {
    if (Object.keys(formvalues).length > 0) {
      formik.setValues({
        firstName: formvalues.firstName || "",
        lastName: formvalues.lastName || "",
        mobileNumber: formvalues.mobileNumber || "",
        secondaryNumber: formvalues.secondaryNumber || "",
        emailAddress: formvalues.emailAddress || "",
        password: formvalues.password || "",
        VATnumber: formvalues.VATnumber || "",
        GSTnumber: formvalues.GSTnumber || "",
        storeName: formvalues.stores?.[0]?.storeName || "",
        storeDescription: formvalues.storeDescription || "",
        storeManagerFirstName: formvalues.storeManagerFirstName || "",
        storeManagerLastName: formvalues.storeManagerLastName || "",
        storeManagerPhone: formvalues.storeManagerPhone || "",
        storeManagerMobile: formvalues.storeManagerMobile || "",
        bankAccountNumber: formvalues.bankAccountNumber || "",
        accountHolderName: formvalues.accountHolderName || "",
        IFSCcode: formvalues.IFSCcode || "",
        aadharNumber: formvalues.aadharNumber || "",
        profileImageUrl: formvalues.profileImageUrl || "",
      });
    }
  }, [formvalues]);

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  return (
    <div style={{ height: "100%" }}>
      <div style={{ background: "#85BE49", padding: "10px" }}>
        <Typography variant="h4" align="center" gutterBottom color={"white"}>
          Registration
        </Typography>
      </div>
      <div style={{ padding: "10px" }}>
        <Typography
          align="center"
          gutterBottom
          fontSize={contentFontSize_22}
          className="chooseHolidaysStyle"
        >
          Please enter your details to create your account
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{ padding: "30px", paddingTop: "0px" }}
        >
          <Typography
            variant="h6"
            gutterBottom
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Seller Contact Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                First Name <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Last Name <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Mobile Number <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Mobile Number"
                name="mobileNumber"
                value={formik.values.mobileNumber}
                disabled={Boolean(formik.values.mobileNumber)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.mobileNumber &&
                  Boolean(formik.errors.mobileNumber)
                }
                helperText={
                  formik.touched.mobileNumber && formik.errors.mobileNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Alternate Mobile Number
              </Typography>
              <TextField
                name="secondaryNumber"
                value={formik.values.secondaryNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.secondaryNumber &&
                  Boolean(formik.errors.secondaryNumber)
                }
                helperText={
                  formik.touched.secondaryNumber &&
                  formik.errors.secondaryNumber
                }
              />
            </Grid>
          </Grid>

          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "20px" }}
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Store Contact Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Store Manager First Name
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Store Manager First Name"
                name="storeManagerFirstName"
                value={formik.values.storeManagerFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.storeManagerFirstName &&
                  Boolean(formik.errors.storeManagerFirstName)
                }
                helperText={
                  formik.touched.storeManagerFirstName &&
                  formik.errors.storeManagerFirstName
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Store Manager Last Name
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Store Manager Last Name"
                name="storeManagerLastName"
                value={formik.values.storeManagerLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.storeManagerLastName &&
                  Boolean(formik.errors.storeManagerLastName)
                }
                helperText={
                  formik.touched.storeManagerLastName &&
                  formik.errors.storeManagerLastName
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Store Manager Mobile Number
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                name="storeManagerMobile"
                value={formik.values.storeManagerMobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.storeManagerMobile &&
                  Boolean(formik.errors.storeManagerMobile)
                }
                helperText={
                  formik.touched.storeManagerMobile &&
                  formik.errors.storeManagerMobile
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Store Manager Alternate Mobile Number
              </Typography>
              <TextField
                name="storeManagerPhone"
                value={formik.values.storeManagerPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.storeManagerPhone &&
                  Boolean(formik.errors.storeManagerPhone)
                }
                helperText={
                  formik.touched.storeManagerPhone &&
                  formik.errors.storeManagerPhone
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Email Address<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Email Address"
                name="emailAddress"
                value={formik.values.emailAddress}
                disabled={Boolean(formik.values.emailAddress)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.emailAddress &&
                  Boolean(formik.errors.emailAddress)
                }
                helperText={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Password<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                name="password"
                value={formik.values.password}
                disabled={Boolean(formik.values.password)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(e) => e.preventDefault()} // prevent the input field from losing focus
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Typography
            gutterBottom
            style={{ marginTop: "20px" }}
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Store Payment Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Bank Account Number<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Bank Account Number"
                name="bankAccountNumber"
                value={formik.values.bankAccountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.bankAccountNumber &&
                  Boolean(formik.errors.bankAccountNumber)
                }
                helperText={
                  formik.touched.bankAccountNumber &&
                  formik.errors.bankAccountNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                IFSC Code<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="IFSC Code"
                name="IFSCcode"
                value={formik.values.IFSCcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.IFSCcode && Boolean(formik.errors.IFSCcode)
                }
                helperText={formik.touched.IFSCcode && formik.errors.IFSCcode}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Account Holder Name<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="Account Holder Name"
                name="accountHolderName"
                value={formik.values.accountHolderName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.accountHolderName &&
                  Boolean(formik.errors.accountHolderName)
                }
                helperText={
                  formik.touched.accountHolderName &&
                  formik.errors.accountHolderName
                }
              />
            </Grid>
          </Grid>

          <Typography
            gutterBottom
            style={{ marginTop: "20px" }}
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Store Registration Details
          </Typography>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                PAN Card<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="PAN Card"
                name="PANnumber"
                value={formik.values.PANnumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.PANnumber && Boolean(formik.errors.PANnumber)
                }
                helperText={formik.touched.PANnumber && formik.errors.PANnumber}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                GST Number
                {/* <span style={{ color: "#EA4335" }}>*</span> */}
              </Typography>
              <TextField
                // label="GST Number"
                name="GSTnumber"
                value={formik.values.GSTnumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.GSTnumber && Boolean(formik.errors.GSTnumber)
                }
                helperText={formik.touched.GSTnumber && formik.errors.GSTnumber}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Aadhaar Number<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // label="GST Number"
                name="aadharNumber"
                value={formik.values.aadharNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.aadharNumber &&
                  Boolean(formik.errors.aadharNumber)
                }
                helperText={
                  formik.touched.aadharNumber && formik.errors.aadharNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_18}
              >
                Upload Profile Image
                {/* <span style={{ color: "#EA4335" }}>*</span> */}
              </Typography>
              <Profileimg />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "20px", background: "#85BE49" }}
            >
              Next
            </Button>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CheckoutPage;
